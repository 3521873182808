import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, TextInput, Tooltip } from 'components/common';
import { SubmitHandler } from 'react-hook-form';
import { IQuestionnaire, IQuestionnaireForm, IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { updateQuestionnaire } from 'services/api/questionnaire';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { EditQuestionnaireForm } from './index';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { SiteStaffIcon, PatientsIcon } from './Icons';
import { SvgIcon } from '@mui/material';
import { getMaxSortId } from 'utils/number';
import DragDropList from './DragableQuestionsContainer/DragDropList';
import { useQuestionnaire } from './DragableQuestionsContainer/useQuestionnaire';
import { insertAtIndex } from 'utils/array';
import PreviewQuestions from './PreviewQuestions';
import ScheduleEpro from './ScheduleEpro';
import { FormattedMessage, useIntl } from 'react-intl';

interface IProps {
  initQuestionnaire: IQuestionnaire;
  refetchSteps: any;
  preview: boolean;
}

const QuestionnairePage: React.FC<IProps> = ({
  preview,
  initQuestionnaire,
  refetchSteps,
}: IProps) => {
  const [editTitle, setEditTitle] = useState(false);
  const [changeQuestionnaireConfirmation, setChangeQuestionnaireConfirmation] = useState(false);
  const [newTitle, setNewTitle] = useState(initQuestionnaire?.questionnaire?.title);
  const [styleType, setStyleType] = useState('horizontal');
  const [mobileView, setMobileView] = useState('mobile');
  const labelRef = useRef<HTMLLabelElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  useEffect(() => {
    if (mobileView === 'mobile' && initQuestionnaire?.questionnaire?.type === 'E_PRO')
      setStyleType('vertical');
  }, [mobileView, styleType]);

  const [isEditQuestionnaireModalOpen, setIsEditQuestionnaireModalOpen] = useState(false);
  const [eproModal, setEproModal] = useState(false);
  const { selectedQuestionnairy, setSelectedQuestionnairy, questions, setQuestions } =
    useQuestionnaireStore();
  const { handleAddQSToQuestionnaire } = useQuestionnaire({});
  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      event.preventDefault();
      if (clickedOut && event.target !== labelRef.current) {
        handleEditTitle(newTitle);
      }
    },
    [newTitle],
  );
  useOutsideClick(containerRef, handleOutsideClick);

  useEffect(() => {
    // const folders: any = [];

    if (Array.isArray(questions)) {
      const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

      if (fndEmptyItem === -1) {
        setQuestions(
          insertAtIndex(
            [...questions],
            {
              id: 0,
              questionTitle: '',
              sortOrder: getMaxSortId(questions) + 1,
            },
            questions.length - 1,
          ),
        );
      }
      const tmpQuestions: any = [...questions];
      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.parentQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.parentQuestionId === el?.id) {
              tmpQuestions[folderIndx]['children'] = el['children']
                ? tmpQuestions[folderIndx]['children']?.findIndex((it: any) => it.id === qs.id) < 0
                  ? [...el['children'], qs]
                  : [...el['children']]
                : [qs];
            }
          });
        }
      });

      // set sub title questions
      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.titleQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.titleQuestionId === el?.id) {
              tmpQuestions[folderIndx]['titleQuestions'] = el['titleQuestions']
                ? tmpQuestions[folderIndx]['titleQuestions']?.findIndex(
                    (it: any) => it.id === qs.id,
                  ) < 0
                  ? [...el['titleQuestions'], qs]
                  : [...el['titleQuestions']]
                : [qs];
            }
          });
        }
      });
    }
  }, [questions]);

  const { mutate: updateQuestionnaireMutation, isLoading: isEditQuestionnaireLoading } =
    useMutation<IQuestionnaireForm, unknown, IQuestionnaireForm, unknown>(
      (data: IQuestionnaireForm) => updateQuestionnaire(data),
      {
        onSuccess: () => {
          setSelectedQuestionnairy({
            ...selectedQuestionnairy,
            questionnaire: { ...selectedQuestionnairy?.questionnaire, title: newTitle },
          });

          setIsEditQuestionnaireModalOpen(false);
          refetchSteps();
        },
        onError(error: any) {
          toast.error(
            error?.response?.data?.message ??
              intl.formatMessage({ id: 'error.questionnaireUpdatedError' }),
          );
        },
      },
    );

  const onSubmit: SubmitHandler<IQuestionnaireForm> = (data: any) => {
    updateQuestionnaireMutation({
      ...data,
      id: initQuestionnaire?.questionnaire?.id,
      sortOrder: initQuestionnaire?.questionnaire?.sortOrder,
      type: initQuestionnaire?.questionnaire?.type,
    });
  };
  const handleEditTitle = (title: string) => {
    if (title)
      updateQuestionnaireMutation({
        title: title,
        id: initQuestionnaire?.questionnaire.id,
        sortOrder: initQuestionnaire?.questionnaire.sortOrder,
        type: initQuestionnaire?.questionnaire?.type,
      });

    setEditTitle(false);
  };

  const handleOnSaveQuestion = (tmpQuestion: IQuestion & { optionalProps?: any }) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const fndItem: any = questions.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' && optionalProps?.isNew
            ? {
                questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                  ...opt,
                  score: null,
                })),
              }
            : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          ...(tmpQuestion?.optionalProps?.numberType
            ? { numberType: tmpQuestion?.optionalProps?.numberType }
            : {}),
          ...(tmpQuestion?.optionalProps?.dateTimeType
            ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
            : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
        ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),

          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF'
            ? {
                questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                  ...opt,
                  label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
                  value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
                  score: null,
                })),
              }
            : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
        ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
      });
    }
    // refetchSteps();
  };

  const handleType = (type: string) => {
    if (initQuestionnaire?.questionnaire?.type !== type) {
      updateQuestionnaireMutation({
        title: initQuestionnaire?.questionnaire?.title,
        id: initQuestionnaire?.questionnaire?.id,
        sortOrder: initQuestionnaire?.questionnaire?.sortOrder,
        type,
      });
    }

    setChangeQuestionnaireConfirmation(false);
    if (type === 'E_PRO') {
      setEproModal(true);
    } else {
      setEproModal(false);
    }
  };
  const hasRepeatedQuestions = () =>
    questions?.findIndex((qs: IQuestion) => qs?.questionType === 'REPEATED') > -1;

  return (
    <div className='w-full max-w-[1100px]  rounded-lg border-[1px] border-purple'>
      <Modal
        isOpen={isEditQuestionnaireModalOpen}
        onClose={() => setIsEditQuestionnaireModalOpen(false)}
        className={{ content: 'p-8 !bg-background' }}
        // title={<h1 className='text-blue-ocean-deep text-[32px]'>Update Questionnaire</h1>}
      >
        <EditQuestionnaireForm
          questionnaire={initQuestionnaire?.questionnaire}
          closeModal={() => setIsEditQuestionnaireModalOpen(false)}
          refetchSteps={refetchSteps}
          onSubmit={onSubmit}
          isLoading={isEditQuestionnaireLoading}
        />
      </Modal>
      <Modal
        isOpen={changeQuestionnaireConfirmation}
        onClose={() => setChangeQuestionnaireConfirmation(false)}
        className={{ content: 'p-8 !bg-background' }}
        // title={<h1 className='text-blue-ocean-deep text-[32px]'>Update Questionnaire</h1>}
      >
        <div className='w-[500px]'>
          <p className='my-4 mx-10 text-[#000]'>
            <FormattedMessage id='study-builder.are-you-sure-to-change-to-epro' />
          </p>

          <div className='flex justify-end'>
            <button
              onClick={() => setChangeQuestionnaireConfirmation(false)}
              className='border border-blue-ocean-deep text-blue-ocean-deep transition-opacity mr-3 w-fit py-[10px] px-5 rounded-[32px] hover:opacity-90 active:opacity-100 font-bold mt-4'
            >
              <FormattedMessage id='cancel' />
            </button>
            <button
              onClick={() => {
                handleType('E_PRO');
                // setTimeout(() => {
                // window.location.reload();
                // refetchSteps();
                // }, 200);
              }}
              className='bg-blue-ocean-deep transition-opacity text-white w-fit py-[10px] px-5 rounded-[32px] hover:opacity-90 active:opacity-100 font-bold mt-4'
              type='submit'
            >
              <FormattedMessage id='confirm' />
            </button>
          </div>
        </div>
      </Modal>
      <div className='bg-white flex justify-between items-center rounded-lg'>
        <div className='flex w-full justify-between items-center border-b border-purple w-full pl-5 pr-7 rounded-t-lg'>
          {editTitle ? (
            <div ref={containerRef}>
              <TextInput
                control={{
                  value: newTitle,
                  onChange: (e) => {
                    if (e.target.value) setNewTitle(e.target.value);
                  },
                }}
                name={'title1'}
                placeholder='questionnaire-field.data-form.typeMessage'
                className='[&>input]:my-[18px] [&>input]:py-3 w-[300px] [&>input]:h-[36px]'
              />
            </div>
          ) : (
            <h2
              onClick={() => {
                setNewTitle(initQuestionnaire?.questionnaire?.title);
                setEditTitle(true);
              }}
              className='block gap-x-2 justify-start items-center text-blue-ocean-deep text-[24px] font-normal text-left py-5 w-[40rem] truncate text-ellipsis max-w-[23rem]'
            >
              {initQuestionnaire?.questionnaire?.title ?? ''}
            </h2>
          )}
          <div className='flex justify-end items-center gap-x-2 relative'>
            <span onClick={() => handleType('E_CRF')} className='flex cursor-pointer'>
              <SvgIcon
                className={`100 mr-1 ${
                  initQuestionnaire?.questionnaire?.type === 'E_CRF'
                    ? 'text-blue-normal'
                    : 'text-blue-oil'
                }`}
                component={SiteStaffIcon}
              />
              <Tooltip
                className='ml-[13px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.ecrfView' })}
              >
                <span
                  className={`font-light ${
                    initQuestionnaire?.questionnaire?.type === 'E_CRF'
                      ? 'text-blue-normal'
                      : 'text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='roles.SITE_STAFF' />
                </span>
              </Tooltip>
            </span>
            <span
              onClick={() => {
                if (hasRepeatedQuestions()) setChangeQuestionnaireConfirmation(true);
                else handleType('E_PRO');
              }}
              className='ml-2 flex cursor-pointer'
            >
              <SvgIcon
                className={`100 mr-1 font-light ${
                  initQuestionnaire?.questionnaire?.type === 'E_PRO'
                    ? 'text-blue-normal'
                    : 'text-blue-oil'
                }`}
                component={PatientsIcon}
              />
              <Tooltip
                className='ml-[10px] -mt-[5px]'
                text={intl.formatMessage({ id: 'questionnaire-field.eproView' })}
              >
                <span
                  className={`font-light ${
                    initQuestionnaire?.questionnaire?.type === 'E_PRO'
                      ? 'text-blue-normal'
                      : 'text-blue-oil'
                  }`}
                >
                  {' '}
                  <FormattedMessage id='study-dashboard.patients' />
                </span>
              </Tooltip>
            </span>
            {eproModal ? (
              <ScheduleEpro
                eproModal={eproModal}
                setEproModal={setEproModal}
                hasEpro={initQuestionnaire?.questionnaire?.eproSchedule}
              />
            ) : null}
          </div>
        </div>
      </div>
      {preview ? (
        <PreviewQuestions
          preview={true}
          styleType={styleType}
          mobileView={mobileView}
          setStyleType={setStyleType}
          handleType={handleType}
          initQuestionnaire={initQuestionnaire}
          questionnaire={initQuestionnaire}
          setChangeQuestionnaireConfirmation={setChangeQuestionnaireConfirmation}
          hasRepeatedQuestions={hasRepeatedQuestions}
          setMobileView={setMobileView}
        />
      ) : (
        <div className='bg-white px-9 py-8 rounded-md'>
          <DragDropList
            questions={questions
              ?.filter((qs: IQuestion) => !qs.parentQuestionId && !qs?.titleQuestionId)
              ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)}
            handleOnSaveQuestion={handleOnSaveQuestion}
            filterRepeatedInput={initQuestionnaire?.questionnaire?.type !== 'E_CRF'}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionnairePage;
