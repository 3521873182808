import { axiosClient } from 'config/axios';

interface SignIn {
  emailOrPhoneNumber: string;
  password: string;
}

interface IResetPassword {
  email: string;
  token: string;
  password: string;
}
interface ISetPassword {
  oldPassword: string;
  newPassword: string;
}

export const signIn = async ({ emailOrPhoneNumber, password }: SignIn) => {
  const { data } = await axiosClient.post('auth/authenticate', { emailOrPhoneNumber, password });
  return data;
};

export const forgetPassword = async (email: string) => {
  const { data } = await axiosClient.put(`auth/passwords/resets/emails/${email}`);
  return data;
};

export const resetPassword = async ({ email, token , password }: IResetPassword) => {
  const { data } = await axiosClient.put(`auth/passwords/${password}/resets/emails/${email}/tokens/${token}`);
  return data;
};

export const setPassword = async ({ oldPassword, newPassword }: ISetPassword) => {
  const { data } = await axiosClient.put(`auth/passwords/resets?oldPassword=${oldPassword}&newPassword=${newPassword}`);
  return data;
};

export const rtcProps = {
  appId: '924a3b097762413b9f3322db37caae08',
};

export const forgetPasswordCopilot = async (email: string) => {
  const { data } = await axiosClient.put(`auth/copilot/passwords/resets/emails/${email}`);
  return data;
};