import { useEffect, useState } from 'react';
import { FiBriefcase, FiFolderPlus, FiInbox } from 'react-icons/fi';
import clsx from 'classnames';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from 'store/usersStore';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from 'services/api/users';
import { FormattedMessage } from 'react-intl';
import { getAiPatients, sendChatgptData } from '../../services/api/patientProfile';
import { useParticipantStore } from '../../store/participantStore';
import { useStudyStore } from '../../store/StudyStore';

const Sites: React.FC = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('all');
  const [result, setResult] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { setRolesOpt } = useUserStore();
  const { studyId } = useParams();
  const { setScoreResult } = useParticipantStore();
  const { currentStudy } = useStudyStore();

  const { data: aiProfiles  } = useQuery({
    queryKey: ['aiPatients'],
    queryFn: () => getAiPatients(),
  });

  const handleTab = (item: string) => {
    setTab(item);
    navigate(item);
  };

  const {
    isSuccess: roleSuccess,
    data: roles,
    refetch: roleRefetch,
  } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getRoles('STUDY', studyId),
  });

  useEffect(() => {
    roleSuccess &&
      roles?.length > 0 &&
      setRolesOpt(
        roles
          ?.filter((role) => role?.category === 'SITE_STAFF')
          ?.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
      );
  }, [roles]);

  useEffect(() => {
    studyId && roleRefetch();
  }, [studyId]);

  const addLineNo = (str: any) => {
    let i = 1;
    const numbered = str.replace(/^/gm, () => `${i++}.`);
    return numbered;
  }

  const fetchResults = async () => {
    setLoading(true);
    const filterProfiles = aiProfiles && aiProfiles?.length > 0 && aiProfiles?.reduce((newAiPro: any, aiPro: any) => {
      let dataCount: number = 0;
      Object.keys(aiPro).map((key: any) => {
        if ((typeof aiPro[key] !== 'object' && (aiPro[key] !== '' || aiPro[key] !== null)) || (typeof aiPro[key] === 'object' && aiPro[key] && aiPro[key]?.length !== 0)) {
          dataCount++;
        }
      })
      if (dataCount >= 3) {
        newAiPro.push(aiPro);
      }
      return newAiPro;
    }, []);

    const payload = {
      model: process.env.REACT_APP_CHATGPT_MODEL,  // Make sure you specify the correct model if needed
      messages: [
        {
          role: 'user',
          content: `we have a clinical study that has the following inclusion criteria:
            ${currentStudy?.inclusionCriteria && addLineNo(currentStudy?.inclusionCriteria)}
                         
            and the following exclusion criteria:
            ${currentStudy?.exclusionCriteria && addLineNo(currentStudy?.exclusionCriteria)}
             
            I'll provide a list of patient profiles in a JSON format, compare every patient to the previously mentioned criteria then provide me with a JSON that shows the results in the following format:
            [{
            "id": Integer
            "result": Integer,
            “score”: Integer
            }]
            "result" attribute has 3 statuses:
            1: patient did not match any exclusion criteria and matched at least one inclusion criteria
            2: patient matched an exclusion criteria or did not match any inclusion criteria
            3: patient profile is missing some details related to the inclusion and exclusion criteria, so you could not decide about the result
             
            "score" attribute has the following rules:
            1- if any exclusion criteria matches the patient, the score is 0 and do not compare the patient with any inclusion criteria
            2- add 1 to the score for each inclusion criteria that matches the patient
             
            These are the patient profiles in JSON: ${JSON.stringify(filterProfiles)}
             
            Note: just send the JSON without any additional word`
        }
      ]
    };

    try {
      const response = await sendChatgptData(payload);
      if (response) {
        setResult(JSON.parse(response));
      }
    } catch (error) {
      console.error('Error fetching results from ChatGPT:', error);
    }
  };

  useEffect(() => {
    currentStudy && aiProfiles && fetchResults();
  }, [aiProfiles, currentStudy]);

  const getScoreCount = (resdata: any) => {
    const totalScore = resdata && resdata?.length > 0 && resdata.reduce((total: number, prev: any) => {
      total += prev?.score;
      return total;
    }, 0)
    return totalScore;
  }

  useEffect(() => {
    if (result) {
      setScoreResult(getScoreCount(result));
      setLoading(false);
    }
  }, [result]);

  useEffect(() => {
    switch (location.pathname.split('/')[4]) {
      case 'all':
        setTab('all');
        break;
      case 'onboarding':
        setTab('onboarding');
        break;
      default:
        setTab('all');
    }
  }, [location]);

  const isStudyOnboarding = `${studyId}` === process.env.REACT_APP_STUDY_ID;

  return (
    <div className='flex bg-background-secondary flex-1 gap-[2.9375rem] pr-11'>
      <div className='w-[84px] h-fit font-serif mt-[36px] border-2 border-blue-ocean-deep bg-white rounded-r-lg flex flex-col justify-start items-center gap-[1.625rem] py-[1.625rem]'>
        <div
          onClick={() => handleTab('all')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center cursor-pointer',
            tab === 'all'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiBriefcase
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'all' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-blue-ocean-deep'>
            <FormattedMessage id='allStudies.all' />
          </span>
        </div>
        <div
          onClick={() => isStudyOnboarding ? handleTab('onboarding') : null}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center',
            isStudyOnboarding ? 'cursor-pointer' : 'cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40',
            tab === 'onboarding'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiFolderPlus
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'onboarding' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='site.onboarding' />
          </span>
        </div>
        <div
          // onClick={() => handleTab('requests')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40',
            tab === 'requests'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiInbox
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'requests' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='site.requests' />
          </span>
        </div>
      </div>
      <div className='flex-1 grow pt-11 container mx-auto'>
        <Outlet context={{ loading }} />
      </div>
    </div>
  );
};
export default Sites;
