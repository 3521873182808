/* eslint-disable react/display-name */
import { IQuestionnaire } from 'types/questionnaire';
import Question from './Question';
import React, { FC, useEffect, useState } from 'react';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FormProvider, useForm } from 'react-hook-form';
import { IAddAnswerForm } from 'types/step';
import useAuthStore from 'store/authStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addPatientAnswers,
  deletePatientAnswer,
  fetchStepsByParticipantId,
} from 'services/api/steps';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Button, ToggleButton, Tooltip } from 'components/common';
import { submitStandardFormApi, updateStandardFormAnswer } from 'services/api/standardForms';
import { IStandardFormAnswerPayload, IStandardFormPayload } from 'types/standardForm';
import { useStudyStore } from 'store/StudyStore';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCentersStore } from 'store/centers';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'classnames';
import EproScheduleQuestionaire from './EproSchedule/EproScheduleQuestionaire';
import { useParticipantStore } from 'store/participantStore';
import usePermissions from '../../../../hooks/usePermissions';
import {
  addQuestionQuery,
  deleteQuestionQuery,
  getQuestionQueries,
  IQueryForm,
} from '../../../../services/api/questionQuery';
import { useQueryQuestionStore } from '../../../../store/queryQuestionStore';
import { toast } from 'react-toastify';
import { getFormat } from '../../../../utils/string';
import { setsdvAnswer } from '../../../../services/api/questionnaire';

interface QuestionsViewProps {
  questionnaire: IQuestionnaire;
  isStandardForm?: boolean;
  canEditStandardForm?: boolean;
  isTerminationFormAndSubmitted?: boolean;
  terminationSubmissionData?: any;
  refetchTerminationSubmissionData?: any;
  defaultValues?: any;
  isEditableForm?: boolean;
  isStandardFormEditMode?: boolean;
  canEditAnswer?: boolean;
  refetchStandardForms?: any;
  standardFormAns?: any;
  isInModal?: boolean;
}

const calcValidationSchema = (questionnaire: any, questions: any) => {
  const intl = useIntl();
  const obj: any = {};

  questions?.map((qs: any, index: number) => {
    if (qs?.required && !([1, 2, 3, 4].includes(index) && questionnaire?.type === 'AE')) {
      switch (qs?.questionType) {
        case 'TEXT_INPUT':
          obj[`textInput${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        case 'TEXT_AREA':
          obj[`textArea${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        case 'NUMBER_VALUE':
          obj[`numberTxt${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        case 'CHECKBOX': {
          obj[`checkList${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        }
        case 'DROPDOWN': {
          obj[`selectField${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        }
        case 'DATE_TIME':
          obj[`dateTime${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        case 'RADIO_BUTTONS': {
          obj[`radioBtns${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        }
        case 'FILE_UPLOAD':
          obj[`fileUpload${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        case 'RATING':
          obj[`rating${qs.id}`] = Yup.string().required(
            `${qs?.questionTitle} ${intl.formatMessage({ id: 'isRequired' })}`,
          );
          break;
        default:
          null;
      }
    }
  });
  return Yup.object(obj).required();
};

export const QuestionsView: FC<QuestionsViewProps> = (props: QuestionsViewProps) => {
  const {
    isTerminationFormAndSubmitted,
    terminationSubmissionData,
    questionnaire,
    canEditStandardForm,
    refetchTerminationSubmissionData,
    defaultValues = {},
    isEditableForm,
    isStandardFormEditMode = false,
    isStandardForm,
    refetchStandardForms,
    standardFormAns,
    isInModal = false,
  } = props;
  const intl = useIntl();
  const [answers, setAnswers] = useState<any>([]);
  const [isSdv, setIsSdv] = useState<any>(false);
  const { loggedInStudyUser } = useStudyStore();
  const { questionnaireId, participantId, studyId } = useParams();
  const [consentData, setConsentData] = useState('');
  const [selectedQuestId, setSelectedQuestId] = useState(-1);
  const [otherError, setOtherError] = useState<any>([]);
  const [styleType, setStyleType] = useState('horizontal');
  const { stepsData, setStepsData, steps } = useQuestionnaireStore();
  const { setQueryQuestions, queryQuestions } = useQueryQuestionStore();
  const { userData } = useAuthStore();
  const { centers, selectedCenter } = useCentersStore();
  const { setSelectedQuestionnaire, selectedQuestionnaire } = useParticipantStore();
  const { isFreezeStudy } = usePermissions();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';

  const { mutate: updateSDV } = useMutation<unknown, unknown, number, unknown>(
    (answerId: number) => setsdvAnswer(answerId),
    {
      onSuccess(res: any) {
        const tempAns: any = answers;
        const foundIndex = answers?.findIndex((rep: any) => `${rep?.id}` === `${res?.id}`,);
        if (tempAns[foundIndex]) {
          tempAns[foundIndex] = res;
          setAnswers(tempAns);
        }
      },
      onError() {},
    },
  );

  const { refetch } = useQuery({
    queryKey: ['stepsByStudyId', participantId],
    queryFn: () => fetchStepsByParticipantId(participantId as string),
  });

  const { data, refetch: queryRefetch } = useQuery({
    queryKey: ['queries'],
    queryFn: () =>
      studyId &&
      participantId &&
      selectedCenter &&
      getQuestionQueries(
        parseInt(`${studyId}`, 10),
        parseInt(`${selectedCenter}`, 10),
        parseInt(`${participantId}`, 10),
      ),
  });

  const { mutate: sendQuery } = useMutation<any, unknown, IQueryForm, unknown>(
    (data: IQueryForm) => addQuestionQuery(data),
    {
      onSuccess: () => {
        queryRefetch();
      },
    },
  );

  const { mutate: deleteQuery } = useMutation<any, unknown, number, unknown>(
    (id: number) => deleteQuestionQuery(id),
    {
      onSuccess: () => {
        queryRefetch();
      },
    },
  );

  const onSubmitQuery = (id: number, value: string) => {
    if (value && loggedInStudyUser?.id !== -1) {
      sendQuery({
        baseQuestionId: parseInt(`${id}`, 10),
        patientStepId: stepsData?.stepId || 0,
        folderId: stepsData?.folder?.parentFolderId || stepsData?.folder?.id || null,
        centerUserId: parseInt(`${loggedInStudyUser?.id}`, 10) || 1,
        comment: value,
      });
    } else if (value) {
      toast.error(intl.formatMessage({ id: 'query.queryError' }));
    }
  };

  const autoQueryCheck = (qstId: number) => {
    const filteredQuests: any =
      (questionnaire?.questions &&
        questionnaire?.questions?.length > 0 &&
        questionnaire?.questions?.filter(
          (q: any) =>
            `${q?.id}` !== `${qstId}` &&
            q?.questionTitle != '' &&
            q?.questionType !== 'TITLE' &&
            q?.questionType !== 'REPEATED' &&
            q?.required,
        )) ||
      [];
    filteredQuests &&
      filteredQuests?.length > 0 &&
      filteredQuests?.map((quest: any) => {
        const foundAns: any = quest?.id && getAnswer(quest?.id);
        if (quest?.required) {
          const findQuery =
            queryQuestions &&
            queryQuestions?.length > 0 &&
            queryQuestions?.find(
              (qs: any) =>
                `${qs?.baseQuestionId}` === `${quest?.id}` &&
                qs?.comment.includes('This question is mandatory. Please enter a value'),
            );
          if (findQuery && findQuery?.id) {
            if (findQuery && findQuery?.id && foundAns && foundAns?.id) {
              deleteQuery(findQuery?.id);
            }
            return;
          } else if (quest?.required && !(foundAns && foundAns?.id) && !findQuery) {
            onSubmitQuery(quest?.id, 'This question is mandatory. Please enter a value');
          }
        }
      });
  };

  useEffect(() => {
    setQueryQuestions(data);
  }, [data]);

  const isAddPermission = isSiteStaff && `${selectedCenter}` === `${loggedInStudyUser?.centerId}` && !isFreezeStudy();

  useEffect(() => {
    setAnswers(
      steps?.find(
        (stp: any) => stp?.step?.questionnaire?.id === Number(questionnaireId?.split('-')?.[1]),
      )?.answers ?? [],
    );
  }, [steps, questionnaireId]);

  const foundStep: any = steps?.find(
    (stp: any) => stp?.step?.questionnaire?.id === Number(questionnaireId?.split('-')?.[1]),
  );

  const { mutate: submitStandardForm } = useMutation<
    unknown,
    unknown,
    IStandardFormPayload,
    unknown
  >((data: IStandardFormPayload) => submitStandardFormApi(data), {
    onSuccess() {
      methods.reset();
      refetchTerminationSubmissionData();
    },
    onError() {},
  });

  useEffect(() => {
    if (steps && steps.length > 0) {
      const foundData = steps.find(
        (opt: any) => opt?.step?.questionnaire?.id === selectedQuestionnaire,
      );
      if (foundData) {
        setStepsData({
          ...foundData.step,
          stepId: foundData.id,
          status: foundData.status,
          answers: foundData.answers,
          dueDate: foundData.dueDate,
        });
      }
    }
  }, [steps, selectedQuestionnaire]);

  useEffect(() => {
    setSelectedQuestionnaire(questionnaireId ? parseInt(questionnaireId.split('-')[1]) : -1);
  }, [questionnaireId]);

  const addQueryReq = (type: string) => {
    const findQuest: any =
      questionnaire?.questions &&
      questionnaire?.questions?.length > 0 &&
      questionnaire?.questions?.filter(
        (q: any) =>
          `${q?.id}` === `${selectedQuestId}` &&
          q?.questionTitle != '' &&
          q?.questionType !== 'TITLE' &&
          q?.questionType !== 'REPEATED' &&
          q?.required,
      )?.[0];
    if (findQuest) {
      const findQuery =
        queryQuestions &&
        queryQuestions?.length > 0 &&
        queryQuestions?.find(
          (qs: any) =>
            `${qs?.baseQuestionId}` === `${findQuest?.id}` &&
            qs?.comment.includes('This question is mandatory. Please enter a value'),
        );
      if (findQuery && type === 'delete') {
        deleteQuery(findQuery?.id);
      } else {
        if (findQuery && findQuery?.id) {
          return;
        } else if (!findQuery && type === 'add') {
          onSubmitQuery(findQuest?.id, 'This question is mandatory. Please enter a value');
        }
      }
    }
  };

  const { mutate: addAnswersMutation } = useMutation<any, unknown, IAddAnswerForm[], unknown>(
    (data: IAddAnswerForm[]) => addPatientAnswers(foundStep?.id ?? (stepsData?.stepId || 0), data),
    {
      onSuccess: (res: any) => {
        if (isSdv) {
          res && res?.length > 0 && res[0]?.id && updateSDV(res[0]?.id);
        }
        addQueryReq('delete');
        if (res && res?.length > 0) {
          const foundAns = answers.find((ans: any) => ans.questionId === res[0]?.questionId);
          const newAnswers = answers.filter((ans: any) => ans.questionId !== foundAns?.questionId);
          setAnswers([ ...newAnswers, ...res ]);
        }
      },
    },
  );

  const { mutate: removeAnswerMutation } = useMutation<any, unknown, number, unknown>(
    (id: number) => deletePatientAnswer(id),
    {
      onSuccess: () => {
        refetch();
        addQueryReq('add');
      },
    },
  );

  const { mutate: submitStandardFormAnswer } = useMutation<
    unknown,
    unknown,
    IStandardFormAnswerPayload,
    unknown
  >((data: IStandardFormAnswerPayload) => updateStandardFormAnswer(data), {
    onSuccess() {
      refetchStandardForms();
    },
    onError() {},
  });

  const uploadFileAns = (res: any) => {
    if (res && res?.answer && stepsData?.stepId && isAddPermission) {
      setSelectedQuestId(res?.questionId);
      autoQueryCheck(res?.questionId);
      addAnswersMutation([
        {
          answererId: userData.id,
          questionId: res?.questionId,
          optionId: null,
          answer: res?.answer,
        },
      ]);
    }
  };

  useEffect(() => {
    if (
      questionnaire?.type === 'CONSENT' &&
      questionnaire?.staticContents &&
      questionnaire?.staticContents?.length > 0
    ) {
      setConsentData('');
      let newText = '';
      questionnaire?.staticContents?.map((stData: any) => {
        newText = newText + ' ' + stData?.header;
        setConsentData(newText);
      });
    }
  }, [questionnaire]);

  const filteredQuestions: any =
    (questionnaire?.questions &&
      questionnaire?.questions?.length > 0 &&
      questionnaire?.questions?.filter(
        (q: any) => q?.questionTitle != '' && !q?.parentQuestionId && q?.titleQuestionId === null,
      )) ||
    [];

  const validationSchema = calcValidationSchema(questionnaire ?? {}, filteredQuestions);
  
  const methods: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...(defaultValues as any) },
  });
  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      methods.reset(defaultValues);
    }
  }, [defaultValues]);

  const values: any = methods.watch();
  useEffect(() => {
    if (questionnaire?.type === 'PROTOCOL_DEVIATION') {
      let reasonQs: any, anotherReasonQs: any, consequencesQs: any, anotherConsequencesQs: any;
      questionnaire?.questions?.map((it: any) => {
        if (it.sortOrder == 2) {
          reasonQs = it;
        } else if (it.sortOrder == 3) {
          anotherReasonQs = it;
        } else if (it.sortOrder == 4) {
          consequencesQs = it;
        } else if (it.sortOrder == 5) {
          anotherConsequencesQs = it;
        }
      });

      // handle depend inputs validation for protocol deviations form
      if (
        values[`selectField${reasonQs?.id}`] &&
        !values[`textInput${anotherReasonQs?.id}`] &&
        methods?.formState?.errors?.[`textInput${anotherReasonQs?.id}`]
      ) {
        methods.setError(`textInput${anotherReasonQs?.id}`, {});
        methods.clearErrors(`textInput${anotherReasonQs?.id}`);
        methods.unregister(`textInput${anotherReasonQs?.id}`);
        methods.setValue(`textInput${anotherReasonQs?.id}`, ' ');
      } else if (
        values[`selectField${reasonQs?.id}`] === ' ' &&
        values[`textInput${anotherReasonQs?.id}`] === ' ' &&
        !methods?.formState?.errors?.[`textInput${anotherReasonQs?.id}`] &&
        !methods?.formState?.errors?.[`selectField${reasonQs?.id}`]
      ) {
        methods.setValue(`textInput${anotherReasonQs?.id}`, '');
        methods.setValue(`selectField${reasonQs?.id}`, null);
      } else if (
        !values[`selectField${reasonQs?.id}`] &&
        values[`textInput${anotherReasonQs?.id}`] &&
        methods?.formState?.errors?.[`selectField${reasonQs?.id}`]
      ) {
        methods.clearErrors(`selectField${reasonQs?.id}`);
        methods.setValue(`selectField${reasonQs?.id}`, ' ');
      }

      if (
        values[`selectField${consequencesQs?.id}`] &&
        !values[`textInput${anotherConsequencesQs?.id}`] &&
        methods?.formState?.errors?.[`textInput${anotherConsequencesQs?.id}`]
      ) {
        methods.unregister(`textInput${anotherConsequencesQs?.id}`);
        methods.setError(`textInput${anotherConsequencesQs?.id}`, {});
        methods.clearErrors(`textInput${anotherConsequencesQs?.id}`);
        methods.setValue(`textInput${anotherConsequencesQs?.id}`, ' ');
      } else if (
        values[`selectField${consequencesQs?.id}`] === ' ' &&
        values[`textInput${anotherConsequencesQs?.id}`] === ' ' &&
        !methods?.formState?.errors?.[`textInput${anotherConsequencesQs?.id}`] &&
        !methods?.formState?.errors?.[`selectField${consequencesQs?.id}`]
      ) {
        methods.setValue(`textInput${anotherConsequencesQs?.id}`, '');
        methods.setValue(`selectField${consequencesQs?.id}`, null);
      } else if (
        !values[`selectField${consequencesQs?.id}`] &&
        values[`textInput${anotherConsequencesQs?.id}`] &&
        methods?.formState?.errors?.[`selectField${consequencesQs?.id}`]
      ) {
        methods.clearErrors(`selectField${consequencesQs?.id}`);
        methods.setValue(`selectField${consequencesQs?.id}`, ' ');
      }
    }
  }, [values]);

  const getAnswer = (qid: number) => {
    const found: any = answers?.length > 0 && answers.find((ans: any) => ans.questionId === qid);
    return found;
  };

  const getOptionIds = (type: string, data: any, id: number | string) => {
    const quest =
      questionnaire &&
      questionnaire?.questions?.find((qs) => qs.questionType === type && qs.id === id);

    if (quest) {
      if (typeof data === 'string') {
        return quest?.questionOptions?.find((qs: any) => qs.value === data)?.id;
      } else {
        const idList =
          data &&
          data?.length > 0 &&
          data?.map((i: string) => {
            const foundOpt = quest?.questionOptions?.find((qs: any) => qs.value === i);
            return foundOpt?.id;
          });
        return idList;
      }
    }
    return [];
  };

  const isAnswerValid = (data: IAddAnswerForm) => {
    const ansData = answers.find((ans: any) => ans.questionId === data.questionId);
    return ansData
      ? data && data.optionId
        ? ansData.optionId !== data.optionId
        : ansData && ansData.answer !== data.answer
      : true;
  };

  const checkType = (automatic?: any) => {
    if (isEditableForm) return true;
    else if (automatic) return false;
    else if (questionnaireId?.split('-')?.[0] === 'sf' && canEditStandardForm) return true;

    return (
      questionnaire?.type &&
      questionnaire?.type === 'E_CRF' &&
      isAddPermission
    );
  };

  const handleAnsBeforeSdv = (id: any, qType: string) => {
    if (id && (qType === 'TEXT_INPUT' || qType === 'NUMBER_VALUE')) {
      setIsSdv(true);
      addAnswersMutation([{
        answererId: parseInt(`${loggedInStudyUser?.id}`),
        questionId: id,
        optionId: null,
        answer: ' ',
      }]);
    }
  }

  const handleBlur = (
    id: any,
    qType: string,
    answerValue: any,
    answerId?: number | null,
    dateTimeType?: string,
    inputType?: string,
  ) => {
    const values = methods.watch();
    if (checkType() && id) {
      let answer = '';
      let optionId = null;
      switch (qType) {
        case 'TEXT_INPUT':
          answer = inputType === 'LONG' ?  values[`textArea${id}`] : values[`textInput${id}`];
          break;
        case 'NUMBER_VALUE':
          answer = values[`numberTxt${id}`];
          break;
        case 'CHECKBOX': {
          const checkAns = answerValue && getOptionIds('CHECKBOX', answerValue, id);
          if (checkAns) {
            optionId = checkAns;
            answer = (checkAns && checkAns?.join('')) || '';
            if (
              answerValue &&
              answerValue?.length > 0 &&
              answerValue?.find((val: any) => val?.trim().toLowerCase() === 'other')
            ) {
              if (values[`otherCheck-${id}`]) {
                answer = values[`otherCheck-${id}`];
              } else {
                return null;
              }
            }
          }
          break;
        }
        case 'DROPDOWN': {
          const dropAns = getOptionIds('DROPDOWN', answerValue, id);
          optionId = [dropAns];
          answer = dropAns;
          if (
            answerValue &&
            answerValue?.length > 0 &&
            answerValue?.trim().toLowerCase() === 'other'
          ) {
            if (values[`otherDropdown-${id}`]) {
              answer = values[`otherDropdown-${id}`];
            } else {
              return null;
            }
          }
          break;
        }
        case 'DATE_TIME':
          answer =
            answerValue && dateTimeType && format(new Date(answerValue), getFormat(dateTimeType));
          break;
        case 'RADIO_BUTTONS': {
          const radioAns = getOptionIds('RADIO_BUTTONS', answerValue, id);
          optionId = [radioAns];
          answer = radioAns;
          if (
            answerValue &&
            answerValue?.length > 0 &&
            answerValue?.trim().toLowerCase() === 'other'
          ) {
            if (values[`otherRadio-${id}`]) {
              answer = values[`otherRadio-${id}`];
            } else {
              return null;
            }
          }
          break;
        }
        case 'RATING':
          answer = answerValue;
          break;
        case 'SCALE':
          answer = answerValue;
          break;
        default:
          null;
      }
      const payload: any = {
        answererId: parseInt(`${loggedInStudyUser?.id}`),
        questionId: id,
        ...(optionId ? { optionId: optionId } : {}),
        answer: `${answer}`,
        ...(isStandardForm ? { answerId: answerId } : {}),
      };
      setSelectedQuestId(id);
      if (answer && isAnswerValid(payload)) {
        if (isStandardForm) submitStandardFormAnswer(payload);
        else {
          autoQueryCheck(id);
          answer && addAnswersMutation([payload]);
        }
      } else if (!isStandardForm && answerId && (!answer || answer === '' || answer === null)) {
        removeAnswerMutation(parseInt(`${answerId}`));
      }
    }
  };

  const handleDeleteAnswer = (answerId: number, qid: number) => {
    setSelectedQuestId(qid);
    removeAnswerMutation(answerId);
  };

  const getNameById = (values: any, id: number, qType: string, dateTimeType: string) => {
    const fndKey = Object.keys(values).find((key) => key.includes(`${id}`)) ?? '';
    const answerValue = values[fndKey];
    let answer, optionId;
    switch (qType) {
      case 'TEXT_INPUT':
        answer = values[`textInput${id}`];
        break;
      case 'TEXT_AREA':
        answer = values[`textArea${id}`];
        break;
      case 'NUMBER_VALUE':
        answer = values[`numberTxt${id}`];
        break;
      case 'CHECKBOX': {
        const checkAns = getOptionIds('CHECKBOX', answerValue, id);
        optionId = checkAns;
        answer = (checkAns && checkAns?.join('')) || '';
        if (
          answerValue &&
          answerValue?.length > 0 &&
          answerValue?.find((val: any) => val?.trim().toLowerCase() === 'other')
        ) {
          if (values[`otherCheck-${id}`]) {
            answer = values[`otherCheck-${id}`];
          } else {
            return null;
          }
        }
        break;
      }
      case 'DROPDOWN': {
        const dropAns = getOptionIds('DROPDOWN', answerValue, id);
        optionId = [dropAns];
        answer = dropAns;
        
        if (
          answerValue &&
          answerValue?.length > 0 &&
          answerValue?.trim().toLowerCase() === 'other'
        ) {
          if (values[`otherDropdown-${id}`]) {
            answer = values[`otherDropdown-${id}`];
          } else {
            return null;
          }
        }
        break;
      }
      case 'DATE_TIME':
        answer =
          values[`dateTime${id}`] &&
          `${new Date(values[`dateTime${id}`])}` !== 'Invalid Date' &&
          format(new Date(values[`dateTime${id}`]), getFormat(dateTimeType));
        break;
      case 'RADIO_BUTTONS': {
        const radioAns = getOptionIds('RADIO_BUTTONS', answerValue, id);
        optionId = [radioAns];
        answer = radioAns;
        if (
          answerValue &&
          answerValue?.length > 0 &&
          answerValue?.trim().toLowerCase() === 'other'
        ) {
          if (values[`otherRadio-${id}`]) {
            answer = values[`otherRadio-${id}`];
          } else {
            return null;
          }
        }
        break;
      }
      case 'RATING':
        answer = answerValue;
        break;
      case 'SCALE':
        answer = answerValue;
        break;
      default:
        null;
    }
    return {
      answererId: parseInt(`${loggedInStudyUser?.id}`),
      questionId: id,
      optionId,
      answer: `${answer}`,
    };
  };

  const checkError = (data: any) => {
    const errs: any = [];
    Object.keys(data)?.map((d: any) => {
      if (typeof data[d] === 'string' && data[d]?.trim()?.toLowerCase() === 'other') {
        if (d?.includes('selectField')) {
          const qid = d.split('selectField')?.[1];
          if (qid && data[`otherDropdown-${qid}`] === '') {
            errs?.push(`otherDropdown-${qid}`);
          }
        } else if (d?.includes('radioBtns')) {
          const qid = d.split('radioBtns')?.[1];
          if (qid && data[`otherRadio-${qid}`] === '') {
            errs?.push(`otherRadio-${qid}`);
          }
        }
      } else if (
        d?.includes('checkList') &&
        data[d]?.find((val: any) => val.trim().toLowerCase() === 'other')
      ) {
        if (d?.includes('checkList')) {
          const qid = d.split('checkList')?.[1];
          if (qid && data[`otherCheck-${qid}`] === '') {
            errs?.push(`otherCheck-${qid}`);
          }
        }
      }
    });
    setOtherError(errs);
    return errs && errs?.length > 0;
  };

  const onSubmit = (data: any) => {
    let answers: any = [];
    questionnaire.questions
      ?.filter((it: any) => it.id !== 1)
      ?.map((answ: any) => {
        answers.push({ ...getNameById(data, answ.id, answ?.questionType, answ?.dateTimeType) });
      });

    const keyId =
      questionnaire?.type === 'AE'
        ? questionnaire.questions?.find(
            (qs: any) => qs?.questionTitle?.includes('Device issue') && qs?.sortOrder === 14,
          )?.id
        : questionnaire?.type === 'PROTOCOL_DEVIATION'
          ? questionnaire.questions?.find((qs: any) => qs?.sortOrder == 2)?.id
          : questionnaire.questions?.find((qs: any) =>
              qs?.questionTitle?.includes('Free description of the even'),
            )?.id ?? '';
    const nameKey = Object.keys(data).find((it: string) => it?.includes(`${keyId}`)) ?? '';
    const aeSubtypeId =
      questionnaire.questions?.find((qs: any) => qs?.questionTitle?.includes('AE /SAE /DD'))?.id ??
      '';
    const aeSubtypeKey =
      Object.keys(data).find((it: string) => it?.includes(`${aeSubtypeId}`)) ?? '';

    if (questionnaire?.type === 'AE') {
      if (questionnaire?.questions?.[1]?.automatic) {
        const fndCenter = centers?.find((cn: any) => cn?.id === selectedCenter);
        answers = answers?.map((answ: any) => {
          if (answ?.questionId === questionnaire?.questions?.[1]?.id) {
            return { ...answ, answer: fndCenter?.address?.country?.countryIsoCode };
          } else return answ;
        });
      }
      if (questionnaire?.questions?.[2]?.automatic) {
        const fndCenter = centers?.find((cn: any) => cn?.id === selectedCenter);
        answers = answers?.map((answ: any) => {
          if (answ?.questionId === questionnaire?.questions?.[2]?.id) {
            return {
              ...answ,
              answer: fndCenter?.address?.country?.countryIsoCode + selectedCenter,
            };
          } else return answ;
        });
      }
      if (questionnaire?.questions?.[3]?.automatic) {
        const fndCenter = centers?.find((cn: any) => cn?.id === selectedCenter);
        answers = answers?.map((answ: any) => {
          if (answ?.questionId === questionnaire?.questions?.[3]?.id) {
            return {
              ...answ,
              answer: `0${participantId}${fndCenter?.address?.country?.countryIsoCode}${selectedCenter}`,
            };
          } else return answ;
        });
      }
      if (questionnaire?.questions?.[4]?.automatic) {
        answers = answers?.map((answ: any) => {
          if (answ?.questionId === questionnaire?.questions?.[4]?.id) {
            return { ...answ, answer: uuidv4() };
          } else return answ;
        });
      }
    }

    const foundNullAns = answers?.find((answ: any) => answ && !answ?.answer);
    if (foundNullAns) {
      const isError = checkError(data);
      if (isError) return isError;
    }

    const payload: any = {
      studyId: parseInt(`${loggedInStudyUser?.id}`),
      name: data?.[nameKey],
      visit: data['selectField1'],
      patientId: Number(participantId),
      questionnaireId: Number(questionnaireId?.split('-')?.[1]),
      answers: [...answers],
      ...(questionnaire?.type !== 'TERMINATION' &&
        questionnaire?.type !== 'PROTOCOL_DEVIATION' && {
          aeSubtype:
            questionnaire?.type === 'PROTOCOL_DEVIATION'
              ? 'PROTOCOL_DEVIATION'
              : data?.[aeSubtypeKey],
        }),
    };
    methods.reset();
    submitStandardForm(payload);
  };
  const getQuestionAnswer = (qsId: number, questionType: string) => {
    const fndAnswr = terminationSubmissionData?.answers?.find((qs: any) => qs?.questionId === qsId);
    const fndQs = questionnaire.questions?.find((qs: any) => qs.id === qsId);

    let qsAnsw = null;
    switch (questionType) {
      case 'TEXT_INPUT':
      case 'NUMBER_VALUE':
        qsAnsw = fndAnswr?.answer;
        break;
      case 'DATE_TIME':
        qsAnsw = fndAnswr?.answer?.split('-')?.[0];
        break;
      case 'CHECKBOX':
      case 'RADIO_BUTTONS':
      case 'DROPDOWN':
        qsAnsw = '';
        qsAnsw = fndQs?.questionOptions?.find(
          (op: any) => op?.id === Number(fndAnswr?.answer),
        )?.label;
        break;
      case 'RATING':
        qsAnsw = '';
        break;
      case 'FILE_UPLOAD':
        qsAnsw = '';
        break;
      default:
        null;
    }

    return qsAnsw;
  };

  const titleMap: any = {
    'AE/SAE/DD': <FormattedMessage id='standard-forms.ae-sae-dd' />,
    'Protocol Deviation': <FormattedMessage id='standard-forms.protocole-deviation' />,
    'Termination form': <FormattedMessage id='standard-forms.termination-form' />,
    '': '',
  };

  const getRepeatedQuestion = (questionsList: any, question: any) => {
    if (question?.questionType === 'REPEATED') {
      const parentQuestions = questionsList?.filter(
        (item: any) => item?.parentQuestionId === question?.id,
      );
      return { ...question, parentQuestions: parentQuestions };
    }
  };

  const getSubFilterQuest: any = (questions: any, quest: any) => {
    return (
      (questions &&
        questions?.length > 0 &&
        questions?.filter((q: any) => q?.titleQuestionId === quest?.id)) ||
      []
    );
  };

  let questNo = 0;

  return (
    <div
      className={clsx(
        'flex-1 text-left h-full overflow-y-auto rounded-lg no-scrollbar bg-white',
        ' rounded-lg',
        !isInModal && 'border-[1px] border-purple',
      )}
    >
      {questionnaire && questionnaire?.eproSchedule && questionnaire?.type === 'E_PRO' ? (
        <EproScheduleQuestionaire refetch={refetch} questionnaire={questionnaire} />
      ) : (
        <>
          {!isInModal ? (
            <div className='flex justify-between border-b-1 border-purple'>
              <div className="pl-5 py-4 text-blue-ocean-deep text-[24px] font-medium">
                {questionnaireId?.split('-')?.[0] === 'sf'
                  ? titleMap[questionnaire?.title?.trim() ?? ''] ?? ''
                  : questionnaire?.title}
              </div>
              <div className='flex justify-end items-center gap-x-2 mr-4 relative'>
                <span className='flex mr-2 relative'>
                  <Tooltip
                    className='ml-[99px] -mt-[5px]'
                    text={intl.formatMessage({ id: 'questionnaire-field.verticalMessage' })}
                  >
                    <span
                      className={`text-sm ${
                        styleType === 'vertical' ? '!text-blue-normal' : '!text-blue-oil'
                      }`}
                    >
                      {' '}
                      <FormattedMessage id='questionnaire-field.vertical' />
                    </span>
                  </Tooltip>
                  <ToggleButton
                    onChange={() =>
                      setStyleType(styleType === 'vertical' ? 'horizontal' : 'vertical')
                    }
                    className='!mx-3'
                    defaultChecked={styleType === 'horizontal'}
                    borderClass='!border-blue-oil !bg-white !border'
                    dotClass='!bg-blue-oil'
                  />
                  <Tooltip
                    className='ml-[80px] -mt-[5px]'
                    text={intl.formatMessage({ id: 'questionnaire-field.horizontalMessage' })}
                  >
                    <span
                      className={`text-sm ${
                        styleType === 'horizontal' ? '!text-blue-normal' : '!text-blue-oil'
                      }`}
                    >
                      {' '}
                      <FormattedMessage id='questionnaire-field.horizontal' />
                    </span>
                  </Tooltip>
                </span>
              </div>
            </div>
          ) : null}

          <div
            className={clsx(
              standardFormAns ? '' : 'pt-10',
              'text-gray-dark leading-6 pb-[28px] pt-[8px] ',
            )}
          >
            {questionnaire?.type === 'CONSENT' ? (
              <div>
                <div className='text-left' dangerouslySetInnerHTML={{ __html: consentData }} />
              </div>
            ) : (
              <div>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className='questionValues  max-w-full h-max mb-1'>
                      {filteredQuestions?.length ? (
                        filteredQuestions?.map((question: any, i: number) => {
                          const foundAns: any = question?.id && getAnswer(question?.id);

                          const fndStandardFormAns = standardFormAns?.find(
                            (answ: any) => answ.questionId === question?.id,
                          );

                          const filteredSubQuestions: any = getSubFilterQuest(
                            questionnaire?.questions,
                            question,
                          );
                          if ([1, 2, 3, 4].includes(i) && questionnaire?.type === 'AE') return null;
                          questNo++;
                          return (
                            <>
                              <Question
                                methods={methods}
                                key={i}
                                questionNumber={questionnaire?.type === 'AE' ? questNo : i + 1}
                                isLastQuestion={`${i}` === `${filteredQuestions.length - 1}`}
                                repeatedQuestions={getRepeatedQuestion(
                                  questionnaire?.questions,
                                  question,
                                )}
                                handleAnsBeforeSdv={handleAnsBeforeSdv}
                                isQuestionEmpty={
                                  question?.questionType === 'TITLE' &&
                                  filteredSubQuestions &&
                                  filteredSubQuestions?.length === 0
                                }
                                {...question}
                                {...(canEditStandardForm &&
                                  question?.questionType === 'DATE_TIME' && {
                                    dateTimeType: 'DATE',
                                  })}
                                checkType={checkType}
                                styleType={styleType}
                                otherError={otherError}
                                dateTimeType={question?.dateTimeType}
                                numberType={question?.numberType}
                                automatic={question?.automatic}
                                uploadFileAns={uploadFileAns}
                                onBlur={(e) => {
                                  (!canEditStandardForm ||
                                    isStandardForm ||
                                    questionnaire.type === 'E_CRF') &&
                                  (isInModal || questionnaire.type === 'E_CRF')
                                    ? handleBlur(
                                        question?.id,
                                        question?.questionType || '',
                                        e,
                                        isStandardForm ? fndStandardFormAns?.id : foundAns?.id,
                                        question?.dateTimeType || '',
                                        question?.inputType
                                      )
                                    : null;
                                }}
                                handleDeleteAnswer={() =>
                                  handleDeleteAnswer(foundAns?.id, question?.id)
                                }
                                refetch={refetch}
                                isStandardForm={canEditStandardForm}
                                questionairetype={questionnaire.type}
                                title={question.questionTitle || ''}
                                answerData={foundAns || {}}
                                answer={
                                  foundAns && foundAns?.optionId
                                    ? foundAns?.optionId
                                    : foundAns?.answer
                                }
                                showAnswer={
                                  isTerminationFormAndSubmitted &&
                                  terminationSubmissionData &&
                                  getQuestionAnswer(question?.id, question?.questionType)
                                }
                                updateSDV={updateSDV}
                                isSiteStaff={isSiteStaff}
                              />
                              {filteredSubQuestions &&
                                filteredSubQuestions?.length > 0 &&
                                filteredSubQuestions?.map((quest: any, i: number) => {
                                  const foundAns: any = quest?.id && getAnswer(quest?.id);

                                  return (
                                    <Question
                                      key={i}
                                      methods={methods}
                                      questionNumber={i}
                                      isLastQuestion={false}
                                      handleAnsBeforeSdv={handleAnsBeforeSdv}
                                      isSubLastQuestion={
                                        `${i}` === `${filteredSubQuestions.length - 1}`
                                      }
                                      {...quest}
                                      {...(canEditStandardForm &&
                                        quest?.questionType === 'DATE_TIME' && {
                                          dateTimeType: 'DATE',
                                        })}
                                      checkType={checkType}
                                      styleType={styleType}
                                      otherError={otherError}
                                      automatic={quest?.automatic}
                                      dateTimeType={quest?.dateTimeType}
                                      numberType={quest?.numberType}
                                      uploadFileAns={uploadFileAns}
                                      onBlur={(e) => {
                                        (!canEditStandardForm ||
                                          isStandardForm ||
                                          questionnaire.type === 'E_CRF') &&
                                        (isInModal || questionnaire.type === 'E_CRF')
                                          ? handleBlur(
                                              quest?.id,
                                              quest?.questionType || '',
                                              e,
                                              isStandardForm
                                                ? fndStandardFormAns?.id
                                                : foundAns?.id,
                                              quest?.dateTimeType || '',
                                            )
                                          : null;
                                      }}
                                      handleDeleteAnswer={() =>
                                        handleDeleteAnswer(foundAns?.id, quest?.id)
                                      }
                                      refetch={refetch}
                                      isStandardForm={false}
                                      questionairetype={questionnaire.type}
                                      title={quest.questionTitle || ''}
                                      answerData={foundAns || {}}
                                      answer={
                                        foundAns && foundAns?.optionId
                                          ? foundAns?.optionId
                                          : foundAns?.answer
                                      }
                                      showAnswer={
                                        isTerminationFormAndSubmitted &&
                                        terminationSubmissionData &&
                                        getQuestionAnswer(quest?.id, quest?.questionType)
                                      }
                                      updateSDV={updateSDV}
                                      isSiteStaff={isSiteStaff}
                                    />
                                  );
                                })}
                            </>
                          );
                        })
                      ) : (
                        <p className='!ml-6'>
                          <FormattedMessage id='step.noQuestionaireMessage' />
                        </p>
                      )}
                    </div>
                    {canEditStandardForm &&
                    !isStandardFormEditMode &&
                    questionnaire.type !== 'E_CRF' ? (
                      <Button
                        className='w-[166px] h-8 hover:bg-blue-ocean-deep bg-blue-ocean-deep rounded-full text-white mx-auto mt-6 ml-[40%] text-sm'
                        // onClick={() => {}}
                      >
                        <FormattedMessage id='patient.submitAE' />
                      </Button>
                    ) : null}
                  </form>
                </FormProvider>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
