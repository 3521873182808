import { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'classnames';
import { FiCheck, FiChevronDown } from 'react-icons/fi';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useIntl } from 'react-intl';
import Tooltip from '../Tooltip';
import ReactDOM from 'react-dom';
import { CustomControl } from '../Select/CustomControl';

interface Option {
  label: string;
  value: string | number;
}

interface IProps extends UseControllerProps<any> {
  placeholder?: string;
  options: Option[];
  customControl?: CustomControl;
  label?: string;
  disabled?: boolean;
  isTooltip?: boolean;
  onBlur?: (e: any) => void;
  className?: string;
  buttonClassName?: string;
  iconClass?: string;
  style?: any;
  optionsClassName?: string;
  errors?: FieldErrors<FieldValues>;
  mainClass?: string;
  selectOptClass?: string;
  noErrorMessage?: boolean;
  optWrapperClassName?: string;
  noTranslation?: boolean;
  isCheckIcon?: boolean;
  onFocus?: any;
}

const DropdownOptions = ({
  optWrapperClassName,
  dropdownPosition,
  filteredOpts,
  optionsClassName,
  isCheckIcon,
  selectedOption,
  handleSelectOption,
  noTranslation,
  isOpen,
}: any) => {
  const intl = useIntl();
  const dropdownRoot: any = document.getElementById('dropdown-root'); // Portal target

  return (
    ReactDOM.createPortal(
      <div
        className={clsx(
          'dropdown-portal absolute mt-0.5 flex z-100 box-content max-h-40 no-scrollbar bg-base-white border border-gray-300 rounded-b-xl overflow-auto',
          isOpen ? 'min-h-fit border overflow-y-auto' : 'hidden',
          optWrapperClassName,
        )}
        style={{
          position: 'fixed', // Fixed positioning for dropdown
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          zIndex: 10, // Ensure z-index is high enough to be clickable
        }}
      >
        <ul className="dropdown-portal-ul w-full !p-0">
          {filteredOpts?.length > 0 &&
            filteredOpts?.map((option: any, index: number) => (
              <li
                className={clsx(
                  'dropdown-portal-li hover:bg-blue-white-ice text-blue-ocean-deep flex items-center break-normal transition-colors px-3 py-2 cursor-pointer text-left',
                  optionsClassName,
                  isCheckIcon && option?.value === selectedOption ? 'font-semibold' : 'font-normal',
                )}
                key={`${option?.value}-${index}`}
                onClick={() => handleSelectOption(option?.value)}
                data-value={option?.value}
              >
                {isCheckIcon && option?.value === selectedOption && <FiCheck className='font-bold text-blue-ocean-deep mr-1 stroke-[4px] !h-4 !w-4 !min-w-4 !min-h-4' />}
                {noTranslation ? option?.label : intl.formatMessage({ id: `${option?.label}` })}
              </li>
            ))}
        </ul>
      </div>,
      dropdownRoot, // The target element for the portal
    )
  );
}

function SearchableSelect({
  placeholder,
  options,
  isTooltip,
  customControl,
  label,
  disabled,
  style,
  onBlur,
  buttonClassName,
  errors,
  className,
  iconClass,
  mainClass,
  selectOptClass,
  optionsClassName,
  noErrorMessage = false,
  optWrapperClassName,
  noTranslation,
  onFocus,
  isCheckIcon,
  ...rest
}: IProps) {
  const intl = useIntl();
  const props = customControl ? customControl : useController(rest)?.field;
  const errorMsg = errors?.[rest.name]?.message as string | undefined;
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [selectedOption, setSelectedOption] = useState(props?.value);
  const [filteredOpts, setFilteredOpts] = useState<any>();
  const [searchQuery, setSearchQuery] = useState(''); // Search input state
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 }); // Dropdown position

  // Recalculate dropdown position
  const calculateDropdownPosition = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY, // Adjust for vertical scroll
        left: rect.left + window.scrollX,  // Adjust for horizontal scroll
        width: rect.width,
      });
    }
  };

  useEffect(() => {
    calculateDropdownPosition();
  }, [isOpen]);

  // Recalculate the position on scroll and resize
  useEffect(() => {
    const handleScrollOrResize = (e: any) => {
      if (e?.target?.classList && !e?.target?.classList[0]?.includes('dropdown-portal') && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('scroll', handleScrollOrResize, isOpen);
    window.addEventListener('resize', handleScrollOrResize, isOpen);

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize, isOpen);
      window.removeEventListener('resize', handleScrollOrResize, isOpen);
    };
  }, [isOpen]);

  useEffect(() => {
    setSelectedOption(props?.value);
  }, [props?.value]);

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  useEffect(() => {
    if (selectedOption && isCheckIcon && searchQuery === '') {
      const newOpts = options?.filter((opt: any) => opt?.value && !selectedOption?.includes(opt?.value));
      const selectedOpts = options?.find((opt: any) => opt?.value && selectedOption?.includes(opt?.value));
      setFilteredOpts([ selectedOpts, ...newOpts ]);
    } else if (options && options?.length > 0 && searchQuery === '') {
      setFilteredOpts(options);
    }
  }, [options, selectedOption, searchQuery]);

  const handleSelectOption = (optionValue: string | number) => {
    let newValue: any = optionValue;
    if (props?.value && props?.value === optionValue) {
      newValue = null;
    }
    setSelectedOption(newValue);
    props.onChange(newValue as string);
    onBlur?.(newValue);
    onFocus?.();
    toggleOpen();
  };

  useEffect(() => {
    searchQuery && setFilteredOpts(
      options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, options]);

  const handleOutsideClick = useCallback(
    (event: any | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        if (event?.target?.classList && !event?.target?.classList[0]?.includes('dropdown-portal')) {
          setIsOpen(false);
        }
      }
    },
    [setIsOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getLabel = () => {
    return options?.find((option) => option?.value && `${selectedOption}` === `${option?.value}`)?.label;
  };

  const renderBtn = () => {
    return (
      <button
        type="button"
        disabled={disabled}
        id={`button-${rest.name}`}
        onClick={toggleOpen}
        className={clsx(
          `flex w-full flex-1 gap-x-4 outline-none focus:outline-none ring-0 items-center p-3 rounded-[0.625rem] ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`,
          buttonClassName,
        )}
      >
        <input
          type="text"
          value={selectedOption ? noTranslation ? getLabel() : intl.formatMessage({ id: `${getLabel()}` }) : searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setIsOpen(true);
          }}
          disabled={disabled}
          placeholder={placeholder}
          className={`w-full !text-blue-ocean-deep text-base truncate flex-1 !text-left text-ellipsis focus:outline-none focus:ring-0 focus:border-none border-none outline-none ${selectOptClass}`}
        />
        <FiChevronDown
          className={clsx('transition-all ', iconClass ? iconClass : isOpen && ' -rotate-180')}
        />
      </button>
    );
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={`button-${rest.name}`}
          ref={labelRef}
          className="text-left w-full text-blue-ocean-deep mb-2 inline-block leading-6 font-semibold"
        >
          {label}
        </label>
      )}

      <div
        ref={containerRef}
        style={style}
        className={clsx(
          isOpen ? 'border border-b-transparent rounded-b-none' : 'border',
          `rounded-[0.625rem] flex items-center relative max-h-[3rem] text-blue-ocean-deep ${errors?.[rest.name]?.message ? 'border-danger-100 border-2' : 'border-blue-ocean-deep'}`,
          mainClass,
          isOpen && 'open',
        )}
      >
        {isTooltip ? (
          <Tooltip position="right" arrowClass="top-[6px]" className="w-max" text={isTooltip ? getLabel() : ''}>
            {renderBtn()}
          </Tooltip>
        ) : (
          renderBtn()
        )}
      </div>
      <DropdownOptions
        optWrapperClassName={optWrapperClassName}
        filteredOpts={filteredOpts}
        optionsClassName={optionsClassName}
        isCheckIcon={isCheckIcon}
        selectedOption={selectedOption}
        handleSelectOption={handleSelectOption}
        noTranslation={noTranslation}
        dropdownPosition={dropdownPosition}
        isOpen={isOpen}
      />
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default memo(SearchableSelect);
