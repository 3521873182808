export const BoldIcon = (props: any) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
  >
    <path
      d='M5.25 10.5H13.125C14.0533 10.5 14.9435 10.8687 15.5999 11.5251C16.2563 12.1815 16.625 13.0717 16.625 14C16.625 14.9283 16.2563 15.8185 15.5999 16.4749C14.9435 17.1313 14.0533 17.5 13.125 17.5H5.25V10.5Z'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.25 3.5H12.25C13.1783 3.5 14.0685 3.86875 14.7249 4.52513C15.3813 5.1815 15.75 6.07174 15.75 7C15.75 7.92826 15.3813 8.8185 14.7249 9.47487C14.0685 10.1313 13.1783 10.5 12.25 10.5H5.25V3.5Z'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const ItalicIcon = (props: any) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
  >
    <path
      d='M12.25 17.5H4.375'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.625 3.5H8.75'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.125 3.5L7.875 17.5'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const UnderlinedIcon = (props: any) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
  >
    <path
      d='M3.5 18.375H17.5'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.25 2.625V8.75C5.25 10.1424 5.80312 11.4777 6.78769 12.4623C7.77226 13.4469 9.10761 14 10.5 14C11.8924 14 13.2277 13.4469 14.2123 12.4623C15.1969 11.4777 15.75 10.1424 15.75 8.75V2.625'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const PickColorIcon = (props: any) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='19'
    viewBox='0 0 16 18'
    fill='none'
  >
    <path
      d='M8 1L2 13'
      stroke='#012C54'
      strokeWidth='0.730435'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 1L14 13'
      stroke='#012C54'
      strokeWidth='0.730435'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.5 8L4.5 8'
      stroke='#012C54'
      strokeWidth='0.730435'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 17.4H15'
      stroke='#012C54'
      strokeWidth='0.913043'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
