import React, { useState, useEffect, useRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { Tooltip } from '../../../../../components/common';

interface ScaleInputProps extends UseControllerProps<any> {
  start: number;
  end: number;
  increment: number;
  name: string;
  mainClass?: string;
  inputClass?: string;
  numberClass?: string;
  legendClass?: {
    start?: string;
    end?: string;
  };
  customControl?: any;
  onChange: (value: number) => void;
  onBlur?: (value: any) => void;
  onFocus?: () => void;
  startLegend?: any;
  endLegend?: any;
  islegendSide?: any;
  isMobileLayout?: boolean;
}

const ScaleInput: React.FC<ScaleInputProps> = ({
  start,
  end,
  increment,
  onChange,
  name,
  control,
  disabled,
  onBlur,
  mainClass,
  inputClass,
  numberClass,
  legendClass,
  onFocus,
  startLegend,
  endLegend,
  islegendSide,
  isMobileLayout
}) => {
  const [value, setValue] = useState(start);
  const [percentage, setPercentage] = useState(0);
  const rangeRef = useRef<HTMLInputElement>(null);
  const props = useController({ control, name, disabled })?.field;

  useEffect(() => {
    setPercentage(((value - start) / (end - start)) * 100);
  }, [value]);
  const diff = startLegend && endLegend ? end - start : -1;

  useEffect(() => {
    props?.value ? setValue(props?.value) : setValue(start);
  }, [props?.value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value < start || +event.target.value > end) return;
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const divisions = [];
  if (diff > 10) {
    const increment = diff / 10; // Calculate the increment for 11 segments
    for (let i = start; i <= end; i += increment) {
      divisions.push(Math.round(i)); // Round to avoid floating-point issues
    }
  } else {
    for (let i = start; i <= end; i += increment) {
      divisions.push(i);
    }
  }

  return (
    <div className={`${islegendSide ? 'w-[410px] !pl-0' : 'w-[230px] pl-4'} ${mainClass}`}>
      <div className='flex items-baseline	gap-x-2'>
        <div className='relative mb-4 mt-7 scaleSlider'>
          <input
            ref={rangeRef}
            type='range'
            name={name}
            min={start}
            max={end}
            disabled={disabled}
            step={increment}
            value={value}
            onFocus={onFocus}
            onChange={handleInputChange}
            onMouseUp={(e: any) => {
              onBlur?.(e.target.value);
            }}
            style={{
              background: `linear-gradient(to right, #004381 ${percentage}%, rgb(205,217,230) ${percentage}%)`,
            }}
            className={`${islegendSide ? 'w-[410px]' : 'w-[230px]'} ${inputClass} appearance-none !accent-base-white bg-transparent h-1.5 rounded-full ${disabled ? 'cursor-not-allowed ' : 'cursor-pointer'}`}
          />
          <div
            className={`percent absolute bg-blue-ocean-deep text-white text-xs rounded py-1 ${isMobileLayout ? 'px-1.5' : 'px-2'}`}
            style={{
              left: `calc(${percentage}% - 12px)`,
              top: isMobileLayout ? '-42px' : '-30px',
              whiteSpace: 'nowrap',
            }}
          >
            <p className={`${isMobileLayout ? 'rotate-90 py-2' : 'py-0.5'}`}>{value}</p>
            <span
              className='absolute block w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-blue-ocean-deep'
              style={{ top: '100%', left: '50%', transform: 'translateX(-50%)' }}
            />
          </div>
          <div
            className={`absolute top-0 left-0 ${islegendSide ? 'w-[410px]' : 'w-[230px]'} ${numberClass} h-1 flex justify-between px-1.5`}
          >
            {divisions.map((division, index) => (
              <div key={index} className='relative flex items-center justify-center'>
                <p className='absolute border-[0.905px] border-blue-oil h-1 bg-blue-oil rounded-full block top-6 !important'></p>
                <span className='division absolute top-7 text-[9px] text-blue-oil break-keep'>{division}</span>
              </div>
            ))}
          </div>
          {!isMobileLayout && (
            <div className='mt-[20px] flex justify-between'>
              {startLegend && (
                <Tooltip
                  className={`!p-[6px] !rounded-none text-wrap break-all ${islegendSide ? 'max-w-[185px]' : 'max-w-[105px]'}`}
                  text={startLegend}
                >
                  <p
                    className={`${islegendSide ? 'max-w-[185px]' : 'max-w-[105px]'} truncate w-auto text-[10.86px] text-blue-oil ${legendClass?.start}`}
                  >
                    {startLegend}
                  </p>
                </Tooltip>
              )}
              {endLegend && (
                <Tooltip
                  className={`!p-[6px] !rounded-none text-wrap break-all ${islegendSide ? 'max-w-[185px]' : 'max-w-[105px]'}`}
                  text={endLegend}
                >
                  <p
                    className={`${islegendSide ? 'max-w-[185px]' : 'max-w-[105px]'} truncate w-auto text-[10.86px] text-blue-oil ${legendClass?.end}`}
                  >
                    {endLegend}
                  </p>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScaleInput;
