import React, { useState } from 'react';
import { TextInput, Select } from 'components/common';
import { IRoleCard } from 'types/roles';
import { ICreateStudyUser } from 'types/user';
import clsx from 'classnames';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { createStudyUser } from 'services/api/users';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

interface IProps {
  roles?: IRoleCard[];
  usersRefetch: any;
}

const CreateNewUser: React.FC<IProps> = ({ usersRefetch, roles = [] }: IProps) => {
  const { studyId } = useParams();
  const [formSubmit, setFormSubmit] = useState(false);
  const [newUserRole, setNewUerRole] = useState(null);
  const [formValues, setFormValues] = useState({
    newUserName: '',
    email: '',
  });
  const intl = useIntl();

  const { mutate: createStudyUserMutation } = useMutation<
    ICreateStudyUser,
    unknown,
    ICreateStudyUser,
    unknown
  >((data: ICreateStudyUser) => createStudyUser(data), {
    onSuccess: () => {
      setFormSubmit(false);
      setNewUerRole(null);
      setFormValues({ newUserName: '', email: '' });
      usersRefetch();
    },
    onError(error: any) {
      setFormSubmit(false);
      toast.error(error?.response?.data?.message);
    },
  });

  const handleCreateStudyUser = () => {
    const { newUserName, email } = formValues;
    const payload: ICreateStudyUser = {
      firstName: newUserName,
      roleId: newUserRole ?? 1,
      email: email,
      studyId: Number(studyId) ?? 1,
      countryName: 'Global',
    };
    createStudyUserMutation(payload);
  };

  const handleKeyDown = (e: any) => {
    setFormSubmit(true);
    if (e.key === 'Enter' && !e.target.value) {
      // do nothing
    } else if (e.key === 'Enter' && e.target.value) {
      handleCreateStudyUser();
    }
  };
  const validateEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getRolesOptions = roles
    .filter((role: IRoleCard) => role.category !== 'SITE_STAFF')
    .map((role: IRoleCard) => ({ label: role?.name, value: role.id }));

  return (
    <tr
      className='w-[1194px]  border-t-2 border-2-gray-very-light border-b-2 border-b-gray-very-light cursor-pointer hover:bg-slate-200 hover:bg-blue-light-100 hover:!rounded-lg hover:border-[rgba(9,115,212,0.35)] '>
      <td className={clsx('w-[289px] text-[#6C757D] text-left  text-sm py-1')}>
        <TextInput
          control={{
            value: formValues.newUserName,
            onChange: (e) => setFormValues({ ...formValues, newUserName: e.target.value }),
          }}
          name={'newUserName'}
          placeholder={intl.formatMessage({ id: 'patient.name' }) + '...'}
          noTranslation={true}
          className={clsx(
            'h-full !mt-0 [&>input]:bg-white [&>input]:h-[28px] [&>input]:mt-0 [&>input]:pl-2 pt-0 pb-0 [&>input]:rounded [&>input]:w-[289px] ',
          )}
          inputClassName='!border-[1px] !border-blue-normal !px-1 !py-1 !text-[14px]'
          errors={
            formSubmit && !formValues.newUserName
              ? { newUserName: { message: `${intl.formatMessage({ id: 'error.nameIsRequired' })}` } }
              : {}
          }
          noErrorMessage={true}
          onKeyDown={handleKeyDown}
        />
      </td>
      <td className='px-2 w-[259px] text-[#6C757D] text-left text-sm py-1 h-[28px]'>
        <Select
          customControl={{
            value: newUserRole,
            onChange: (value: any) => setNewUerRole(value),
          }}
          isSearchable={true}
          isTooltip={true}
          name={'newUserRole'}
          noTranslation={true}
          placeholder='New role...'
          options={getRolesOptions}
          mainClass='w-full h-[28px] border-[1px] border-blue-normal !rounded-[4px]'
          errors={
            (formSubmit && !newUserRole
              ? { newUserRole: { message: `${intl.formatMessage({ id: 'users.roleRequired' })}` } }
              : {}) as any
          }
          className={`bg-white    ${newUserRole !== 'all' ? 'bg-blue-normal' : 'bg-base-white'} `}
          buttonClassName={`w-full !py-0 !px-1  ${newUserRole === 'all' ? '[&>span]:text-white [&>svg]:text-white' : '[&>span]:text-blue-ocean-deep [&>svg]:text-blue-ocean-deep'}`}
          noErrorMessage={true}
          optionsClassName="hover:!bg-blue-light-100"
          optWrapperClassName="border-purple"
        />
      </td>
      <td className='px-2 w-[218px] text-[#6C757D] text-left  text-sm py-1'>
        <TextInput
          control={{
            value: formValues.email,
            onChange: (e) => setFormValues({ ...formValues, email: e.target.value }),
          }}
          name={'email'}
          placeholder={intl.formatMessage({ id: 'email' }) + '...'}
          noTranslation={true}
          className={clsx(
            'h-full !mt-0 [&>input]:bg-white [&>input]:border-blue-normal [&>input]:bg-blue-light [&>input]:h-[28px] [&>input]:mt-0 [&>input]:pl-2 pt-0 pb-0 [&>input]:rounded [&>input]:w-[218px] ',
          )}
          errors={
            (formSubmit && !formValues.email) || (formSubmit && !validateEmail(formValues.email))
              ? { email: { message: `${intl.formatMessage({ id: 'users.emailRequired' })}` } }
              : {}
          }
          inputClassName='border-r-none border-l-none border-t-none !px-1 !py-1 !text-[14px]'
          onKeyDown={handleKeyDown}
          noErrorMessage={true}
        />
      </td>
      <td className='px-2 w-[209px] text-[#6C757D] text-left text-sm py-[14px]'></td>
      <td className='w-[30px] -mr-4'></td>
      <td className='w-[30px] -mr-4'></td>
    </tr>
  );
};

export default CreateNewUser;
