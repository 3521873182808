import { useAppointment } from 'react-hook-calendar';
import AppointmentPopUp from './AppointmentPopup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { format, isAfter, isBefore } from 'date-fns';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { MeetingStatusR, getAppointmentVideo } from 'services/api/appointment';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { rtcProps } from 'services/api/auth';

const Appointment = (props: { 
  start: Date; end: Date; title: string; color: string, 
  id: number, name: string, appointment: any, centerUser: any,
  setOpenModal: any, setModalData: any, isSiteStaff: any
}) => {
  const navigate = useNavigate();
  const { participantId, studyId } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(-1);
  const [joinCall, setJoinCall] = useState(false);
  const [popData, setPopData] = useState<any>({});
  const [appointId, setAppointId] = useState<any>(-1);
  const { style, inView } = useAppointment(props);

  const { data: videoData, refetch: videoRefetch, isLoading } = useQuery({
    queryKey: ['appointmentsVideo'],
    queryFn: () => appointId !== -1 && getAppointmentVideo(appointId),
  });

  const handlePopup = () => {
    setIsOpen(props.id);
    setPopData({
      id: props.id,
      title: props.title,
      start: props.start,
      end: props?.end,
      dateTime: `${format(new Date(props.start), 'MMMM dd hh:mm aaaa')} to ${format(new Date(props.end), 'hh:mm aaaa')} `,
      appointment: props.appointment,
      centerUser: props.centerUser,
    })
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        setIsOpen(-1);
      }
    },
    [setIsOpen],
  );

  const handleEdit = () => {
    props?.setOpenModal(true);
    props?.setModalData({
      id: props.id,
      title: props.title,
      start: props.start,
      end: props?.end,
      dateTime: `${format(new Date(props.start), 'MMMM dd hh:mm aaaa')} to ${format(new Date(props.end), 'hh:mm aaaa')} `,
      appointment: props.appointment,
      centerUser: props.centerUser,
    })
  }

  async function handleJoinCall() {
    try {
      const video = (await navigator.mediaDevices.enumerateDevices()).find(
        (device) => device.kind === 'videoinput',
      );

      if (video) {
        navigate(
          `/video-call/${rtcProps.appId}/${props.appointment?.videoChannel}/${encodeURIComponent(
            videoData,
          )}/${participantId}/${studyId}`, {
            state: {
              id: props.id,
              title: props.title,
              start: props.start,
              end: props?.end,
              appointment: props.appointment,
              centerUser: props.centerUser,
              page: 'appointment'
            }
          }
        );
      } else {
        throw new Error('You must connect a video device in order to join the video call.');
      }
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    }
  }

  useEffect(() => {
    if (videoData && joinCall) {
      handleJoinCall();
    }
  }, [videoData, joinCall]);

  useOutsideClick(containerRef, handleOutsideClick);

  const checkType = () => {
    return props?.appointment?.appointmentType !== 'CONSENT' && props?.appointment?.appointmentStatus !== MeetingStatusR.DECLINED &&
      props?.appointment?.appointmentStatus !== MeetingStatusR.CONFIRMED
  }

  const checkJoin = () => {
    return checkType() && isAfter(new Date(), new Date(props?.start)) && isBefore(new Date(), new Date(props?.end))
  }

  useEffect(() => {
    appointId && !videoData && !isLoading && videoRefetch();
  }, [appointId]);

  {return inView ? (
    <div style={style} onClick={handlePopup} ref={containerRef} className='px-[3px] pt-[2px] pb-[1px] group relative'>
      <div
          className='flex items-center gap-[2px] text-xs text-black text-left rounded bg-[#9ECCF7] overflow-hidden h-[2.188rem]'
        >
        <span className='w-[3px] h-full' style={{ backgroundColor: `var(--${props.color})` }} />
        <div className='flex items-center justify-between w-full gap-1'>
          <div>
            <p className='truncate max-w-[7rem] font-semibold text-xs	text-black'>{props.title}</p>
            <p className='font-normal text-xs	text-black'>{props.name}</p>
          </div>
          {props?.isSiteStaff && checkJoin() && (
            <button 
              onClick={() => {
                setAppointId(props?.appointment?.id);
                setJoinCall(true);
              }} 
              className='px-1 py-0.5 bg-blue-ocean-deep text-white text-xs font-semibold leading-[15px] rounded-sm mr-1'>
              <FormattedMessage id="appointment.join" />
            </button>
          )}
        </div>
        {props.id === isOpen &&
          <AppointmentPopUp
            checkType={checkType}
            videoLoading={isLoading}
            setAppointId={setAppointId}
            isSiteStaff={props?.isSiteStaff}
            setJoinCall={setJoinCall} rtcProps={rtcProps}
            videoData={videoData} handleEdit={handleEdit}
            modalData={popData}
          />
        }
      </div>
    </div>
    ) : 
    null
  }
}

export default Appointment;
