import { useEffect, useState } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import clsx from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Participants: React.FC = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('recruitment');

  const handleTab = (item: string) => {
    setTab(item);
    navigate(item);
  };

  useEffect(() => {
    switch (location.pathname.split('/')[4]) {
      case 'recruitment':
        setTab('recruitment');
        break;
      case 'enrollment':
        setTab('enrollment');
        break;
      default:
        setTab('recruitment');
    }
  }, [location]);

  return (
    <div className='flex bg-background-secondary flex-1 gap-[2.9375rem] pr-11'>
      <div
        className='w-[94px] max-w-[94px] min-w-[94px] h-fit font-serif mt-[36px] border-2 border-blue-ocean-deep bg-white rounded-r-lg flex flex-col justify-start items-center gap-[1.625rem] py-[1.625rem]'>
        <div
          onClick={() => handleTab('recruitment')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex cursor-pointer flex-col justify-center items-center',
            tab === 'recruitment'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <img alt='patient-enrollment' src={process.env.PUBLIC_URL + '/assets/images/patient_recruitment.svg'}
               className={clsx('text-blue-ocean-deep w-6 h-6 mb-1.5', tab === 'recruitment' ? 'stroke-[3px]' : 'stroke-[1px]')}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='patient.recruitment' />
          </span>
        </div>
        <div
          onClick={() => handleTab('enrollment')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex cursor-pointer flex-col justify-center items-center',
            tab === 'enrollment'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiUserPlus
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'enrollment' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='patient.enrollment' />
          </span>
        </div>
      </div>
      <div className='grow mx-auto'>
        <Outlet />
      </div>
    </div>
  );
};
export default Participants;
