import React, { useContext, useEffect, useState } from 'react';
import { FiUserPlus, FiFileText, FiPieChart, FiUsers, FiGlobe } from 'react-icons/fi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconType } from 'react-icons/lib';
import { SelectableOption } from './components/SelectableOption';
import { useQuery } from '@tanstack/react-query';
import { fetchStudyById } from 'services/api/study';
import { IStudy } from 'types/study';
import { useStudyStore } from 'store/StudyStore';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useCentersStore } from 'store/centers';
import { useParticipantStore } from 'store/participantStore';
import usePermissions from 'hooks/usePermissions';
import { AiOutlineQuestionCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import ThreeDotsDropdown from 'components/common/ThreeDotsDropdown';
import SvgIcon from '@mui/material/SvgIcon';
import { ThreeDotIcon } from 'components/common/Icons';
import { LanguageContext } from 'pages/App/LanguageContext';
import { useIntl } from 'react-intl';
import { langIconEnum } from 'types/util';
import { AuditTrialIcon } from './Icons';

type Options =
  | 'study-dashboard'
  | 'pie'
  | 'study-builder'
  | 'users'
  | 'sites'
  | 'settings'
  | 'participants'
  | 'audit-trail'
  | 'site-select';

interface ObjectOption {
  icon: IconType;
  name: Options;
  customIcon?: any;
  title: string;
  access: string;
}

export const NavBar: React.FC = () => {
  const intl = useIntl();
  const [selectedItem, setSelectedItem] = useState('');
  const [langIcon, setLangIcon] = useState('english');
  const { studyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentStudy, currentStudy, setLoggedInStudyUser } = useStudyStore();
  const { setSelectedFolder } = useQuestionnaireStore();
  const { setSelectedCenter } = useCentersStore();
  const { setParticipant } = useParticipantStore();
  const { hasNotAccess, isGeneralAdmin } = usePermissions();
  const { locale, setLocale } = useContext(LanguageContext);
  const isDeployment = currentStudy?.status === 'DEPLOYMENT';

  const TopOptions: ObjectOption[] = [
    {
      icon: FiPieChart,
      name: 'pie',
      title: intl.formatMessage({ id: 'study-dashboard.studyHome' }),
      access: '',
      customIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='22'
          viewBox='0 0 20 22'
          fill='none'
        >
          <path
            d='M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z'
            stroke='#fff'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7 21V11H13V21'
            stroke='#fff'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      icon: FiFileText,
      name: 'study-builder',
      title: intl.formatMessage({ id: 'study-builder' }),
      access: 'STUDY_BUILDER',
    },
    {
      icon: FiUsers,
      name: 'users',
      title: intl.formatMessage({ id: 'study-builder.users' }),
      access: '',
      customIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='20'
          viewBox='0 0 22 20'
          fill='none'
        >
          <path
            d='M19 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z'
            stroke='#fff'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M15 19V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V19'
            stroke='#fff'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      icon: FiGlobe,
      name: 'site-select',
      title: intl.formatMessage({ id: 'study-builder.siteSelect' }),
      access: 'STUDY_BUILDER',
    },

    {
      icon: FiUserPlus,
      name: 'sites',
      title: intl.formatMessage({ id: 'site.sites' }),
      access: '',
      customIcon: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='21'
          height='25'
          viewBox='0 0 21 25'
          fill='none'
        >
          <path
            d='M9.16992 14.7148C9.16992 17.6739 9.16997 23.2064 14.4346 23.2064C17.3043 23.2064 18.3477 20.8586 18.3477 18.5108'
            stroke='#fff'
            strokeWidth='2'
          />
          <path
            d='M9.24165 14.7143C1.59642 14.3671 0.884374 5.02946 1.13103 3.64067C1.37768 2.25188 1.84016 1 6.27928 1V2.90958'
            stroke='#fff'
            strokeWidth='2'
          />
          <path
            d='M8.9185 14.7143C16.5637 14.3671 17.2758 5.02946 17.0291 3.64067C16.7825 2.25188 16.32 1 11.8809 1V2.90958'
            stroke='#fff'
            strokeWidth='2'
          />
          <path
            d='M19.9071 16.8017C19.9071 17.3682 19.3599 18.0058 18.4526 18.0058C17.5453 18.0058 16.998 17.3682 16.998 16.8017C16.998 16.2353 17.5453 15.5977 18.4526 15.5977C19.3599 15.5977 19.9071 16.2353 19.9071 16.8017Z'
            stroke='#fff'
            strokeWidth='2'
          />
        </svg>
      ),
    },
    {
      icon: FiUsers,
      name: 'participants',
      title: intl.formatMessage({ id: 'study-dashboard.patients' }),
      access: '',
    },
    {
      icon: FiPieChart,
      name: 'audit-trail',
      access: '',
      title: intl.formatMessage({ id: 'study-dashboard.audit-trial' }),
      customIcon: AuditTrialIcon,
    },
    // {
    //   icon: FiSettings,
    //   name: 'settings',
    //   title: 'Settings',
    // },
  ];

  useEffect(() => {
    const actualPath = location.pathname.split('/').filter(Boolean)[2];
    if (!selectedItem || actualPath !== selectedItem) {
      switch (actualPath) {
        case 'summary':
          return setSelectedItem('pie');
        case 'study-builder':
          return setSelectedItem('study-builder');
        case 'study-dashboard':
          return setSelectedItem('study-dashboard');
        case 'participants':
          return setSelectedItem('participants');
        case 'sites':
          return setSelectedItem('sites');
        case 'users':
          return setSelectedItem('users');
        case 'site-select':
          return setSelectedItem('site-select');
        case 'settings':
          return setSelectedItem('settings');
        case 'audit-trail':
          return setSelectedItem('audit-trail');
      }
    }
  }, [location]);

  const { data, refetch } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(studyId as string),
  });

  useEffect(() => {
    refetch();
  }, [studyId]);

  useEffect(() => {
    if (data) {
      setLoggedInStudyUser(data?.loggedInStudyUser);
      currentStudy === null ?
        setCurrentStudy(data as any) :
        setCurrentStudy({
          ...data,
          shortTitle: currentStudy?.shortTitle,
          title: currentStudy?.title,
          center : currentStudy?.shortTitle,
          id: currentStudy?.id,
          numberOfPatient: currentStudy?.numberOfPatient,
          role: currentStudy?.role,
          status: currentStudy?.status,
        } as any);
    }
  }, [data]);

  const handleSelectItem = (selected: Options) => {
    setSelectedItem(selected);
    setSelectedCenter('all');
    setSelectedFolder('all');
    setParticipant('all');
    const redirects = {
      pie: () => navigate(`/study/${studyId}/summary`),
      participants: () => navigate(`/study/${studyId}/participants/enrollment`),
      'study-builder': () => navigate(`/study/${studyId}/study-builder`),
      sites: () => navigate(`/study/${studyId}/sites/all`),
      'site-select': () => navigate(`/study/${studyId}/site-select`),
      users: () => navigate(`/study/${studyId}/users`),
      settings: () => navigate(`/study/${studyId}/settings`),
      'study-dashboard': () => navigate(`/study/${studyId}/study-dashboard`),
      'audit-trail': () => navigate(`/study/${studyId}/audit-trail`),
    };
    
    if (['study-builder', 'users'].includes(selected)) {
      localStorage.removeItem('selectedFlow');
      localStorage.removeItem('selectedTask');
    }
    return redirects[selected]();
  };

  const getMenuList = () => {
    let tmpTopOptions = [...TopOptions];
    // if (hasNotAccess('VIEW_USERS'))
    //   tmpTopOptions = tmpTopOptions.filter((item: ObjectOption) => item.name !== 'users');
    if (!isDeployment)
      tmpTopOptions = tmpTopOptions.filter((item: ObjectOption) => item.name !== 'site-select');

    if (isGeneralAdmin()) return [tmpTopOptions[2]];
    else if (currentStudy?.status === 'DEPLOYMENT')
      return [tmpTopOptions[1], tmpTopOptions[2], tmpTopOptions[3]];
    else if (currentStudy?.status === 'EXECUTION' || currentStudy?.status === 'FREEZE')
      return tmpTopOptions.filter((item: ObjectOption) => item.name !== 'study-builder');

    return tmpTopOptions;
  };

  const handleSelectLang = (code: string, icon: string) => {
    setLocale(code);
    setLangIcon(icon);
    localStorage.setItem('translation', code);
  };

  useEffect(() => {
    if (locale) {
      setLangIcon(langIconEnum[locale]);
    }
  }, [locale]);

  return (
    <div className='flex flex-col justify-between fixed h-[calc(100vh-77px)] items-center pt-8 pb-[50px] w-[5.4625rem] z-40 bg-[#012C54] -mt-[2px]'>
      <div className='flex space-y-6 flex-col justify-center w-full '>
        {getMenuList().map((option) => (
          <SelectableOption
            key={option.name}
            Icon={option.icon}
            customIcon={option?.customIcon}
            onClick={() => handleSelectItem(option.name)}
            selected={selectedItem === option.name}
            title={option.title}
            disabled={option.access ? hasNotAccess(option.access) : false}
          />
        ))}
      </div>
      <ul className='flex flex-col gap-5 items-center w-[90%] mx-auto border-t border-[#CACACA] pt-5'>
        <li>
          <AiOutlineExclamationCircle size={20} className='shadow-md rounded-full' />
        </li>
        <li>
          <AiOutlineQuestionCircle size={20} className='shadow-md rounded-full' />
        </li>
        <li>
          <ThreeDotsDropdown
            classes={{
              content: '!bg-blue-oil !text-white !bottom-0 left-[59px] w-max',
              options:
                'hover:!bg-blue-ocean-deep text-white text-[0.7rem] !justify-center !px-2 !py-1 leading-normal',
            }}
            dotIcon={
              <div className='flex items-center cursor-pointer'>
                <img className='rounded' src={`/assets/images/${langIcon}.svg`} alt='french' />
                <SvgIcon className='cursor-pointer' component={ThreeDotIcon} />
              </div>
            }
            options={[
              { icon: '', action: () => handleSelectLang('en', 'english'), label: 'lang.english' },
              { icon: '', action: () => handleSelectLang('fr', 'french'), label: 'lang.french' },
              {
                icon: '',
                action: () => handleSelectLang('ja', 'japanese'),
                label: 'lang.japanese',
              },
              {
                icon: '',
                action: () => handleSelectLang('pt', 'portuguese'),
                label: 'lang.portuguese',
              },
            ]}
          />
        </li>
      </ul>
    </div>
  );
};
