import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import { HeaderItem } from 'components/common/Table/Table.types';
import { CheckBoxList, Radio } from '../../../../../components/common';

interface ITableProps {
  headerItems: HeaderItem[];
  columns: any;
  onRowClick?: (data: any) => void;
  rowSelect?: boolean;
}

const QuestionTable: React.FC<ITableProps> = (props: ITableProps) => {
  const { headerItems, columns, onRowClick } = props;
  const [data, setData] = useState<any>(columns);

  const getCheckboxAns = (answer: string, questionOptions: any) => {
    const idList = answer.replace('[', '').replace(']', '').split(',');
    const valueList = questionOptions?.reduce((newValue: any, ques: any) => {
      if (idList.find((ids) => `${ids}` === `${ques.id}`)) {
        newValue.push(ques.value);
      }
      return newValue;
    }, []);
    return valueList || [];
  };

  const renderAnswer = (answer: string, questionType: string, id: number, questionOptions: any) => {
    switch (questionType) {
      case 'CHECKBOX':
        return (
          <div className='h-max w-full [&>div>div>section:!justify-start]'>
            <CheckBoxList
              name={`checkList${id}`}
              className={`!gap-x-0 w-full ${
                questionOptions?.length > 3 ? '!grid-cols-3 !grid' : '!justify-start'
              }`}
              defaultValue={getCheckboxAns(`${answer}`, questionOptions)}
              options={questionOptions}
              optionsClassName='!border-none !py-1.5 !pl-0'
              disabled={true}
              checkboxClassName='!w-4 !h-4'
              labelClassName='text-sm text-base-black w-full max-w-[17rem] min-w-[80px] truncate'
            />
          </div>
        );
      case 'RADIO_BUTTONS':
        return (
          <div>
            <div className={clsx('h-max relative w-full')}>
              <Radio
                name={`radioBtns${id}`}
                options={questionOptions}
                optionsClassName={` w-full ${
                  questionOptions?.length > 3 ? '!grid-cols-3 !grid !gap-x-1' : '!justify-start '
                }`}
                disabled={true}
                defaultValue={answer}
                labelClassName='text-sm text-base-black w-full max-w-[80px] min-w-[75px] truncate'
                className={clsx('border-none !py-1.5 !pl-0 !pr-4 gap-x-[4px]')}
                wrapperClassName='[&>div]:px-[2px] relative -left-[1px]'
                radioClassName='!w-4 !h-4 gap-x-2'
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setData(columns);
  }, [columns]);

  const renderSubQuestions = (subQuestions: any) => {
    return (
      <>
        <table className="min-w-full w-full">
          <tbody>
            {subQuestions?.map((item: any, i: any) => (
              <tr key={i}>
                <td className="px-4 w-[40%] max-w-[40%] text-gray-dark text-sm py-[7px] text-left table-cell">
                  <p className="w-[20rem] font-normal flex font-sans text-blue-oil text-wrap break-words">
                    {`${String.fromCharCode(65 + i).toLowerCase()}. `}
                    <p className='w-[20rem] px-1.5 font-normal font-sans text-blue-oil text-wrap break-words' dangerouslySetInnerHTML={{ __html: `${item.question}` }}></p>
                  </p>
                </td>
                <td
                  className="pl-0 pr-4 group w-[30%] max-w-[30%] text-left text-gray-dark text-sm py-[7px] table-cell">
                  <p className="text-grey-medium text-sm w-[18rem] text-wrap break-words">
                    {item?.questionData?.questionType === 'CHECKBOX' ? getCheckboxAns(`${item?.answer?.answer}`, item?.questionData?.questionOptions) : item?.answer?.answer}
                  </p>
                  <div className="hidden group-hover:block">
                    {item?.answer?.answer && renderAnswer(item?.answer?.answer, item?.questionData?.questionType, item?.id, item?.questionData?.questionOptions || '')}
                  </div>
                </td>
                <td className="px-4 text-left text-gray-dark text-sm py-[7px] table-cell">
                  <p className="text-grey-medium text-sm">{item?.score}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <div className="h-max w-full max-h-[calc(100vh-33rem)] overflow-auto">
      <table className="min-w-full w-full pl-4">
        <thead className="sticky top-0 bg-white z-[39]">
        <tr>
          {headerItems.map((r) => (
            <th
              key={r.name}
              className={clsx(
                'text-left px-4 group cursor-pointer h-14 text-blue-ocean-deep text-base font-semibold',
                r.width,
              )}
            >
              <div className="flex items-center">
                {r.title}
              </div>
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
          {data?.map((item: any, i: any) => (
            <tr
              onClick={() => onRowClick?.(item)}
              key={item.id}
              className="bg-white border border-gray align-text-top group cursor-pointer"
            >
              <td colSpan={item?.subQuestions && item?.subQuestions?.length > 0 ? 3 : 1} className="px-4 w-[40%] max-w-[40%] py-3 text-left table-cell">
                <p className={`flex ${item?.subQuestions && item?.subQuestions?.length > 0 && '!text-xl'} w-[20rem] font-normal font-sans text-blue-oil text-wrap break-words`}>
                  {i + 1}.<p className={`w-[90%] px-1 ${item?.subQuestions && item?.subQuestions?.length > 0 && '!text-xl font-normal font-sans text-blue-oil text-wrap break-words'}`} dangerouslySetInnerHTML={{ __html: `${item.question}` }}></p>
                </p>
                {item.subtitle && (
                  <p
                    className="w-[20rem] pb-[10px] pl-[1.3rem] pt-[1.2rem] font-normal font-sans text-blue-oil text-wrap break-words">
                    {item.subtitle}
                  </p>
                )}
                {item?.subQuestions && item?.subQuestions?.length > 0 && renderSubQuestions(item?.subQuestions)}
              </td>
              {item?.questionType !== 'TITLE' && (<td className="group px-4 w-[30%] max-w-[30%] text-left text-gray-dark text-sm py-3 table-cell">
                <p className="text-grey-medium text-sm w-[18rem] text-wrap break-words">
                  {item?.questionData?.questionType === 'CHECKBOX' ? getCheckboxAns(`${item?.answer?.answer}`, item?.questionData?.questionOptions) : item?.answer?.answer}
                </p>
                <div className="hidden group-hover:block">
                  {item?.answer?.answer && renderAnswer(item?.answer?.answer, item?.questionData?.questionType, item?.id, item?.questionData?.questionOptions || '')}
                </div>
              </td>)}
              {item?.questionType !== 'TITLE' && (<td className="px-4 text-left text-gray-dark text-sm py-3 table-cell">
                <p className="text-grey-medium text-sm">{item?.score}</p>
              </td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuestionTable;
