import { Checkbox } from 'components/common';
import { IPermissionItem, IRoleCard, IRole } from 'types/roles';
import useRoles from '../../useRoles';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage } from 'react-intl';

interface IProps {
  roles: IRoleCard[];
  list?: IPermissionItem[] | undefined;
  selectedRoleTitle?: any;
  setSelectedPermissions: any;
  selectedPermissions: number[];
  selectedRole: number | null;
  rolesRefetch: any;
}
const Permissions: React.FC<IProps> = ({
  roles,
  list = [],
  // selectedRoleTitle,
  setSelectedPermissions,
  selectedPermissions,
  selectedRole,
  rolesRefetch,
}: IProps) => {
  const { handleEditRole } = useRoles({ rolesRefetch });
  const { isFreezeStudy, hasNotAccess } = usePermissions();

  const findItemById = (
    items: IRoleCard[],
    id: number | null,
    keyName: string,
  ): IRole | undefined => {
    for (const item of items) {
      const foundItem: IRole | undefined = item.list.find((item: any) => item?.[keyName] === id);
      if (foundItem) {
        return foundItem;
      }
    }
    return undefined;
  };

  const findRole = findItemById(roles, selectedRole, 'id');

  const handleOnchange = (e: any, permission: IPermissionItem) => {
    let newPermissions = [];
    if (selectedPermissions.includes(permission.id)) {
      newPermissions = selectedPermissions.filter((p: number) => p !== permission.id);
    } else {
      newPermissions = !selectedPermissions.includes(permission.id)
        ? [...selectedPermissions, permission.id]
        : [];
    }

    if (permission.name.includes('ADD') && e.target.checked) {
      const str: any = permission.name.split('_');
      const findViewPermission: IPermissionItem | undefined = list.find(
        (it: IPermissionItem) =>
          it.name.includes('VIEW') && (it.name.includes(str[1]) || it.name.includes(str?.[2])),
      );

      if (findViewPermission?.id)
        newPermissions = !newPermissions.includes(findViewPermission.id)
          ? [...newPermissions, findViewPermission.id]
          : [...newPermissions];
    }
    if (permission.name.includes('VIEW') && !e.target.checked) {
      const str: any = permission.name.split('_');

      const findViewPermission: IPermissionItem | undefined = list.find(
        (it: IPermissionItem) =>
          it.name.includes('ADD') &&
          (it.name.includes(str[1]) || it.name.includes(str?.[2]?.slice(0, 3))),
      );

      if (findViewPermission?.id)
        newPermissions = newPermissions.filter((it: any) => it !== findViewPermission.id);
    }
    if (selectedRole) {
      handleEditRole(selectedRole, findRole?.name ?? '', findRole?.category ?? '', newPermissions);
    }
    setSelectedPermissions(newPermissions);
  };
  const filterArray: any = {
    FULL_ADMINISTRATIVE: ['Add patients', 'Televisit'],
    DATA_ACTIVITIES: ['Add patients'],
    CLINICAL_OPERATIONS: ['Add patients'],
    SITE_STAFF: [],
  };
  const filteredList = list.filter((item: any) => {
    if (findRole?.category && filterArray?.[`${findRole.category}`].includes(item.description)) {
      return false;
    }
    return true;
  });
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_ROLES') || hasNotAccess('VIEW_ROLES');

  return (
    <div className='border-[1px] border-purple rounded-[10px] w-full  h-[335px]'>
      <h3 className='border-b-[1px] border-b-purple pl-[20px] py-[10px] text-blue-ocean-deep text-[20px] font-medium text-left bg-[rgba(221,221,221,0.22)]'>
        <FormattedMessage id="users.permissions" /> {findRole?.name ? ' - ' + findRole?.name : ''}
      </h3>
      {/* <div className='flex wrap gap-x-[32px] gap-y-[30px] px-[40px] py-[31px]'> */}
      <div className='grid grid-cols-5 pl-[20px] pr-1 py-[31px] gap-y-[30px]'>
        {filteredList.map((permission: IPermissionItem) => (
          <div key={permission.id} className='flex justify-start items-center gap-x-[8px]'>
            <Checkbox
              className='w-[18px] h-[18px] ml-1 accent-base-[#004381] appearance-none border-2 border-blue-500 rounded-sm bg-white [&>input]:!border-none'
              control={{
                checked: selectedPermissions.includes(permission.id),
                onChange: (e) => {
                  if (!disableActions) handleOnchange(e, permission);
                },
              }}
            />
            <span className='max-w-max text-blue-ocean-deep text-sm font-normal'>
              {permission.description}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Permissions;
