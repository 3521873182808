import { ITask } from 'types/flow';
import { useState, useEffect } from 'react';
import { insertAtIndex } from 'utils/array';
import { getMaxSortId } from 'utils/number';
import { IQuestion } from 'types/questionnaire';
import { Select } from 'components/common';
import { useIntl } from 'react-intl';
import ActionTypeBody from './ActionTypeBody';
import { editTaskInfoFC } from 'services/api/onboarding';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface IProps {
  selectedTask: ITask | null;
  onDeleteAttachment?: any;
  handleUploadAttachment?: any;
  title?: any;
  information?: any;
  isRequired?: any;
  refetchFlows?: any;
  actionType?: any;
  setActiontype?: any;
  agreementText?: any;
  setAgreementText?: any;
  declinationText?: any;
  setDeclinationText?: any;
  setSelectedTask?: any;
  setTaskInformation?: any;
}
const GenericDocument = ({
  selectedTask,
  onDeleteAttachment,
  handleUploadAttachment,
  title,
  information,
  isRequired,
  refetchFlows,
  agreementText,
  setAgreementText,
  declinationText,
  setDeclinationText,
  setSelectedTask,
}: IProps) => {
  const [questions, setQuestions] = useState(selectedTask?.questionnaire?.questions ?? []);
  const [actionType, setActiontype] = useState<any>(selectedTask?.actionType ?? '');

  useEffect(() => {
    setActiontype(selectedTask?.actionType);
  }, [selectedTask?.actionType]);

  const intl = useIntl();
  const { mutate: editTaskInfoMutate } = useMutation<any, unknown, any, unknown>(
    (data: any) => editTaskInfoFC(data),
    {
      onSuccess: (data: any) => {
        setSelectedTask(data);
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const handleBlur = async (field: string, value: any) => {
    if (selectedTask) {
      try {
        editTaskInfoMutate({
          title,
          information,
          isRequired,
          id: selectedTask.id,
          agreementText,
          declinationText,
          [field]: value,
          actionType,
        });
      } catch (error) {
        console.error('Failed to update task:', error);
      }
    }
  };
  useEffect(() => {
    const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

    if (fndEmptyItem === -1) {
      setQuestions(
        insertAtIndex(
          [...questions],
          {
            id: 0,
            questionTitle: '',
            sortOrder: getMaxSortId(questions) + 1,
          },
          questions?.length - 1,
        ),
      );
    }
  }, [questions]);
  const handleChangeActionType = (type: string) => {
    setActiontype(type);
  };
  useEffect(() => {
    if (actionType && actionType !== selectedTask?.actionType) {
      editTaskInfoMutate({
        actionType: actionType,
        title: selectedTask?.title,
        information: selectedTask?.information,
        isRequired,
        id: selectedTask?.id,
        ...(['CHOOSE_RESPONSE', 'ATTESTATION_REQUIRED'].includes(actionType ?? '') &&
        selectedTask?.type === 'GENERIC_DOCUMENT'
          ? { agreementText, declinationText }
          : {}),
      });
    }
  }, [actionType]);

  return (
    <div className='mt-10'>
      <Select
        className='w-[200px]'
        buttonClassName='bg-white h-[30px] !px-1'
        name='actiontype'
        defaultValue={null}
        customControl={{
          value: actionType,
          onChange: (e: any) => {
            handleChangeActionType(e);
          },
        }}
        optionsClassName='!px-[2px]'
        mainClass='rounded-md mt-3 [&>div>ul]:!pl-0'
        optWrapperClassName='border border-blue-ocean-deep border-t-0 -!left-[2px]'
        placeholder={intl.formatMessage({ id: 'study-builder.flows-task-action-type' })}
        options={[
          { label: 'Download required', value: 'DOWNLOAD_REQUIRED' },
          { label: 'Approval required', value: 'APPROVAL_REQUIRED' },
          { label: 'Choose response', value: 'CHOOSE_RESPONSE' },
          { label: 'Attestation required', value: 'ATTESTATION_REQUIRED' },
        ]}
        noTranslation={true}
      />
      <ActionTypeBody
        actionType={actionType}
        onDeleteAttachment={onDeleteAttachment}
        handleUploadAttachment={handleUploadAttachment}
        handleBlur={handleBlur}
        agreementText={agreementText}
        declinationText={declinationText}
        setAgreementText={setAgreementText}
        setDeclinationText={setDeclinationText}
        selectedTask={selectedTask}
      />
    </div>
  );
};

export default GenericDocument;
