import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'classnames';
import Input from '../../../../components/common/TextInput';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Calendar, Checkbox, TextArea } from '../../../../components/common';
import ScaleInput from '../../StudyBuilder/components/PreviewQuestions/ScaleInput';
import FileUploadWithProgressBar from '../../../../components/common/FileUploadWithProgressBar';
import { toast } from 'react-toastify';
import { useQuestionnaireStore } from '../../../../store/QuestionnaireStore';
import { useMutation } from '@tanstack/react-query';
import { addRepAnsUploadFile } from '../../../../services/api/steps';
import RatingStar from '../../../../components/common/RatingStar';
import { useStudyStore } from '../../../../store/StudyStore';
import usePermissions from '../../../../hooks/usePermissions';
import { FiFilePlus } from 'react-icons/fi';
import QuestionMultiSelect from './QuestionMultiSelect';
import { deleteMultiAnswers, postRepeatedAnswers, setRepeatsdvAnswer } from '../../../../services/api/questionnaire';
import { format } from 'date-fns';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useParams } from 'react-router-dom';
import QueryQuestionnaire from '../QueryQuestionaire';
import _ from 'lodash';
import { formatAnsDate, getFormat } from '../../../../utils/string';
import SearchableSelect from '../../../../components/common/SearchableSelect';
import { useCentersStore } from '../../../../store/centers';
// import SearchableSelect from '../../../../components/common/SearchableSelect';

interface IQuestionOptions {
  id: number;
  label: string;
  value: string;
}

const RepeatedQuestion = ({
  title, repeatedQuestions, checkIsAnswer, PickerTypeEnum, checkVerify, refetch, checkIsQuery,
  selectedTable, setSelectedTable, questionNumber
}: any) => {
  const intl = useIntl();
  const methods = useForm();
  const { questionnaireId } = useParams();
  const [isBackupEdit, setIsBackupEdit] = useState(-1);
  const [repeatedAnswers, setRepeatedAnswers] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(-1);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [headerItems, setHeaderItems] = useState<any>();
  const [uploadId, setUploadId] = useState(-1);
  const [progress, setProgress] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [columns, setColumns] = useState<any>([]);
  const { stepsData, steps } = useQuestionnaireStore();
  const { currentStudy } = useStudyStore();
  const { selectedCenter } = useCentersStore();
  const { isFreezeStudy, hasNotAccess } = usePermissions();
  const mutation = useMutation(addRepAnsUploadFile);
  const { loggedInStudyUser } = useStudyStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const isAddPermission = isSiteStaff && `${selectedCenter}` === `${loggedInStudyUser?.centerId}` && !isFreezeStudy();
  const isSDV = !hasNotAccess('SDV')

  const { mutate: removeAnswerMutation } = useMutation<any, unknown, any, unknown>(
    (payload: any) => deleteMultiAnswers(payload),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  useEffect(() => {
    setRepeatedAnswers(steps?.find(
      (stp: any) => stp?.step?.questionnaire?.id === Number(questionnaireId?.split('-')?.[1]),
    )?.repeatedAnswersRecords ?? []);
  }, [steps, questionnaireId]);

  const values: any = methods?.watch();

  const handleDeleteFile = (item: any) => {
    // item?.id && removeAnswerMutation(item?.id);
    console.log('deleteFIle', item);
  }

  const handleFileUpload = async (e: any, id: number, submitId?: -1) => {
    setUploadId(id);
    const maxAllowedSize = 9.8 * 1024 * 1024;
    if (e.size < maxAllowedSize) {
      if (id) {
        let res = '';
        try {
          if(isBackupEdit > -1 || submitId) {
            res = await mutation.mutateAsync({
              repeatedAnswersRecordId: isBackupEdit || submitId,
              stepId: stepsData?.stepId || 0,
              questionId: id,
              payload: e,
              onProgress: (progressEvent: any) => {
                const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(progres);
                // You can update your UI with the progress here
              },
            });
          } else {
            res = await mutation.mutateAsync({
              stepId: stepsData?.stepId || 0,
              questionId: id,
              payload: e,
              onProgress: (progressEvent: any) => {
                const progres = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(progres);
                // You can update your UI with the progress here
              },
            });
          }
          if (res) {
            refetch();
            if (isEdit === -1 ) {
              methods?.reset();
              setIsBackupEdit(-1);
            }
            if (isBackupEdit === -1) {
              setUploadId(-1);
              setProgress(0);
              methods?.reset();
            } else {
              setIsEdit(-1);
            }
          }
        } catch (e: any) {
          setProgress(-100);
        }
      }
    } else {
      toast.error(intl.formatMessage({ id: 'error.fileLimitSize' }));
    }
  };

  const { mutate: addRepeatedAns } = useMutation<any, unknown, any, unknown>(
    (data: any) => postRepeatedAnswers(stepsData?.stepId || 0, isBackupEdit, data),
    {
      onSuccess: (res) => {
        refetch();
        const getFileName: any = Object?.keys(values)?.filter((val) => val.includes('FILE_UPLOAD'));
        if(getFileName && values[getFileName] && values[getFileName]?.files) {
          handleFileUpload(values?.files, uploadId, res?.id);
          return;
        }
        if (isEdit === -1 ) {
          methods?.reset();
          setIsBackupEdit(-1);
        }
        if (isBackupEdit === -1) {
          methods?.reset();
        } else {
          setIsEdit(-1);
        }
      },
    },
  );

  const handleColumns = (repeatedAnswers: any) => {
    return repeatedAnswers?.filter((repAns: any) => repAns?.repeatedQuestionId === repeatedQuestions?.id && repAns?.answers?.length > 0).map((repAns: any) => {
      let sortAns: any = [];
      headerItems?.map((item: any) => {
        const foundAns = repAns?.answers?.find((ans: any) => ans?.questionId === item?.id);
        if (sortAns && sortAns?.length > 0) {
          foundAns ? sortAns?.push({ ...foundAns, questData: item }) : sortAns?.push({ answer: '' , questData: item});
        } else {
          sortAns = foundAns ? [{ ...foundAns, questData: item }] :  [{ answer: '', questData: item }];
        }
      });
      return { ...repAns,  answers: sortAns };
    })
  }

  const { mutate: updateSDV } = useMutation<
    unknown,
    unknown,
    number,
    unknown
  >((answerId: number) => setRepeatsdvAnswer(answerId), {
    onSuccess(res: any) {
      const tempRepAns: any = repeatedAnswers;
      const foundIndex = repeatedAnswers?.findIndex(
        (rep: any) =>
          `${rep?.repeatedQuestionId}` === `${res?.repeatedQuestionId}` &&
          `${rep?.id}` === `${res?.id}`,
      );
      if (tempRepAns[foundIndex]) {
        tempRepAns[foundIndex].sdv = true;
        setRepeatedAnswers(tempRepAns);
        setColumns(handleColumns(tempRepAns));
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (repeatedAnswers && repeatedAnswers?.length > 0) {
      setColumns(handleColumns(repeatedAnswers));
    }
  }, [repeatedAnswers]);

  useEffect(() => {
    if (repeatedQuestions && repeatedQuestions?.parentQuestions && repeatedQuestions?.parentQuestions?.length > 0) {
      const repeatedQues: any = repeatedQuestions?.parentQuestions?.reduce((newItems: any, item: any) => {
        newItems?.push({
          ...item,
          title: item?.questionTitle,
          key: `${item?.questionType}`,
          value: `${item?.inputType === 'LONG' ? 'TEXT_AREA' : item?.questionType}-${item?.id}`,
          width: item?.questionType === 'SCALE' ? '!w-[240px] !min-w-[240px] !max-w-[240px]' : '!w-[138px] !min-w-[138px] !max-w-[138px]',
        });

        return newItems;
      }, [])
      setHeaderItems(repeatedQues);
    } else {
      setHeaderItems([])
    }
  }, [repeatedQuestions]);

  const handleIsEdit = (item: any) => {
    methods?.reset();
    setProgress(0);
    setIsEdit(item?.id);
    setIsBackupEdit(item?.id);
    setSelectedTable(repeatedQuestions?.id);
    item?.answers && item?.answers?.map((ans: any) => {
      const qType: any = ans?.questData?.inputType === 'LONG' ? 'TEXT_AREA' : ans?.questData?.questionType;
      const name = `${qType}-${ans?.questData?.id}-${item?.id}`;
      if (ans?.questData?.questionType === 'CHECKBOX' && ans?.optionId) {
        const opt = ans?.optionId?.split('[')[1].split(']')[0]?.split(',');
        const optLables = opt && opt?.length > 0 && opt?.reduce((newOp: any, op: any) => {
          newOp?.push(ans?.questData?.questionOptions?.filter((qs: any) => qs?.id === parseInt(op))[0]?.label)
          return newOp;
        }, [])
        methods.setValue(name, optLables);
        return;
      } if (ans?.questData?.questionType === 'DROPDOWN' || ans?.questData?.questionType === 'RADIO_BUTTONS') {
        const optLabel = ans?.questData?.questionOptions?.find((qs: any) => qs?.id === parseInt(ans?.answer))?.label;
        methods.setValue(name, optLabel);
        return;
      } else if (ans?.questData?.questionType === 'DATE_TIME' && ans?.answer && ans?.answer !== '') {
        const newAns = ans?.questData?.dateTimeType && formatAnsDate(ans.answer, ans?.questData?.dateTimeType);
        methods.setValue(name, newAns && `${new Date(newAns)}` !== 'Invalid Date' ? new Date(newAns) : '');
        return;
      } else {
        methods.setValue(name, ans?.answer);
      }
    })
  }

  const handleUserKeyPress = useCallback((event: any) => {
    if (event.key === 'Enter' && !event?.target?.name?.includes('TEXT_AREA')) {
      onSubmit();
      setIsEdit(-1);
    }
  }, [values, stepsData]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const getOptionIds = (questionOptions: any, data: any) => {
    if (questionOptions && questionOptions?.length > 0) {
      if (typeof data === 'string') {
        return questionOptions?.find((qs: any) => qs.value === data)?.id;
      } else {
        const idList =
          data &&
          data?.length > 0 &&
          data?.map((i: string) => {
            const foundOpt = questionOptions?.find((qs: any) => qs.value === i);
            return foundOpt?.id;
          });
        return idList;
      }
    }
    return [];
  };

  const getQuestAnswer = (id: number, qType: string, questionOptions: any, dateType: any) => {
    let answer = '';
    let optionId = null;
    const newValues = methods?.watch();
    const name = isBackupEdit > -1 ? `${qType}-${id}-${isBackupEdit}` : `${qType}-${id}`;
    switch (qType) {
      case 'TEXT_INPUT':
        answer = newValues[name];
        break;
      case 'TEXT_AREA':
        answer = newValues[name];
        break;
      case 'NUMBER_VALUE':
        answer = newValues[name];
        break;
      case 'CHECKBOX': {
        const checkAns = newValues[name] && getOptionIds(questionOptions, newValues[name]);
        optionId = checkAns ? checkAns : [];
        answer = checkAns && checkAns?.join('') || '';
        break;
      }
      case 'DROPDOWN': {
        const dropAns = newValues[name] && getOptionIds(questionOptions, newValues[name]);
        optionId = dropAns ? [dropAns] : [];
        answer = dropAns;
        break;
      }
      case 'DATE_TIME':
        answer = newValues[name] && format(new Date(newValues[name]), getFormat(dateType));
        break;
      case 'RADIO_BUTTONS': {
        const radioAns = newValues[name] && getOptionIds(questionOptions, newValues[name]);
        optionId = radioAns ? [radioAns] : [];
        answer = radioAns;
        break;
      }
      case 'FILE_UPLOAD':
        isBackupEdit !== -1 && newValues[name] && handleFileUpload(newValues[name], id);
        break;
      case 'RATING':
        answer = newValues[name];
        break;
      case 'SCALE':
        answer = newValues[name];
        break;
      default:
        null;
    }
    return { answer, optionId }
  }

  const checkSame = () => {
    const foundData = columns?.find((repAns: any) => repAns?.id === isBackupEdit);
    let isUpdate = false;
    if (foundData?.answers && foundData?.answers?.length > 0) {
      const obj = methods?.watch();
      const filteredKeys = Object.keys(obj)?.filter((ob: any) => ob?.split('-')[2]);
      const filteredObj = _.pick(obj, filteredKeys);
      foundData?.answers?.map((ans: any) => {
        const name: any = Object.keys(filteredObj).find((val) => `${val?.split('-')[1]}` === `${ans?.questionId}`);
        if (ans?.optionId && name && name?.includes('CHECKBOX')) {
          const answer = ans.answer ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType) : [];
          const valueAns = filteredObj[name] && filteredObj[name]?.length > 0 && filteredObj[name]?.join(', ');
          if (valueAns !== answer) {
            isUpdate = true;
            return isUpdate;
          }
        } else if (ans?.optionId && name && (name?.includes('DROPDOWN') || name?.includes('RADIO_BUTTONS'))) {
          const answer = ans.answer ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType) : [];
          if (filteredObj[name] !== answer) {
            isUpdate = true;
            return isUpdate;
          }
        } else if (name && values[name] !== ans?.answer) {
          isUpdate = true;
          return isUpdate;
        } else if (ans?.answer === '') {
          isUpdate = true;
          return isUpdate;
        }
      })
    }
    return isUpdate
  }

  const isObjNull = () => {
    const obj = methods?.watch();
    const filteredKeys = Object.keys(obj)?.filter((ob: any) => ob?.split('-')[2]);
    const filteredObj = _.pick(obj, filteredKeys);
    const foundAns = columns?.find((col: any) => `${col.id}` === `${isBackupEdit}`)
    const removeAnsList = foundAns?.answers?.reduce((newAns: any, ans: any) => {
      const keyObj: any = Object.keys(filteredObj).find((val) => `${val?.split('-')[1]}` === `${ans?.questionId}`);
      if (keyObj?.includes('RATING') || keyObj?.includes('SCALE') || keyObj?.includes('DATE_TIME')) {
        return newAns;
      }
      if (keyObj?.includes('CHECKBOX')) {
        const answer = ans.answer ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType) : [];
        const valueAns = filteredObj[keyObj] && filteredObj[keyObj]?.length > 0 && filteredObj[keyObj]?.join(',');
        if (keyObj && (valueAns !== answer && (filteredObj[keyObj] === null || filteredObj[keyObj]?.length === 0))) {
          newAns.push(ans?.id);
        }
      } else if (keyObj?.includes('DROPDOWN') || keyObj?.includes('RADIO_BUTTONS')) {
        const answer = ans.answer ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType) : [];
        if (keyObj && (filteredObj[keyObj] !== answer && (filteredObj[keyObj] === null || filteredObj[keyObj] === ''))) {
          newAns.push(ans?.id);
        }
      } else if (keyObj && (filteredObj[keyObj] !== ans?.answer && (filteredObj[keyObj] === null || filteredObj[keyObj] === ''))) {
        newAns.push(ans?.id);
      }
      return newAns;
    }, []);
    return removeAnsList;
  }

  const onSubmit = () => {
    if (`${selectedTable}` !== `${repeatedQuestions?.id}`) return;
    if (isAddPermission && isBackupEdit !== -1 && !checkSame()) {
      setIsBackupEdit(-1);
      return;
    }
    const removeList = isObjNull();
    if (isAddPermission && isBackupEdit !== -1 && removeList && removeList?.length > 0) {
      const payload = removeList?.reduce((newAns: any, quest: any) => {
        quest && newAns?.push(parseInt(quest, 10));
        return newAns;
      }, []);
      if (payload && payload?.length > 0) {
        removeAnswerMutation(payload)
      }
    }
    if (isAddPermission && repeatedQuestions && repeatedQuestions?.parentQuestions && repeatedQuestions?.parentQuestions?.length > 0 && checkIsAnswer?.()) {
      try {
        const payload = repeatedQuestions?.parentQuestions?.reduce((newAns: any, quest: any) => {
          const { answer, optionId } = getQuestAnswer(quest?.id, quest?.inputType === 'LONG' ? 'TEXT_AREA' : quest?.questionType, quest?.questionOptions || '', quest?.dateTimeType);
          const filteredOpt = optionId?.filter((item: any) => item !== null && item !== '');
          newAns?.push({
            answererId: parseInt(`${loggedInStudyUser?.id}`),
            questionId: quest?.id,
            optionId: filteredOpt && filteredOpt?.length > 0 ? filteredOpt : [],
            answer: answer ? answer : ''
          });
          return newAns;
        }, []);
        const filteredPayload = payload?.filter((ans: any) => ans?.answer !== '' && ans?.answer !== null);
        if (filteredPayload && filteredPayload?.length > 0) {
          addRepeatedAns(filteredPayload);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleChange = (name: string, value: any) => {
    methods.setValue(name, value);
    setSelectedTable(repeatedQuestions?.id)
  }

  const renderInputField = (type: string, name: string, questData: any) => {
    switch (type) {
      case 'SCALE':
        return (
          <div>
            <ScaleInput
              name={name}
              control={methods.control}
              start={questData?.startValue}
              end={questData?.endValue}
              increment={questData?.increment}
              startLegend={questData?.startLegend}
              endLegend={questData?.endLegend}
              onChange={(e: any) => handleChange(name, e)}
              mainClass="!pl-0"
              numberClass="[&>div>span]:!text-[7px]"
              disabled={checkIsAnswer() ? false : true}
            />
          </div>
        );
      case 'TEXT_INPUT': {
        return questData?.inputType === 'LONG' ? (
          <TextArea
            name={name}
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
            control={methods?.control}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            rows={values[name] ? 3 : 1}
            className="!mt-0 w-full !min-w-[120px]"
            textClass={clsx(
              'placeholder:text-gray-very-light !border-blue-light-200 !rounded-[4px] !py-0 !min-h-7',
            )}
            readOnly={checkIsAnswer() ? false : true}
          />
        ) : (
          <Input
            name={name}
            register={methods.register}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.target.blur(e.target.value);
              }
            }}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            readOnly={checkIsAnswer() ? false : true}
            inputClassName="h-7 !rounded placeholder:text-sm placeholder:text-gray-very-light !border-blue-light-200"
            className="!mt-0"
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          />
        );
      }
      case 'NUMBER_VALUE':
        return <Input
          name={name}
          register={methods.register}
          onKeyDown={(e: any) => {
            if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.' || e.key === ',') {
              if (questData?.numberType === 'DECIMAL' && e.key === '.') return;
              e.preventDefault();
            }
            if (e.key === 'Enter') {
              e.target.blur(e.target.value);
            }
          }}
          type='number'
          onBlur={() => setSelectedTable(repeatedQuestions?.id)}
          readOnly={checkIsAnswer?.() ? false : true}
          inputClassName="h-7 !rounded placeholder:text-sm placeholder:text-gray-very-light !border-blue-light-200"
          className="!mt-0"
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
        />
      case 'DROPDOWN': {
        return <SearchableSelect
          control={methods?.control}
          label=""
          className="shadow-none !p-0 min-w-[120px] w-full"
          mainClass={clsx(
            '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[5px] !max-h-[28px] !border-blue-light-200 !bg-white [&>div>ul]:!pl-0',
          )}
          disabled={checkIsAnswer?.() ? false : true}
          options={questData?.questionOptions.map((item: IQuestionOptions) => ({
            label: item.label,
            value: item.value,
          }))}
          isCheckIcon={true}
          noTranslation={true}
          noErrorMessage={true}
          buttonClassName="!pl-0"
          name={name}
          onBlur={() => setSelectedTable(repeatedQuestions?.id)}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          optWrapperClassName="!border !border-blue-light-200 !rounded-b-[6px] !min-w-[236px]"
        />;
      }
      case 'CHECKBOX':
        return <QuestionMultiSelect
          options={questData?.questionOptions.map((item: IQuestionOptions) => ({
            label: item.label,
            value: item.value,
          }))}
          control={{
            value: values[name],
            onChange: (e: any) => handleChange(name, e),
          }}
          name={name}
          disabled={checkIsAnswer?.() ? false : true}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px]'
        />
      case 'RADIO_BUTTONS':
        return <SearchableSelect
          control={methods?.control}
          label=''
          className='shadow-none !p-0 min-w-[120px] w-full'
          mainClass={clsx(
            '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[5px] !max-h-[28px] !border-blue-light-200 !bg-white [&>div>ul]:!pl-0',
          )}
          disabled={checkIsAnswer?.() ? false : true}
          options={questData?.questionOptions.map((item: IQuestionOptions) => ({
            label: item.label,
            value: item.value,
          }))}
          noTranslation={true}
          noErrorMessage={true}
          isCheckIcon={true}
          buttonClassName='!pl-0'
          name={name}
          onBlur={() => setSelectedTable(repeatedQuestions?.id)}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px] !min-w-[236px]'
        />
      case 'FILE_UPLOAD': {
        const nameList = values && name && values[name] && typeof values[name] === 'string' ? values[name]?.split('/') : [values[name]?.name];
        return <FileUploadWithProgressBar
          register={methods.register}
          disabled={checkIsAnswer() ? false : true}
          name={name}
          customIcon={<FiFilePlus className="text-lg" />}
          id={questData?.id}
          uploadId={uploadId}
          progress={isBackupEdit && name?.includes(`${isBackupEdit}`) ? progress : 0}
          showName={nameList[nameList?.length - 1]}
          btnClasses="!bg-transparent !gap-x-2"
          accept=".png,.jpeg,.WebP,.HEIF,.pdf,.word,.txt,.ODF"
          handleUpload={(e: any) => {
            if (e === '') {
              handleDeleteFile(questData);
            } else {
              handleChange(name, e);
            }
          }}
        />;
      }
      case 'RATING':
        return <div className='max-w-[120px] bg-white overflow-x-scroll !border !border-blue-light-200 rounded'>
            <RatingStar
            control={methods.control}
            name={name}
            className={`!mt-0 !pr-0 ${questData?.stars === 'FIVE' ? 'gap-2 !mx-auto' : questData?.stars === 'THREE' ? 'gap-5 !mx-auto' : 'gap-0'}`}
            iconClass={questData?.stars <= 5 ? '!w-4 !h-4' : '!w-[14px] !h-[14px]'}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            stars={questData?.stars}
            noZero={true}
            disabled={checkIsAnswer() ? false : true}
          />
        </div>
      case 'DATE_TIME':
        return <Calendar
          control={methods.control}
          name={name}
          label=''
          className={clsx(
            '!border !border-blue-light-200 !rounded !w-[118px] !h-[28px] no-border-mui [&>div]!flex-reverse -[&>div>div>div>button]:!mr-[1px] [&>div>div>div>button>svg]:w-[16px] [&>div>div>div>button]:!p-0 [&>div>div>input]:!py-0.5 [&>div>div>input]:!text-sm [&>div>div>input]:!pl-2 [&>div>div>input]:!text-left',
            methods?.formState?.errors?.[name]
              ? ' [&>div]:!h-[34px] [&>div>div]:!border-danger-100 [&>div>.MuiInputBase-root]:!border-2 [&>div>div>input]:p-[2px]'
              : '[&>div>div>input]:pl-0 !my-0',
          )}
          onBlur={() => setSelectedTable(repeatedQuestions?.id)}
          setIsDatePickerOpen={setIsDatePickerOpen}
          placeholder='Select a date'
          errors={methods?.formState?.errors}
          noErrorMessage={true}
          isOkButtonDisabled={true}
          inputDisable={true}
          disabled={checkIsAnswer() ? false : true}
          type={(questData?.dateTimeType && PickerTypeEnum[questData?.dateTimeType]) || 'date'}
        />
      default:
        return null;
    }
  }

  const handleSetSDV = (item: any, name: any) => {
    methods?.setValue(name, true);
    item && item?.id && updateSDV(item?.id);
  }

  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
    const isMui = e?.target?.classList && e?.target?.classList?.length > 0 && e?.target?.classList[0]?.includes('Mui');
    const isPortal = e?.target?.classList && e?.target?.classList?.length > 0 && e?.target?.classList[0]?.includes('dropdown-portal');
      if (clickedOut && !isMui && !isDatePickerOpen && !isPortal) {
        onSubmit();
        setIsEdit(-1);
      }
    },
    [isBackupEdit, isEdit, stepsData, isDatePickerOpen, selectedTable],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getAnsValue = (answer: string, questionOptions: any, type: any) => {
    const ans = answer.replace('[', '').replace(']', '');
    if (type === 'CHECKBOX') {
      const updatedAns = ans?.includes(',') ? ans?.split(',') : ans;
      if (typeof updatedAns === 'string') {
        return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
      } else {
        const ansList = updatedAns && updatedAns?.length > 0 && updatedAns?.map((aswr) => {
          return questionOptions?.find((qs: any) => `${qs?.id}` === `${aswr}`)?.value
        })
        return ansList && ansList?.length > 0 && ansList.join(', ');
      }
    }
    return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
  };

  const renderRow= (answer: any, item: any) => {
    return <p className={`${selectedRow && selectedRow?.id === item?.id ? '' : 'truncate'} text-sm text-blue-oil font-light break-words max-w-[138px]`}>{answer}</p>;
  }

  const renderAns = (ans: any, item: any) => {
    const qType = ans?.questData?.inputType === 'LONG' ? 'TEXT_AREA' : ans?.questData?.questionType;
    if (isEdit === item?.id && !item?.sdv) {
      return renderInputField(ans?.questData?.questionType, `${qType}-${ans?.questData?.id}-${isBackupEdit}`, ans?.questData);
    }
    const dateType = ans?.questData?.dateTimeType
    if (qType === 'FILE_UPLOAD') {
      const nameList = ans?.answer?.split('/');
      return <div className='flex justify-between items-center'>
        {ans?.answer && nameList && nameList?.length > 0 && renderRow(nameList[nameList?.length - 1], item)}
      </div>
    }
    if (qType === 'SCALE' || qType === 'TEXT_INPUT' || qType === 'TEXT_AREA' || qType === 'NUMBER_VALUE' || qType === 'RATING' || qType === 'FILE_UPLOAD') {
      return renderRow(ans?.answer, item);
    }
    if (qType === 'DROPDOWN' || qType === 'CHECKBOX' || qType === 'RADIO_BUTTONS') {
      return renderRow(ans.answer ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType) : '', item);
    }
    if (qType === 'DATE_TIME' && ans.answer && ans.answer !== '') {
      const newAns = dateType && formatAnsDate(ans.answer, dateType);
      return renderRow(newAns && `${new Date(newAns)}` !== 'Invalid Date' ? format(new Date(newAns), getFormat(dateType)) : '', item);
    }
    return null;
  }

  return (
    <div ref={containerRef}>
      <div className="flex w-1/2 max-w-1/2 mt-3 items-center mb-3 group">
        <p className="font-normal flex font-sans text-blue-oil mr-1 text-left break-all">
          {questionNumber}.<p className='w-[90%] px-2' dangerouslySetInnerHTML={{ __html: ` ${title}` }}></p>
        </p>
        <div className="w-3">
          {checkIsQuery ? (
            <QueryQuestionnaire id={repeatedQuestions?.id || 0} methods={methods} />
          ) : null}
        </div>
      </div>
      {headerItems?.length > 0 && (
        <div className="border border-gray-light mb-4 max-h-[41rem] rounded-lg bg-white overflow-auto">
          <FormProvider {...methods}>
            <form>
              <table className="w-full min-w-full table-fixed">
              <thead>
                  <tr>
                    {headerItems?.length > 0 && headerItems?.map((r: any, i: number) => (
                      <th
                        key={`${r.key}${i}`}
                        className={clsx(
                          'text-left break-words text-sm text-blue-oil leading-6 tracking-[0.15px] px-2.5 py-2',
                          r.width,
                        )}
                      >
                        <p className='text-sm text-blue-oil font-medium' dangerouslySetInnerHTML={{ __html: `${r.title}` }}></p>
                      </th>
                    ))}
                    <th
                      className={clsx('text-left text-sm text-blue-oil leading-6 tracking-[0.15px] px-2.5 py-2 !w-[138px] !min-w-[138px]')}>
                      {intl.formatMessage({ id: 'questionnaire-field.verify' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr onClick={() => {
                    setIsEdit(-1);
                    setIsBackupEdit(-1);
                  }} className="group bg-transparent hover:bg-blue-light-100 overflow-hidden rounded">
                    {headerItems?.length > 0 && headerItems?.map((r: any, i: number) => (
                      <td key={`${r.key}${i}`} className='px-2.5 py-1'>
                        {renderInputField(r.key ,r.value, r)}
                      </td>
                    ))}
                  </tr>
                  {columns?.length > 0 && columns.map((item: any, i: any) => (
                    <tr
                      key={item.id}
                      className={clsx('border-b inherit border-b-gray-very-light !z-0 group hover:bg-blue-light-100 cursor-pointer', selectedRow && selectedRow.id === item.id && 'bg-[#0973d40a]')}
                      onClick={() => {
                        isEdit !== item?.id && setIsEdit(-1);
                        setSelectedRow(item);
                      }}
                    >
                      {item?.answers && item?.answers?.length > 0 && item?.answers?.map((ans: any, i: any) => (
                        <td
                          key={i}
                          onDoubleClick={() => checkIsAnswer?.() && handleIsEdit(item)}
                          className="px-2.5 break-all text-blue-oil text-sm py-2.5 text-left table-cell"
                        >
                          {renderAns(ans, item)}
                        </td>
                      ))}
                      <td>
                        {checkVerify(item) ?
                          <Checkbox
                            control={{ value: item?.sdv, checked: item?.sdv, onChange: () => handleSetSDV(item, `verifySdv-${item?.repeatedQuestionId}-${i}`) }}
                            name={`verifySdv-${item?.repeatedQuestionId}-${i}`}
                            mainClass='!my-0'
                            label={item?.sdv ? intl.formatMessage({ id: 'questionnaire-field.verified' }) : intl.formatMessage({ id: 'questionnaire-field.verify' })}
                            disabled={(item?.sdv || currentStudy?.status != 'EXECUTION' || !isSDV)}
                            className={`!w-4 !h-4 ${item?.sdv ? 'flex' : 'hidden'} group-hover:flex`}
                            inputClass={`!accent-base-white !bg-base-white !border-[1.185px] !w-4 !h-4 !shrink-0 ${(values[`verifySdv-${item?.repeatedQuestionId}-${i}`] || item?.sdv) ? '!border-green-light !text-green-light' : '!border-blue-oil !text-blue-oil'} !appearance-none`}
                            svgClass={(values[`verifySdv-${item?.repeatedQuestionId}-${i}`] || item?.sdv) ? 'stroke-green-light' : ''}
                          /> : <></>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  )
}

export default RepeatedQuestion;