import { create } from 'zustand';
import { IQuestion, TypeForm, IQuestionnaire, IConsentFormData, IStepEproList } from 'types/questionnaire';
import { IAnswer, IStepWithStatus } from 'types/step';
// import { ICreateQuestionnaireForm, IQuestionnaireForm, IQuestionnaire } from 'types/questionnaire';

interface QuestionnaireState {
  addQuestionError: null | string;
  setAddQuestionError: (err: string | null) => any;
  steps: any;
  page: string;
  setPage: any;
  setSteps: (steps: any) => any;
  selectedQuestionnairy: IQuestionnaire;
  setSelectedQuestionnairy: (item: IQuestionnaire) => void;
  questions: IQuestion[];
  answers: IAnswer[];
  stepsData: IStepWithStatus | undefined;
  reOrderedQuestions: IQuestion[];
  selectedFolder: number | string;
  consentFormData: IConsentFormData | undefined,
  isOpenConsentForm: boolean,
  consentFormLoading: boolean,
  isDoneMeeting: number,
  stepEproList: IStepEproList[],
  eproListPageNo: number,
  questStepId: any,
  setStepsData: (item: IStepWithStatus) => void;
  setQuestStepId: (item: any) => void;
  setAnswers: (item: IAnswer[]) => void;
  setQuestions: (item: IQuestion[]) => void;
  setReOrderedQuestions: (item: IQuestion[]) => void;
  setQuestionnaires: (item: IQuestion) => void;
  setSelectedFolder: (item: number | string) => void;
  removeQuestionnaire: (id: number) => void;
  duplicateQuestionnaire: (id: any) => void;
  updateQuestionnaireFormData: (id: number, data: TypeForm) => void;
  updateQuestionnaireTitle: (id: number, title: string) => void;
  setConsentFormData: (consentData: IConsentFormData) => void;
  setOpenConsentForm: (isOpenConsentForm: boolean) => void;
  setIsDoneMeeting: (isDoneMeeting: number) => void;
  setStepEproList: (stepEproList: IStepEproList[]) => void;
  setEproListPageNo: (eproListPageNo: number) => void;
  setConsentFormLoading: (consentFormLoading: boolean) => void;
}

export const useQuestionnaireStore = create<QuestionnaireState>((set) => ({
  page: 'add-questionnaire',
  setPage: (page: string) => set((state) => ({ ...state, page })),
  // setpage: (page: string) => set((state) => ({ ...state, page })),
  addQuestionError: null,
  setAddQuestionError: (err: string | null) =>
    set((state) => ({ ...state, addQuestionError: err })),
  steps: [],
  setSteps: (steps: any) => set((state) => ({ ...state, steps })),
  questions: [],
  answers: [],
  reOrderedQuestions: [],
  stepsData: undefined,
  selectedQuestionnairy: { type: '', id: 1 },
  selectedFolder: '',
  consentFormData: undefined,
  consentFormLoading: true,
  isOpenConsentForm: false,
  isDoneMeeting: 0,
  stepEproList: [],
  eproListPageNo: 0,
  questStepId: -1,
  setSelectedQuestionnairy: (questionnaire) =>
    set((state) => ({ ...state, selectedQuestionnairy: questionnaire })),
  setSelectedFolder: (id: number | string) => set((state) => ({ ...state, selectedFolder: id })),
  setConsentFormLoading: (consentFormLoading: boolean) => set((state) => ({ ...state, consentFormLoading })),
  setStepsData: (stepsData) => set((state) => ({ ...state, stepsData })),
  setAnswers: (answers) => set((state) => ({ ...state, answers })),
  setQuestions: (questions) =>
    set((state) => ({ ...state, questions: questions, reOrderedQuestions: questions })),
  setReOrderedQuestions: (questions) =>
    set((state) => ({ ...state, reOrderedQuestions: questions })),
  setQuestionnaires: (item) =>
    set((state) => ({ ...state, questions: [...state.questions, item] })),
  removeQuestionnaire: (id) =>
    set((state) => ({ questions: state.questions.filter((item) => item.id !== id) })),
  duplicateQuestionnaire: (id) =>
    set((state) => {
      const itemToDuplicate = state.questions.find((item) => item.id === id);

      if (itemToDuplicate) {
        const duplicatedItem = { ...itemToDuplicate, frontId: state.questions.length + 1 };
        delete duplicatedItem.id;
        return { questions: [...state.questions, duplicatedItem] };
      }

      return state;
    }),
  updateQuestionnaireFormData: (id, data) =>
    set((state) => {
      const arr = state.questions.map((item) => {
        if (item.id === id) {
          return { ...item, formData: data };
        }
        return item;
      });
      return { ...state, questions: arr };
    }),
  updateQuestionnaireTitle: (id, title = '') =>
    set((state) => {
      const arr = state.questions.map((item) => {
        if (item.id === id) {
          return { ...item, title: title, data: { ...item.data, questionTitle: title } };
        }
        return item;
      });
      return { ...state, questions: arr };
    }),
  setConsentFormData: (consentFormData: IConsentFormData) => set((state) => ({ ...state, consentFormData })),
  setOpenConsentForm: (isOpenConsentForm: boolean) => set((state) => ({ ...state, isOpenConsentForm })),
  setIsDoneMeeting: (isDoneMeeting: number) => set((state) => ({ ...state, isDoneMeeting })),
  setStepEproList: (stepEproList: IStepEproList[]) => set((state) => ({ ...state, stepEproList })),
  setEproListPageNo: (eproListPageNo: number) => set((state) => ({ ...state, eproListPageNo })),
  setQuestStepId: (questStepId: number) => set((state) => ({ ...state, questStepId }))
}));
