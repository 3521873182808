import React, { useState } from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

interface ExpandableTableRowProps {
  columns?: any;
  row?: any;
}

const ExpandableTableRow: React.FC<ExpandableTableRowProps> = ({ columns, row }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();
  const EVENT_TYPES: any = [
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-non-anonymized-data-modified' }),
      value: 'PATIENT_NON_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signed' }),
      value: 'DOCUMENT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signature-expired' }),
      value: 'DOCUMENT_SIGNATURE_EXPIRED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-anonymized-data-modified' }),
      value: 'PATIENT_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.data-verified' }),
      value: 'DATA_VERIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deleted' }),
      value: 'PARTICIPANT_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-created' }),
      value: 'PARTICIPANT_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deregistered-client-app' }),
      value: 'PARTICIPANT_DEREGISTERED_CLIENT_APP',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-scheduled' }),
      value: 'APPOINTMENT_SCHEDULED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-joined' }),
      value: 'APPOINTMENT_JOINED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.signature-expired' }),
      value: 'PARTICIPANT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-signed' }),
      value: 'Participant signed',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-created' }),
      value: 'QUERY_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-updated' }),
      value: 'QUERY_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-created' }),
      value: 'QUERY_RESPONSE_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-updated' }),
      value: 'QUERY_RESPONSE_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-deleted' }),
      value: 'QUERY_RESPONSE_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-resolved' }),
      value: 'QUERY_RESOLVED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-deleted' }),
      value: 'QUERY_DELETED',
    },
  ];
  const handleMouseEnter = () => setIsExpanded(true);
  const handleMouseLeave = () => setIsExpanded(false);
  const getEventTypeLabel = (val: string) => {
    return EVENT_TYPES.find((ev: any) => ev.value === val).label;
  };
  return (
    <tr
      className={` hover:bg-blue-very-light ${isExpanded ? '[&>td]:align-top h-auto' : 'h-12'}`} // Set initial and expanded heights
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {columns.map((column: any) => (
        <td
          key={column.label}
          className={`px-2 py-1 text-left text-dark border-b text-sm border-gray-200 max-w-[150px] xxl:max-w-[206px] ${isExpanded ? 'break-words	' : ' h-[20px] overflow-hidden whitespace-nowrap text-ellipsis'}`} // Adjust styles as needed
        >
          {column.value === 'eventType'
            ? getEventTypeLabel(row[column.value])
            : column.value === 'date'
              ? format(new Date(row[column.value]), 'MM/dd/yyyy hh:mm:ss')
              : column.value === 'username'
                ? row[column.value] + ` ( ${row.email} ) `
                : row[column.value]}
        </td>
      ))}
    </tr>
  );
};

export default ExpandableTableRow;
