import React from 'react';
import clsx from 'classnames';
import { SvgIcon } from '@mui/material';
import { CloseIcon } from '../Icons';
import { InputProps } from '../../../types/form';
import { useIntl } from 'react-intl';

const Input: React.FC<InputProps & { noErrorMessage?: boolean; requiredStar?: boolean }> = ({
  placeholder = 'Input ',
  ...props
}) => {
  const {
    label,
    type = 'text',
    name = '',
    disabled,
    control,
    readOnly,
    register,
    onBlur,
    defaultValue = '',
    errors,
    hasClear,
    className,
    inputClassName,
    icon,
    handleIcon,
    onKeyDown,
    ref,
    onFocus,
    noErrorMessage = false,
    noTranslation,
    requiredStar,
    customError,
  } = props;
  const errorMsg = errors?.[name]?.message as string | undefined;

  const intl = useIntl();

  const handleClear = () => {
    if (control?.onChange) {
      control?.onChange(null);
    }
  };
  return (
    <div
      onBlur={onBlur}
      data-testid='textinput-container'
      className={clsx('relative mt-4 flex justify-start items-start flex-col', className)}
    >
      {label && (
        <label
          data-testid='textinput-label'
          htmlFor={name}
          className={clsx(
            'block text-blue-ocean-deep  mb-2 truncate font-semibold leading-6 relative',
            requiredStar && 'pr-2',
          )}
        >
          {noTranslation ? label : intl.formatMessage({ id: `${label}` })}
          {requiredStar && <span className='absolute right-0 top-0'>*</span>}
        </label>
      )}
      <input
        ref={ref}
        data-testid='textinput-field'
        type={type}
        id={name}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={onFocus}
        className={clsx(
          `block w-full px-3 py-[11px] border leading-6 text-base text-blue-ocean-deep rounded-[0.625rem] focus:outline-none ${
            customError || errors?.[name]?.message
              ? '!border-danger-100'
              : 'border-blue-ocean-deep '
          }`,
          readOnly || disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          inputClassName,
        )}
        defaultValue={defaultValue}
        {...(control ? { ...control } : register ? { ...register(name) } : {})}
        {...(onKeyDown && { onKeyDown })}
      />
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
      {hasClear && (
        <SvgIcon
          onClick={handleClear}
          className='text-danger-100 absolute right-1 top-[2.7rem]'
          component={CloseIcon}
        />
      )}
      {icon && (
        <SvgIcon
          onClick={handleIcon}
          className='absolute cursor-pointer !w-4 !h-4 right-2 top-[3rem]'
          component={icon}
        />
      )}
    </div>
  );
};

export default Input;
