import { ReactElement } from 'react';
import { QuestionButton } from 'components/shared/QuestionButton';
import { IQuestion } from 'types/questionnaire';
import {
  TextInputIcon,
  DropdownIcon,
  CheckboxIcon,
  RatingIcon,
  DateTimeIcon,
  RadioButtonIcon,
  NumberIcon,
  ScaleIcon,
  FileIcon,
} from '../../Icons';

import { SvgIconTypeMap, SvgIcon } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  FiGrid,
  FiFileText,
  FiUpload,
  FiStar,
  FiRepeat,
  FiType,
  FiMenu,
  FiHash,
} from 'react-icons/fi';
import QuestionOptionalProps from '../QuestionOptionalProps';
export interface IInput {
  title: string;
  icon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  fiIcon?: ReactElement;
  type: string;
}
interface IInputs {
  title: string;
  list: IInput[];
}
interface IProps {
  question: IQuestion;
  togglePopover: any;
  isPopoverOpen: boolean;
  updateQuestion: any;
  optionalPropsPopover: any;
  setOptionalPropsPopover: any;
  onSaveQuestion: any;
  closePopups?: any;
  collapsedSubQuestions: any;
  setCollapsedSubQuestions: any;
  filterRepeatedInput?: boolean;
  filterTitleInput?: boolean;
  filterFileInput?: boolean;
  handleOpenPopup?: any;
  setLocalQsType: any;
  isStandardForm?: boolean;
}
export const getQuestionIcon = (questionType: string) => {
  switch (questionType) {
    case 'SCALE':
      return {
        title: 'Scale',
        icon: <SvgIcon component={ScaleIcon} className='!fill-white z-10' />,
      };
    case 'TEXT_INPUT':
      return {
        title: 'Text Input',
        icon: <SvgIcon component={TextInputIcon} className='!fill-white z-10' />,
      };
    case 'DROPDOWN':
      return {
        title: 'DropDown',
        icon: <SvgIcon component={DropdownIcon} className='!fill-white z-10' />,
      };
    case 'CHECKBOX':
      return {
        title: 'Checkbox',
        icon: <SvgIcon component={CheckboxIcon} className='text-gray-medium w-[26px] h-[13px]' />,
      };
    case 'DATE_TIME':
      return {
        title: 'Date/Time',
        icon: <SvgIcon component={DateTimeIcon} className='text-gray-medium w-[26px] h-[13px]' />,
      };
    case 'RADIO_BUTTONS':
      return {
        title: 'Radio Buttons',
        icon: (
          <SvgIcon component={RadioButtonIcon} className='text-gray-medium w-[26px] h-[13px]' />
        ),
      };
    case 'FILE_UPLOAD':
      return {
        title: 'File Upload',
        icon: <FiUpload className='text-gray-medium w-[22px] h-[21px] [&>path]:!fill-white' />,
      };

    case 'NUMBER_VALUE':
      return {
        title: 'Number Value',
        icon: <SvgIcon component={NumberIcon} className='text-gray-medium w-[26px] h-[13px]' />,
      };
    case 'RATING':
      return {
        title: 'Rating',
        icon: <FiStar className='text-gray-medium w-[22px] h-[21px]' />,
      };
    case 'REPEATED':
      return {
        title: 'Repeated question',
        icon: <FiRepeat className='text-gray-medium w-[22px] h-[21px]' />,
      };
    case 'TITLE':
      return {
        title: 'Title',
        icon: (
          <span className='flex'>
            <FiType className='text-gray-medium w-[22px] h-[21px]' />
            <FiMenu className='text-gray-medium w-[22px] h-[21px]' />
          </span>
        ),
      };
    // case 'HEADER':
    //   return HeaderItemIcon;
    // case 'INSTRUCTION':
    //   return InstructionItemIcon;
    // case 'IMAGE':
    //   return ImageItemIcon;
    default:
      return {
        title: 'Text Input',
        icon: <FiFileText className='text-gray-medium w-[22px] h-[21px]' />,
      };
  }
};

const QuestionTypes: React.FC<IProps> = ({
  question,
  togglePopover,
  isPopoverOpen,
  updateQuestion,
  optionalPropsPopover,
  setOptionalPropsPopover,
  onSaveQuestion,
  closePopups,
  collapsedSubQuestions,
  setCollapsedSubQuestions,
  filterRepeatedInput = false,
  filterTitleInput = false,
  filterFileInput = false,
  handleOpenPopup,
  setLocalQsType,
  isStandardForm,
}: IProps) => {
  console.log('filterFileInput: ', isStandardForm, filterFileInput);
  const handleSetCollapsedSubQuestions = (id: any) => {
    if (collapsedSubQuestions?.includes(id))
      setCollapsedSubQuestions([...collapsedSubQuestions.filter((sbId: any) => sbId !== id)]);
    else setCollapsedSubQuestions([...collapsedSubQuestions, id]);
  };

  return (
    <div
      className=' flex flex-1 justify-end items-center relative mt-2 '
      onClick={() => handleSetCollapsedSubQuestions(question?.id)}
    >
      {question?.questionType ? (
        <div className='group flex gap-x-3 justify-end items-center'>
          <QuestionOptionalProps
            question={question}
            optionalPropsPopover={optionalPropsPopover}
            setOptionalPropsPopover={setOptionalPropsPopover}
            title={getQuestionIcon(question?.questionType).title}
            onSaveQuestion={onSaveQuestion}
            icon={getQuestionIcon(question?.questionType).icon}
            closePopups={closePopups}
            isStandardForm={isStandardForm}
          />
        </div>
      ) : (
        <button
          className='bg-gray-200 hover:bg-gray-300 rounded-full focus:outline-none'
          onClick={handleOpenPopup}
        >
          <FiGrid className='text-blue-normal w-6 h-6' />
        </button>
      )}
      {isPopoverOpen && (
        <div className='w-[394px] absolute z-10 bg-white rounded-md top-0 right-5 mt-6 -mr-[2rem] pl-4 py-[21px] border-purple border-2 '>
          <div className=''>
            {inputs.map((section) => {
              return (
                <div key={section.title} className=''>
                  <h2 className='pl-3 text-left text-blue-ocean-deep text-[20px] font-[600]'>
                    Items
                  </h2>
                  <div className='flex flex-wrap gap-x-[2px] gap-y-1'>
                    {section.list
                      ?.filter(
                        (input: IInput) => !(input.type === 'REPEATED' && filterRepeatedInput),
                      )
                      ?.filter((input: IInput) => !(input.type === 'TITLE' && filterTitleInput))
                      ?.filter(
                        (input: IInput) => !(input.type === 'FILE_UPLOAD' && filterFileInput),
                      )
                      .map((input: IInput) => (
                        <QuestionButton
                          key={input.title}
                          Logo={
                            input?.fiIcon ? (
                              input.fiIcon
                            ) : input?.icon ? (
                              <SvgIcon
                                component={input.icon}
                                className='fill-blue group-hover:fill-blue-normal z-10'
                              />
                            ) : null
                          }
                          textContent={input.title}
                          className='cursor-move'
                          onClick={() => {
                            console.log('call updateQuestion: ', input.type);
                            setLocalQsType(input.type);
                            updateQuestion(input.type);
                            togglePopover();
                          }}
                          onHover={() => setLocalQsType(input.type)}
                        />
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionTypes;

const inputs: IInputs[] = [
  {
    title: 'Question',
    list: [
      {
        title: 'Text Input',
        icon: TextInputIcon,
        type: 'TEXT_INPUT',
      },
      {
        title: 'Dropdown',
        icon: DropdownIcon,
        type: 'DROPDOWN',
      },
      {
        title: 'Rating',
        icon: RatingIcon, // <fiIcon className='text-gray-medium w-8 h-8' />,
        type: 'RATING',
      },
      {
        title: 'Checkbox',
        icon: CheckboxIcon,
        type: 'CHECKBOX',
      },
      {
        title: 'Date/Time',
        icon: DateTimeIcon,
        type: 'DATE_TIME',
      },
      {
        title: 'File Upload',
        icon: FileIcon,
        type: 'FILE_UPLOAD',
      },
      {
        title: 'Radio Button',
        icon: RadioButtonIcon,
        type: 'RADIO_BUTTONS',
      },
      {
        title: 'Number Value',
        fiIcon: (
          <FiHash className='text-gray-medium w-[22px] h-[21px] group-hover:text-blue-normal' />
        ),
        type: 'NUMBER_VALUE',
      },
      {
        title: 'Repeated Question',
        fiIcon: <FiRepeat className='text-gray-medium w-[22px] h-[21px]' />,
        type: 'REPEATED',
      },
      {
        title: 'Scale',
        icon: ScaleIcon,
        type: 'SCALE',
      },
      {
        title: 'Title',
        fiIcon: (
          <span className='flex pr-1'>
            <FiType className='text-gray-medium w-[22px] h-[21px] group-hover:text-blue-normal' />
            <FiMenu className='text-gray-medium w-[22px] h-[21px] group-hover:text-blue-normal' />
          </span>
        ),
        type: 'TITLE',
      },
    ],
  },
  // {
  //   title: 'State Content',
  //   list: [
  //     {
  //       // id: 9,
  //       title: 'Instruction',
  //       icon: InstructionIcon,
  //       type: 'INSTRUCTION',
  //     },
  //     {
  //       // id: 10,
  //       title: 'Header',
  //       icon: HeaderIcon,
  //       type: 'HEADER',
  //     },
  //     {
  //       // id: 11,
  //       title: 'Image',
  //       icon: ImageIcon,
  //       type: 'IMAGE',
  //     },
  //   ],
  // },
  // {
  //   title: 'Other',
  //   list: [
  //     {
  //       id: 12,
  //       title: 'Template',
  //       icon: TemplateIcon,
  //       type: 'template',
  //     },
  //   ],
  // },
];
