import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IQuestion, ISaveQuestionnairesForm } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { toast } from 'react-toastify';
import { getMaxSortId } from 'utils/number';
import {
  addQuestionToQuestionnaire,
  addStaticQuestionToQuestionnaire,
  deleteQuestion,
  deleteStaticQuestion,
  saveQuestionnaires,
  saveStaticQuestionnaires,
  duplicateQuestion,
} from 'services/api/questionnaire';
import { useMutation } from '@tanstack/react-query';
import { isConsentQuestionnaire } from 'constants/questionnaire';
import { useIntl } from 'react-intl';

interface IQuestionForm {
  id: number;
  questionType: string;
  data: any;
}
interface TypeForm {
  id?: string;
  selectionType?: string;
  description?: string;
  questionTitle?: string;
  required?: boolean;
  maxCharacters?: number;
  inputPlaceHolder?: string;
  sortOrder?: number;
  isScored?: boolean;
  parentQuestionId?: number;
  titleQuestionId?: any;
  subtitle?: any;
}

export const useQuestionnaire = ({
  selectedQuestionnairy,
  setQuestions,
  questions,
  refetchFlows,
}: any) => {
  const { questionnaireId: questionnaireIdStr } = useParams();
  const [collapsedSubQuestions, setCollapsedSubQuestions] = useState<number[]>([]);
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const { page, setAddQuestionError } = useQuestionnaireStore();

  const intl = useIntl();
  const insertItemInBettwen = (list: any, newItem: any): any => {
    const newList = [...list];

    const index = newList.findIndex((item) => item.sortOrder === newItem.sortOrder);

    if (index !== -1) {
      newList.splice(index, 0, newItem);

      for (let i = index + 1; i < newList.length; i++) {
        newList[i].sortOrder += 1;
      }
    } else {
      newList.push(newItem);
    }

    return newList;
  };
  const { mutate: addQuestionMutate, isLoading: addQuestionLoading } = useMutation<
    { data: IQuestion; reOrder?: boolean },
    unknown,
    { data: [IQuestionForm]; reOrder?: boolean },
    unknown
  >(
    ({ data }: any) =>
      addQuestionToQuestionnaire(data, selectedQuestionnairy?.questionnaire?.id ?? questionnaireId),
    {
      onSuccess: (res: any, params: any) => {
        setAddQuestionError('');
        refetchFlows();
        if (params?.[0]?.data?.id) {
          const fndQsId = questions.findIndex(
            (qs: IQuestion) => qs?.id && +qs?.id === +params?.[0]?.data?.id,
          );
          if (fndQsId > -1) {
            const arr: IQuestion[] = [
              ...questions.map((qs: IQuestion, idx: number) =>
                idx === fndQsId ? { ...qs, ...res?.[0] } : { ...qs },
              ),
            ];

            setQuestions([...arr]);
          }
        }

        if (params?.reOrder) {
          const newList1 = insertItemInBettwen([...questions], res?.[0]);
          handleReorderQuestions([...newList1]);
          refetchFlows();
        } else {
          refetchFlows();
        }
      },
      onError(error: any) {
        const errMsg =
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' });
        setAddQuestionError(errMsg);
        toast.error(errMsg);
      },
    },
  );

  const { mutate: addStaticQuestionMutate } = useMutation<
    IQuestion,
    unknown,
    [IQuestionForm],
    unknown
  >(
    (data: [IQuestionForm]) =>
      addStaticQuestionToQuestionnaire(
        data,
        selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
      ),
    {
      onSuccess: () => {},
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );

  const { mutate: duplicateQuestionMutate } = useMutation<number, unknown, any, unknown>(
    (id: number) => duplicateQuestion(id),
    {
      onSuccess: () => {
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );

  const getOptionalKeys = (type: string | undefined, data: any) => {
    switch (type) {
      case 'SCALE':
        return {
          startValue:
            data?.optionalProps?.startValue || data?.optionalProps?.startValue == 0
              ? +data?.optionalProps?.startValue
              : -10,
          endValue:
            data?.optionalProps?.endValue || data?.optionalProps?.endValue == 0
              ? +data?.optionalProps?.endValue
              : 10,
          increment: data?.optionalProps?.increment ? +data?.optionalProps?.increment : 2,
          startLegend: data?.optionalProps?.startLegend ? data?.optionalProps?.startLegend : '',
          endLegend: data?.optionalProps?.endLegend ? data?.optionalProps?.endLegend : '',
        };
      case 'TEXT_INPUT':
        return {
          inputPlaceHolder: data?.inputPlaceHolder ?? 'placeholder',
          maxCharacters: +data?.maxCharacters ?? 50,
          ...(data?.description && { description: data?.description }),
        };
      case 'NUMBER_VALUE':
        return {
          measurementUnit: data?.measurementUnit,
          maxDecimal: data?.maxDecimal,
          maxNumber: data?.maxNumber,
          minNumber: data?.minNumber,
          numberType: data?.optionalProps?.numberType,
        };
      case 'FILE_UPLOAD':
        return {
          fileType: data?.fileType,
        };
      case 'DATE_TIME':
        return {
          dateTime: new Date(),
          dateTimeType: data?.optionalProps?.dateTimeType ?? 'DATE',
          disableFutureDates: !!data?.disableFutureDates,
        };
      case 'HEADER':
        return { header: data?.header };
      case 'INSTRUCTION':
        return { header: data?.editor };
      case 'IMAGE':
        return { imageUrl: data?.header };
      case 'RATING':
        return { stars: data?.optionalProps?.stars ?? 'THREE' };
      case 'CHECKBOX':
        if (data?.optionalProps?.questionOptions?.length === 0) {
          return {
            questionOptions: [{ label: '', value: '_', score: null }],
          };
        } else {
          return {
            questionOptions:
              data?.optionalProps?.questionOptions
                ?.filter((opt: any) => opt.value !== '_')
                ?.map((opt: any) => ({
                  ...opt,
                  label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
                  value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
                })) ?? [],
          };
        }

      case 'RADIO_BUTTONS':
      case 'DROPDOWN':
        return {
          questionOptions:
            data?.optionalProps?.questionOptions?.map((opt: any) => ({
              ...opt,
              label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
              value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
            })) ?? [],
        };
      default:
        return {};
    }
  };
  const handleAddQSToQuestionnaire = (
    data: TypeForm & { optionalProps: any },
    reOrder?: boolean,
  ) => {
    const body: any = {
      ...(isConsentQuestionnaire(data?.selectionType?.trim() ?? '')
        ? { staticContentType: data?.selectionType?.trim() }
        : { questionType: data?.selectionType?.trim() ?? 'TEXT_INPUT' }),
      ...(data?.parentQuestionId && { parentQuestionId: data?.parentQuestionId }),
      ...(data?.titleQuestionId && { titleQuestionId: data?.titleQuestionId }),
      data: {
        ...(data?.id && !data?.id?.includes('frontId') && { id: +data?.id }),
        questionTitle: data?.questionTitle ?? '',
        sortOrder: data?.sortOrder
          ? data?.sortOrder
          : isConsentQuestionnaire(data?.selectionType?.trim() ?? '')
            ? getMaxSortId(selectedQuestionnairy?.questionnaire?.staticContents) + 1
            : getMaxSortId(selectedQuestionnairy?.questionnaire?.questions) + 1,
        required: !!data?.required,
        ...(data?.description && { description: data?.description }),
        ...(data?.isScored != null ? { isScored: data?.isScored } : {}),
        ...getOptionalKeys(data?.selectionType?.trim(), data),
        ...(data?.optionalProps?.subtitle ? { subtitle: data?.optionalProps?.subtitle } : {}),
      },
    };

    if (isConsentQuestionnaire(data?.selectionType?.trim() ?? '')) {
      addStaticQuestionMutate([body]);
    } else {
      addQuestionMutate({ data: [body], reOrder: reOrder });
    }
  };

  const handleDuplicateQuestion = (qsId: number) => {
    duplicateQuestionMutate(qsId);
  };

  const { mutate: handleDeleteQuestion } = useMutation<any, unknown, any, unknown>(
    (data: any) =>
      deleteQuestion(data, selectedQuestionnairy?.questionnaire?.id ?? questionnaireId),
    {
      onSuccess: (data: any, id: number) => {
        setQuestions(questions.filter((qs: IQuestion) => qs?.id && +qs?.id !== id));
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionDeleted' }),
        );
      },
    },
  );
  const { mutate: handleDeleteStaticQuestion } = useMutation<any, unknown, any, unknown>(
    (data: any) =>
      deleteStaticQuestion(data, selectedQuestionnairy?.questionnaire?.id ?? questionnaireId),
    {
      onSuccess: () => {},
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionDelete' }),
        );
      },
    },
  );
  const handleRemoveQuestionApi = (question: IQuestion) => {
    if (isConsentQuestionnaire(question?.questionType)) {
      handleDeleteStaticQuestion(question?.id);
    } else {
      handleDeleteQuestion(question?.id);
    }
  };
  const { mutate: saveStaticQuestionnairesMutation } = useMutation<
    unknown,
    unknown,
    ISaveQuestionnairesForm,
    unknown
  >((data: ISaveQuestionnairesForm) => saveStaticQuestionnaires(data), {
    onSuccess: () => {
      // toast.success(intl.formatMessage({ id: 'error.questionnaireSaved' }));
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ??
          intl.formatMessage({ id: 'error.questionnaireSaveError' }),
      );
    },
  });
  const { mutate: saveQuestionnairesMutation } = useMutation<
    unknown,
    unknown,
    ISaveQuestionnairesForm,
    unknown
  >((data: ISaveQuestionnairesForm) => saveQuestionnaires(data), {
    onSuccess: () => {
      refetchFlows();
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ??
          intl.formatMessage({ id: 'error.questionnaireSaveError' }),
      );
    },
  });
  const handleReorderQuestions = (newQuestions: IQuestion[]) => {
    const payload: IQuestion[] = newQuestions
      ?.filter((item: IQuestion) => item?.questionTitle)
      .map((item: IQuestion) => {
        return {
          ...(item?.staticContentType
            ? { staticContentType: item.staticContentType }
            : { questionType: item.questionType }),
          ...(item?.parentQuestionId ? { parentQuestionId: item.parentQuestionId } : {}),
          ...(item?.titleQuestionId ? { titleQuestionId: item.titleQuestionId } : {}),
          data: {
            ...(selectedQuestionnairy?.questionnaire?.staticContents
              ? { header: item?.header }
              : { questionTitle: item?.questionTitle }),
            sortOrder: item?.sortOrder,
            required: !!item?.required,
            questionTitle: item?.questionTitle,
            updatedAt: item?.updatedAt,
            editable: item?.editable,
            ...((item?.automatic === false || item?.automatic === true) &&
              page === 'standard-form' && { automatic: item?.automatic }),
            ...(item?.questionCategory &&
              page === 'standard-form' && { questionCategory: item?.questionCategory }),
            id: item?.id,
            ...(item?.description && { description: item?.description }),
            ...(item.questionType === 'TEXT_INPUT' && {
              maxCharacters: item?.maxCharacters,
              inputPlaceHolder: item?.inputPlaceHolder,
            }),
            ...(item.questionType === 'DATE_TIME' && {
              dateTimeType: item?.dateTimeType,
              dateTime: item?.dateTime,
              disableFutureDates: item?.disableFutureDates,
            }),
            ...(item.questionType === 'RATING' && {
              stars: item?.stars ?? 'THREE',
            }),
            ...(item?.questionOptions && { questionOptions: item?.questionOptions }),
            ...(item.questionType === 'SCALE' && {
              startValue: item?.startValue,
              increment: item?.increment,
              endValue: item?.endValue,
              startLegend: item?.startLegend,
              endLegend: item?.endLegend,
            }),
            ...(item.questionType === 'NUMBER' && {
              numberType: item?.numberType,
            }),
            ...(item.subtitle
              ? {
                  subtitle: item?.subtitle,
                }
              : {}),
            isScored: item?.isScored,
          },
        };
      });

    if (selectedQuestionnairy?.questionnaire?.staticContents?.length) {
      saveStaticQuestionnairesMutation({
        questionnaireId: selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
        data: payload,
      });
    } else {
      saveQuestionnairesMutation({
        questionnaireId: selectedQuestionnairy?.questionnaire?.id ?? questionnaireId,
        data: payload,
      });
    }
  };

  return {
    handleAddQSToQuestionnaire,
    handleRemoveQuestionApi,
    addQuestionLoading,
    handleReorderQuestions,
    collapsedSubQuestions,
    setCollapsedSubQuestions,
    handleDuplicateQuestion,
  };
};
