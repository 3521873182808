import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuestionnaireStore } from '../../../../../store/QuestionnaireStore';
import ScaleInput from './ScaleInput';
import Input from '../../../../../components/common/TextInput';
import { Calendar, Select, TextArea } from '../../../../../components/common';
import QuestionMultiSelect from '../../../ParticipantPages/QuestionnaireStatus/QuestionMultiSelect';
import RatingStar from '../../../../../components/common/RatingStar';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import { getFormat } from '../../../../../utils/string';

interface IQuestionOptions {
  id: number;
  label: string;
  value: string;
}

const PreRepeatedQuestion = ({
  title,
  repeatedQuestions,
  PickerTypeEnum,
  setSelectedTable,
  checkType,
  preview,
  questionNumber,
}: any) => {
  const intl = useIntl();
  const methods = useForm();
  const { questionnaireId } = useParams();
  const [repeatedAnswers, setRepeatedAnswers] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [headerItems, setHeaderItems] = useState<any>();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [columns, setColumns] = useState<any>([]);
  const { stepsData, steps } = useQuestionnaireStore();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRepeatedAnswers(
      steps?.find(
        (stp: any) => stp?.step?.questionnaire?.id === Number(questionnaireId?.split('-')?.[1]),
      )?.repeatedAnswersRecords ?? [],
    );
  }, [steps, questionnaireId]);

  const values: any = methods?.watch();

  useEffect(() => {
    if (
      repeatedQuestions &&
      repeatedQuestions?.children &&
      repeatedQuestions?.children?.length > 0
    ) {
      const repeatedQues: any = repeatedQuestions?.children?.reduce((newItems: any, item: any) => {
        newItems?.push({
          ...item,
          title: item?.questionTitle,
          key: `${item?.questionType}`,
          value: `${item?.inputType === 'LONG' ? 'TEXT_AREA' : item?.questionType}-${item?.id}`,
          width: item?.questionType === 'SCALE' ? '!w-[240px] !min-w-[240px] !max-w-[240px]' : '!w-[138px] !min-w-[138px] !max-w-[138px]',
        });
        return newItems;
      }, []);
      setHeaderItems(repeatedQues);
    } else {
      setHeaderItems([]);
    }
  }, [repeatedQuestions]);

  useEffect(() => {
    if (repeatedAnswers && repeatedAnswers?.length > 0) {
      setColumns(
        repeatedAnswers
          ?.filter(
            (repAns: any) =>
              repAns?.repeatedQuestionId === repeatedQuestions?.id && repAns?.answers?.length > 0,
          )
          .map((repAns: any) => {
            let sortAns: any = [];
            headerItems?.map((item: any) => {
              const foundAns = repAns?.answers?.find((ans: any) => ans?.questionId === item?.id);
              if (sortAns && sortAns?.length > 0) {
                foundAns
                  ? sortAns?.push({ ...foundAns, questData: item })
                  : sortAns?.push({ answer: '', questData: item });
              } else {
                sortAns = foundAns
                  ? [{ ...foundAns, questData: item }]
                  : [{ answer: '', questData: item }];
              }
            });
            return { ...repAns, answers: sortAns };
          }),
      );
    }
  }, [repeatedAnswers]);

  const handleChange = (name: string, value: any) => {
    methods.setValue(name, value);
    setSelectedTable(repeatedQuestions?.id);
  };

  const renderInputField = (type: string, name: string, questData: any) => {
    switch (type) {
      case 'SCALE':
        return (
          <div>
            <ScaleInput
              name={name}
              control={methods.control}
              start={questData?.startValue}
              end={questData?.endValue}
              increment={questData?.increment}
              startLegend={questData?.startLegend}
              endLegend={questData?.endLegend}
              onChange={() => {
              }}
              mainClass='!pl-0'
              numberClass='[&>div>span]:!text-[7px]'
            />
          </div>
        );
      case 'TEXT_INPUT': {
        return questData?.inputType === 'LONG' ? (
          <TextArea
            name={name}
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
            control={methods?.control}
            errors={methods?.formState?.errors}
            rows={values[name] ? 3 : 1}
            className='!mt-0 w-full !min-w-[120px]'
            textClass={clsx(
              'placeholder:text-gray-very-light !border-blue-light-200 !rounded-[4px] !py-0 !min-h-7',
            )}
            readOnly={preview || checkType?.() ? false : true}
          />
        ) : (
          <Input
            name={name}
            register={methods.register}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.target.blur(e.target.value);
              }
            }}
            readOnly={preview || checkType?.() ? false : true}
            inputClassName='h-7 !rounded placeholder:text-sm placeholder:text-gray-very-light !border-blue-light-200'
            className='!mt-0'
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          />
        );
      }
      case 'NUMBER_VALUE':
        return (
          <Input
            name={name}
            register={methods.register}
            onKeyDown={(e: any) => {
              if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.' || e.key === ',') {
                if (questData?.numberType === 'DECIMAL' && e.key === '.') return;
                e.preventDefault();
              }
              if (!preview) {
                if (e.key === 'Enter') {
                  e.target.blur(e.target.value);
                }
              }
            }}
            type='number'
            readOnly={checkType?.() || preview ? false : true}
            inputClassName='h-7 !rounded placeholder:text-sm placeholder:text-gray-very-light !border-blue-light-200'
            className='!mt-0'
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          />
        );
      case 'DROPDOWN':
        return (
          <Select
            control={methods?.control}
            label=''
            isSearchable={true}
            className='shadow-none !p-0 min-w-[120px] w-full'
            mainClass={clsx(
              '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[5px] !max-h-[28px] !border-blue-light-200 !bg-white [&>div>ul]:!pl-0',
            )}
            disabled={checkType?.() || preview ? false : true}
            options={questData?.questionOptions.map((item: IQuestionOptions) => ({
              label: item.label,
              value: item.value,
            }))}
            isCheckIcon={true}
            noTranslation={true}
            noErrorMessage={true}
            buttonClassName='!pl-0'
            name={name}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            selectOptClass={values[name] ? '' : '!text-gray-very-light'}
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
            optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px] !min-w-[236px] !top-[28px]'
          />
        );
      case 'CHECKBOX':
        return (
          <QuestionMultiSelect
            options={questData?.questionOptions.map((item: IQuestionOptions) => ({
              label: item.label,
              value: item.value,
            }))}
            control={{
              value: values[name],
              onChange: (e: any) => handleChange(name, e),
            }}
            name={name}
            disabled={checkType?.() || preview ? false : true}
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
            optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px]'
          />
        );
      case 'RADIO_BUTTONS':
        return (
          <Select
            control={methods?.control}
            label=''
            isSearchable={true}
            className='shadow-none !p-0 min-w-[120px] w-full'
            mainClass={clsx(
              '[&>button]:p-0 [&>button>p]:!w-[88px] py-1 px-2 [&>div]:w-full rounded-[5px] !max-h-[28px] !border-blue-light-200 !bg-white [&>div>ul]:!pl-0',
            )}
            disabled={checkType?.() || preview ? false : true}
            options={questData?.questionOptions.map((item: IQuestionOptions) => ({
              label: item.label,
              value: item.value,
            }))}
            noTranslation={true}
            noErrorMessage={true}
            isCheckIcon={true}
            buttonClassName='!pl-0'
            name={name}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            selectOptClass={values[name] ? '' : '!text-gray-very-light'}
            placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
            optWrapperClassName='!border !border-blue-light-200 !rounded-b-[6px] !min-w-[236px] !top-[28px]'
          />
        );
      case 'RATING':
        return (
          <div className='max-w-[120px] bg-white overflow-x-scroll !border !border-blue-light-200 rounded'>
            <RatingStar
              control={methods.control}
              name={name}
              className={`!mt-0 !pr-0 ${questData?.stars === 'FIVE' ? 'gap-2 !mx-auto' : questData?.stars === 'THREE' ? 'gap-5 !mx-auto' : 'gap-0'}`}
              iconClass={questData?.stars <= 5 ? '!w-4 !h-4' : '!w-[14px] !h-[14px]'}
              onBlur={() => setSelectedTable(repeatedQuestions?.id)}
              stars={questData?.stars}
              noZero={true}
              disabled={checkType?.() || preview ? false : true}
            />
          </div>
        );
      case 'DATE_TIME':
        return (
          <Calendar
            control={methods.control}
            name={name}
            label=''
            className={clsx(
              '!border !border-blue-light-200 !rounded !w-[118px] !h-[28px] no-border-mui [&>div]!flex-reverse -[&>div>div>div>button]:!mr-[1px] [&>div>div>div>button>svg]:w-[16px] [&>div>div>div>button]:!p-0 [&>div>div>input]:!py-0.5 [&>div>div>input]:!text-sm [&>div>div>input]:!pl-2 [&>div>div>input]:!text-left',
              methods?.formState?.errors?.[name]
                ? ' [&>div]:!h-[34px] [&>div>div]:!border-danger-100 [&>div>.MuiInputBase-root]:!border-2 [&>div>div>input]:p-[2px]'
                : '[&>div>div>input]:pl-0 !my-0',
            )}
            onBlur={() => setSelectedTable(repeatedQuestions?.id)}
            setIsDatePickerOpen={setIsDatePickerOpen}
            placeholder='Select a date'
            errors={methods?.formState?.errors}
            noErrorMessage={true}
            isOkButtonDisabled={true}
            inputDisable={true}
            disabled={checkType?.() || preview ? false : true}
            type={(questData?.dateTimeType && PickerTypeEnum[questData?.dateTimeType]) || 'date'}
          />
        );
      default:
        return null;
    }
  };

  const handleOutsideClick = useCallback(
    (e: any, clickedOut: boolean) => {
      const isMui =
        e?.target?.classList &&
        e?.target?.classList?.length > 0 &&
        e?.target?.classList[0]?.includes('Mui');
      if (clickedOut && !isMui && !isDatePickerOpen) {
        console.log('clickoutside');
      }
    },
    [stepsData, isDatePickerOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const getAnsValue = (answer: string, questionOptions: any, type: any) => {
    const ans = answer.replace('[', '').replace(']', '');
    if (type === 'CHECKBOX') {
      const updatedAns = ans?.includes(',') ? ans?.split(',') : ans;
      if (typeof updatedAns === 'string') {
        return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
      } else {
        const ansList =
          updatedAns &&
          updatedAns?.length > 0 &&
          updatedAns?.map((aswr) => {
            return questionOptions?.find((qs: any) => `${qs?.id}` === `${aswr}`)?.value;
          });
        return ansList && ansList?.length > 0 && ansList.join(', ');
      }
    }
    return questionOptions?.find((qs: any) => `${qs.id}` === `${ans}`)?.value || '';
  };

  const renderRow = (answer: any, item: any) => {
    return (
      <p
        className={`${selectedRow && selectedRow?.id === item?.id ? '' : 'truncate'} text-sm break-words max-w-[138px]`}
      >
        {answer}
      </p>
    );
  };

  const renderAns = (ans: any, item: any) => {
    const qType = ans?.questData?.questionType;
    const dateType = ans?.questData?.dateTimeType
    if (qType === 'FILE_UPLOAD') {
      const nameList = ans?.answer?.split('/');
      return (
        <div className='flex justify-between items-center'>
          {ans?.answer &&
            nameList &&
            nameList?.length > 0 &&
            renderRow(nameList[nameList?.length - 1], item)}
        </div>
      );
    }
    if (
      qType === 'SCALE' ||
      qType === 'TEXT_INPUT' ||
      qType === 'NUMBER_VALUE' ||
      qType === 'RATING' ||
      qType === 'FILE_UPLOAD'
    ) {
      return renderRow(ans?.answer, item);
    }
    if (qType === 'DROPDOWN' || qType === 'CHECKBOX' || qType === 'RADIO_BUTTONS') {
      return renderRow(
        ans.answer
          ? getAnsValue(ans.optionId, ans?.questData?.questionOptions, ans?.questData?.questionType)
          : '',
        item,
      );
    }
    if (qType === 'DATE_TIME') {
      return renderRow(ans.answer ? format(new Date(ans.answer), getFormat(dateType)) : '', item);
    }
    return null;
  };

  return (
    <div ref={containerRef}>
      <div className='flex w-1/2 max-w-1/2 mt-3 items-center mb-3 group'>
        <p className='text-sm font-medium text-font mr-1 text-left flex'>
          <span className='font-normal text-blue-oil font-sans'>{`${questionNumber}. `} </span>{' '}
          <p className='pl-1 font-normal text-blue-oil font-sans' dangerouslySetInnerHTML={{ __html: title }}></p>
        </p>
      </div>
      {headerItems?.length > 0 && (
        <div className='border border-gray-light mb-4 max-w-[58rem] min-h-[300px] max-h-[41rem] rounded-lg bg-white overflow-auto'>
          <FormProvider {...methods}>
            <form>
              <table className='w-full min-w-full table-fixed'>
                <thead>
                  <tr>
                    {headerItems?.length > 0 &&
                      headerItems?.map((r: any, i: number) => (
                        <th
                          key={`${r.key}${i}`}
                          className={clsx(
                            'text-left break-words text-sm font-medium text-blue-oil leading-6 tracking-[0.15px] px-2.5 py-2',
                            r.width,
                          )}
                        >
                          {r.title}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className='group bg-transparent hover:bg-blue-light-100 overflow-hidden rounded'>
                    {headerItems?.length > 0 &&
                      headerItems?.map((r: any, i: number) => (
                        <td key={`${r.key}${i}`} className='px-2.5 py-1'>
                          {renderInputField(r.key, r.value, r)}
                        </td>
                      ))}
                  </tr>
                  {columns?.length > 0 &&
                    columns.map((item: any) => (
                      <tr
                        key={item.id}
                        className={clsx(
                          'border-b inherit border-b-gray-very-light !z-0 group hover:bg-blue-light-100 cursor-pointer',
                          selectedRow && selectedRow.id === item.id && 'bg-[#0973d40a]',
                        )}
                        onClick={() => {
                          setSelectedRow(item);
                        }}
                      >
                        {item?.answers &&
                          item?.answers?.length > 0 &&
                          item?.answers?.map((ans: any, i: any) => (
                            <td
                              key={i}
                              className='px-2.5 break-all text-blue-oil text-sm font-medium py-2.5 text-left table-cell'
                            >
                              {renderAns(ans, item)}
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default PreRepeatedQuestion;
