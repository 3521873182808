import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import DragAndDropList from './DragAndDropList';
import FolderItem from './FolderItem';
import { Button } from 'components/common';
import { FiFolderPlus, FiEdit3, FiPenTool, FiUserCheck } from 'react-icons/fi';
import { getMaxSortId } from 'utils/number';
import { v4 as uuidv4 } from 'uuid';
import { hasObjectWithKeyValue } from 'utils/array';
import clsx from 'classnames';
import { useStudyStore } from 'store/StudyStore';
import { useMutation } from '@tanstack/react-query';
import { IActivateConsentForm, IStandardForm } from 'types/study';
import { activateConsentForm, hideConsentQuestionnaire } from 'services/api/study';
import { useNavigate, useParams } from 'react-router-dom';
import StandardFormsMenu from '../components/StandardFormsComponents/StandardFormsMenu';
// import DeploymentQuestionnaire from './DeploymentQuestionnaire';
import { FormattedMessage } from 'react-intl';

interface IProps {
  steps?: any;
  selectedQuestionnairy?: any;
  handleSelectQuestionnaire: (item: any, isFolder?: boolean, isConsentForm?: boolean) => void;
  setPage: (state: string) => void;
  handleSwapItemsApi: any; // (actiontype?: string, newList?: any, callback?: any) => any;
  handleEditTitle?: any;
  handleRemoveFolder: any;
  setFolderItemType: any;
  handleCreateFolder: (folderName: string) => void;
  createQuestionnaireItem: (name: string) => void;
  emptySteps: boolean;
  page: string;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
  refetchStudy: any;
  standardForms: IStandardForm[] | [];
}

const DragDropList: React.FC<IProps> = ({
  steps,
  setPage,
  selectedQuestionnairy,
  handleSelectQuestionnaire,
  handleSwapItemsApi,
  handleEditTitle,
  handleRemoveFolder,
  setFolderItemType,
  handleCreateFolder,
  createQuestionnaireItem,
  emptySteps = false,
  page,
  setShowPreview,
  refetchStudy,
  standardForms,
}: IProps) => {
  const [list, setList] = useState<any>([]);
  const { studyId } = useParams();
  const { currentStudy } = useStudyStore();
  const scrollRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const { mutate: activateConsentFormMutate } = useMutation<
    IActivateConsentForm,
    unknown,
    any,
    unknown
  >((data: any) => activateConsentForm(data), {
    onSuccess() {
      refetchStudy();
      setPage('consent-form');
    },
  });

  const { mutate: hideConsentQuestionnaireMutation } = useMutation<number, unknown, any, unknown>(
    (data: any) => hideConsentQuestionnaire(data),
    {
      onSuccess() {
        refetchStudy();
        setPage('');
      },
    },
  );

  const handleActivateConsentForm = () => {
    if (currentStudy?.consentQuestionnaire && currentStudy?.consentQuestionnaire?.active) {
      hideConsentQuestionnaireMutation(currentStudy?.consentQuestionnaire?.id);
    } else {
      activateConsentFormMutate({
        title: currentStudy?.title,
        type: 'CONSENT',
        studyId,
      });
    }
  };

  useEffect(() => {
    const folders: any = [];

    if (steps.length === 0) {
      const emptyItem = {
        id: 1,
        folder: null,
        idVerification: null,
        questionnaire: null,
        parentId: null,
        content: '',
        sortOrder: 1,
        children: [],
        newFolder: true,
      };
      if (!emptySteps) folders.push(emptyItem);

      setList(folders);
    } else {
      steps.forEach((element: any) => {
        if (element?.folder && !element?.questionnaire && !element?.idVerification) {
          folders.push({
            id: `${element?.id}`,
            // parentId: `${element.id}`,
            content: element?.folder
              ? element.folder?.name
              : element.questionnaire?.title
                ? element.questionnaire?.title
                : element?.idVerification
                  ? 'ID Verification'
                  : '',
            sortOrder: element?.sortOrder,
            children: [],
            ...(element.folder && element.folder.name && { folder: element.folder }),
          });
        }
      });
      steps.forEach((element: any) => {
        if (!element?.folder && (element?.questionnaire || element?.idVerification)) {
          folders.push({
            id: `${element.id}`,
            children: [],
            content: element?.questionnaire?.title
              ? element.questionnaire?.title
              : element?.idVerification
                ? 'ID Verification'
                : '',
            sortOrder: element.sortOrder,
            ...(element?.questionnaire && { questionnaire: element?.questionnaire }),
            ...(element?.idVerification && { idVerification: element?.idVerification }),
          });
        } else {
          folders.forEach((el: any, folderIndx: number) => {
            if (
              +el.folder?.id === +element?.folder?.id &&
              (element?.questionnaire || element?.idVerification)
            ) {
              if (folders[folderIndx]) {
                folders[folderIndx].children = [
                  ...folders[folderIndx].children,
                  {
                    ...element,
                    id: `${element.id}`,
                    parentId: `${el.folder?.id}`,
                    content: element.questionnaire?.title
                      ? element?.questionnaire?.title
                      : element?.idVerification
                        ? 'ID Verification'
                        : '',
                    children: [],
                    sortOrder: element.sortOrder,
                    // sortOrder:
                    //   element?.questionnaire?.sortOrder ?? element?.idVerification?.sortOrder,
                    ...(element?.questionnaire && { questionnaire: element?.questionnaire }),
                    ...(element?.idVerification && { idVerification: element?.idVerification }),
                  },
                ];
              }
            }
          });
        }
      });

      steps.forEach((element: any) => {
        if (element?.folder && element?.folder?.parentFolderId) {
          folders.forEach((el: any, folderIndx: number) => {
            if (+el.folder?.id === +element?.folder?.parentFolderId) {
              const foundFolder = folders.find(
                (ff: any) => +ff?.folder?.id === +element?.folder?.id,
              );
              if (folders[folderIndx]) {
                if (!hasObjectWithKeyValue(folders[folderIndx].children, 'id', foundFolder?.id)) {
                  folders[folderIndx].children = [
                    ...folders[folderIndx].children,
                    {
                      ...foundFolder,
                    },
                  ];
                }
              }
            }
          });
        }
      });
      setList(folders?.filter((n: any) => n && !n?.folder?.parentFolderId));
    }
    // scrollToBottom();
  }, [steps]);

  const handleRowClick = (item: any) => {
    if (item?.questionnaire) {
      handleSelectQuestionnaire(item);
    } else if (item?.idVerification) {
      setPage('add-item');
      handleSelectQuestionnaire(item, true);
    } else {
      setPage('add-item');
      handleSelectQuestionnaire(item, true);
    }
  };

  const scrollToBottom = () => {
    const element = document.getElementById('scrollableDiv');

    if (scrollRef.current && element) {
      setTimeout(() => {
        element.scrollTo({
          top: element.clientHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const handleCreateEmptyFolder = (type: string) => {
    scrollToBottom();
    const emptyItem: any = {
      id: uuidv4(),
      folder: null,
      idVerification: null,
      questionnaire: null,
      parentId: null,
      content: '',
      sortOrder: getMaxSortId(steps) + 1,
      children: [],
      ...(type === 'folder' ? { newFolder: true } : { newItem: true }),
    };

    const fndEmptyFolder: any = list.find((it: any) => it?.newFolder);
    const fndEmptyItem: any = list.find((it: any) => it?.newItem);

    if (!fndEmptyFolder && type === 'folder' && !fndEmptyItem) setList([...list, emptyItem]);
    else if (!fndEmptyItem && type === 'item' && !fndEmptyFolder) setList([...list, emptyItem]);
  };

  return (
    <div className='relative w-[356px] h-full pr-[30px]'>
      <div className='absolute top-0 left-0 right-0 bottom-0 bg-rose-light w-[310px] rounded-[8px] border-gray-light border-2' />
      <div className='relative z-1  w-[356px]'>
        <div className='flex justify-start items-center gap-x-4 w-[90%]'>
          <p className='z-2 relative  text-left text-blue-ocean-deep text-[20px] p-3 pb-2 pr-0 font-medium  rounded-t-[8px]'>
            <FormattedMessage id='study-builder.addItems' />
          </p>
          <FiUserCheck className='text-blue-ocean-deep w-5 h-5  cursor-pointer' />
          <FiPenTool
            onClick={() => handleActivateConsentForm()}
            className={clsx(
              'text-blue-ocean-deep w-5 h-5 mt-[2px] cursor-pointer',
              (!currentStudy?.consentQuestionnaire ||
                (currentStudy?.consentQuestionnaire &&
                  !currentStudy?.consentQuestionnaire?.active)) &&
                'opacity-50 ',
            )}
          />
        </div>
        {currentStudy?.consentQuestionnaire?.active && (
          <div
            className='pl-3'
            onClick={() => {
              handleSelectQuestionnaire({ id: null, questionnaire: null }, false, true);
              setShowPreview(true);
              navigate(`/study/${studyId}/study-builder/consent`, { replace: true });
            }}
          >
            <div
              className={clsx(
                'border-2 rounded-lg h-10 w-[270px] mb-3 bg-base-white text-blue-oil text-base font-semibold flex justify-start items-center pl-[8px] cursor-pointer text-[15px] truncate text-ellipsis',
                page === 'consent-form' && 'border-purple',
              )}
            >
              <FormattedMessage id='study-builder.consentForm' />
            </div>
            <div className='border-2 border-dashed border-[rgba(0,0,0,0.10) w-[270px] mb-4' />
          </div>
        )}
        <DragAndDropList
          {...{
            droppedItems: [...list]?.sort((a: any, b: any) => a?.sortOrder - b?.sortOrder),
            handleSwapItemsApi,
            handleRowClick,
            showFolderName: true,
            classNames: { rowClassNames: 'w-full mb-1' },
            handleEditTitle,
            handleRemoveFolder,
            setFolderItemType,
            handleCreateFolder,
            createQuestionnaireItem,
          }}
          ref={scrollRef}
          renderRow={(item: any) => (
            <div className='flex justify-between items-center w-full gap-x-4'>
              <FolderItem
                key={item?.title}
                {...{
                  handleSelectQuestionnaire,
                  selectedQuestionnairy,
                  setPage,
                  handleRowClick,
                  item: {
                    ...item,
                    name: item?.text,
                    label: item?.text
                      ? item?.text
                      : item?.questionnaire?.title
                        ? item?.questionnaire?.title
                        : item?.idVerification
                          ? 'ID Verification'
                          : '',
                    value: item?.title,
                  },
                }}
              />
            </div>
          )}
        />
        <div className='pl-4 z-2 relative flex gap-x-[12px]  max-h-18 h-[60px] pt-3 w-[90%] rounded-b-[8px]'>
          <Button
            onClick={() => handleCreateEmptyFolder('folder')}
            className='flex justify-center gap-x-2 items-center border-2 h-10 border-dashed rounded-md border-gray-medium text-xs font-medium	 px-[7px] py-[6px] text-gray-medium w-[126px]'
          >
            <FiFolderPlus className='w-6 h-5 text-gray-medium' />
            <FormattedMessage id='study-builder.addFolder' />
          </Button>
          <Button
            onClick={() => {
              handleCreateEmptyFolder('item');
            }}
            className='flex gap-x-2 justify-center !border-spacing-5 items-center border-2 h-10 border-gray-medium border-dashed rounded-md text-xs font-medium	px-[7px] py-[6px] text-gray-medium w-[126px]'
          >
            <FiEdit3 className='w-5 h-5 text-gray-medium' />
            <FormattedMessage id='study-builder.addItem' />
          </Button>
        </div>
        <StandardFormsMenu
          page={page}
          standardForms={standardForms}
          setPage={setPage}
          handleRowClick={handleRowClick}
          handleSelectQuestionnaire={handleSelectQuestionnaire}
        />
      </div>
    </div>
  );
};

export default DragDropList;
