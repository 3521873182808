import React from 'react';
import clsx from 'classnames';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
interface CheckboxProps {
  label?: string;
  name?: string;
  className?: string;
  mainClass?: string;
  inputClass?: string;
  svgClass?: string;
  disabled?: boolean;
  control?: {
    onChange?: (value: any) => void;
    onBlur?: () => void;
    value?: any;
    checked?: boolean;
  };
  defaultValue?: boolean;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<FieldValues>;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name = '',
  // defaultValue = false,
  control,
  register,
  className,
  mainClass,
  inputClass,
  svgClass,
  disabled,
  errors,
}) => {
  const errorMsg = errors?.[name]?.message as string | undefined;
  
  return (
    <div className={`my-4 ${mainClass} ${disabled ? '!cursor-not-allowed' : '!cursor-pointer'}`}>
      <div className={clsx('flex justify-start relative items-center gap-x-2', className)}>
        <input
          type='checkbox'
          id={name}
          name={name}
          disabled={disabled}
          className={`form-checkbox h-6 w-6 text-blue-500 accent-blue-oil ${disabled ? '!cursor-not-allowed' : '!cursor-pointer'} ${inputClass} ${
            errors?.[name]?.message ? 'border-red-500' : 'border-gray-300'
          }`}
          // defaultChecked={defaultValue}
          {...(control ? { ...control } : register ? { ...register(name) } : {})}
        />
        {svgClass && (
          <svg
            // className={`absolute w-3 h-3 pointer-events-none stroke-white fill-none peer-checked:!fill-blue-oil mt-1 ${svgClass}`}
            className={`absolute w-3 h-4 ml-0.5 peer-checked:block pointer-events-none ${svgClass}`}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <polyline points='20 6 9 17 4 12'></polyline>
          </svg>
        )}
        {label ? (
          <label htmlFor={name} className='block text-base text-dark font-medium '>
            {label}
          </label>
        ) : null}
      </div>
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
};

export default Checkbox;
