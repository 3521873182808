import { useEffect, useState } from 'react';
import { IQuestion } from 'types/questionnaire';
import { insertAtIndex } from 'utils/array';
import { getMaxSortId } from 'utils/number';
import DragDropList from '../DragDropList';
import { useQuestionnaire } from '../useQuestionnaire';
import { useIntl } from 'react-intl';

interface IProps {
  questions?: any;
  handleRepeatedOnSaveQs?: any;
  titleQuestionId?: any;
  allQuestions?: IQuestion[] | undefined;
  question?: IQuestion;
  workflowQuestionnaire?: any;
  handleWorkflowOnSaveQuestion?: any;
  onDuplicateQuestion?: any;
  onRemoveQuestionApi?: any;
  handleReorderQuestionsFC?: any;
  isStandardForm?: any;
}
const TitleQuestion = ({
  question,
  questions,
  allQuestions,
  titleQuestionId,
  workflowQuestionnaire,
  handleWorkflowOnSaveQuestion,
  onDuplicateQuestion,
  onRemoveQuestionApi,
  handleReorderQuestionsFC,
  isStandardForm,
}: IProps) => {
  const { handleAddQSToQuestionnaire } = useQuestionnaire({});

  const [subQuestions, setSubQuestions] = useState(questions);
  const [subTitle, setSubTitle] = useState(question?.subtitle ?? '');
  useEffect(() => {
    const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

    if (fndEmptyItem === -1) {
      setSubQuestions(
        insertAtIndex(
          [...questions],
          {
            id: 0,
            questionTitle: '',
            sortOrder: getMaxSortId(questions) + 1,
            titleQuestionId: titleQuestionId,
          },
          questions?.length - 1,
        ),
      );
    }
  }, [questions]);
  const isWorkFlow = workflowQuestionnaire && handleWorkflowOnSaveQuestion;
  const handleOnSaveQuestion = (
    tmpQuestion: IQuestion & { optionalProps?: any },
    isSubtitle?: boolean,
  ) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const tmpQuestions = isSubtitle ? allQuestions : questions;
    const fndItem: any = tmpQuestions?.find((it: IQuestion) => it?.id === id && it?.id !== 0);
    
    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          ...(tmpQuestion?.optionalProps?.numberType
            ? { numberType: tmpQuestion?.optionalProps?.numberType }
            : {}),
          ...(tmpQuestion?.optionalProps?.dateTimeType
            ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
            : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: isStandardForm
          ? getMaxSortId(allQuestions) + 1
          : questions?.length && questions[questions.length - 1]?.sortOrder !== undefined
            ? (questions[questions.length - 1].sortOrder ?? 0) + 1
            : (questions?.length ?? 0) + 3,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          ...(tmpQuestion?.optionalProps?.numberType
            ? { numberType: tmpQuestion?.optionalProps?.numberType }
            : {}),
          ...(tmpQuestion?.optionalProps?.dateTimeType
            ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
            : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    }
  };
  const intl = useIntl();
  const handleSubtitleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (isWorkFlow) handleWorkflowOnSaveQuestion({ ...question, subtitle: subTitle }, true);
      else handleOnSaveQuestion({ ...question, subtitle: subTitle }, true);
    }
  };

  if (!subQuestions?.length) return null;
  return (
    <div className='pl-[10px] pb-4 pr-2 flex justify-start items-start flex-col [&>div]:w-full [&>div]:border-none [&>div>div>div]:border-none bg-blue-very-light  [&>div>div>div]:pl-0 mb-4'>
      <div className='flex justify-start items-center'>
        <input
          onKeyDown={handleSubtitleKeyDown}
          type='text'
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
          onBlur={() => {
            if (isWorkFlow) handleWorkflowOnSaveQuestion({ ...question, subtitle: subTitle }, true);
            else handleOnSaveQuestion({ ...question, subtitle: subTitle }, true);
          }}
          placeholder={intl.formatMessage({ id: 'allStudies.subTitle' })}
          className='my-2 p-4 outline-none focus:outline-none border-[1px] border-gray-300 hover:border-purple !rounded-[4px] h-[29px] w-[313px] px-2 bg-blue-very-light'
        />
      </div>
      <DragDropList
        questions={subQuestions?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)}
        handleOnSaveQuestion={isWorkFlow ? handleWorkflowOnSaveQuestion : handleOnSaveQuestion}
        repeatedQuestion={true}
        allQuestions={allQuestions}
        filterRepeatedInput={true}
        className=' [&>div>div>.value]:bg-white rounded-[4px]'
        titleQuestionId={titleQuestionId}
        isTitleQuestion={true}
        filterTitleInput={true}
        filterRequired={false}
        onDuplicateQuestion={onDuplicateQuestion}
        onRemoveQuestionApi={onRemoveQuestionApi}
        handleReorderQuestionsFC={handleReorderQuestionsFC}
        isStandardForm={isStandardForm}
      />
    </div>
  );
};

export default TitleQuestion;
